@use "../mixins.module.scss" as *;

.iw {
  &_table {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 24px;
    background: var(--background-main, #FFF);

    table {
      width: 100%;
    }
    &__head {
      th {
        padding-top: 8px;
        padding-bottom: 8px;
        &:not(:first-child) {
          padding-left: var(--sp-4, 16px);
        }
        &:not(:last-child) {
          padding-right: var(--sp-4, 16px);
        }
      }
      &_wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;

        color: var(--text-additional-alt, #6C7284);
        @include bold__caption_1;
      }
    }
    &__body {
      tr {
        border-top: 1px solid var(--strokes-main, #F3F5FB);
      }
      td {
        padding-top: 8px;
        padding-bottom: 8px;
        &:not(:first-child) {
          padding-left: var(--sp-4, 16px);
        }
        &:not(:last-child) {
          padding-right: var(--sp-4, 16px);
        }
          .image-item {
              width: 192px;
              height: 124px;
              background-image: var(--img, none);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin: 0 auto 16px auto;
              border-radius: 12px;
          }
      }
      &_wrap {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;

        color: var(--text-basic, #2E3549);
        font-family: Nunito, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .center {
      text-align: center;
      justify-content: center;
    }
    .right {
      text-align: right;
      justify-content: right;
    }
  }
}
