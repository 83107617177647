.faw-abey {
  &.gradient {
      background: linear-gradient(139.89deg, rgba(255, 255, 255, 0.3) -4.13%, rgba(255, 255, 255, 0) 106.02%), radial-gradient(50% 50% at 50% 50%, #FEAA67 0%, #FE7202 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
}

.color-vticket {
  color: #009dc4;
  &::before, &::after {
    color: #009dc4;
  }
}

.color-ticket {
  color: #8b8b8b;
  &::before, &::after {
    color: #8b8b8b;
  }
}

.faw-stickers {
    color: #009DC4;
}
@font-face {
  font-family: 'faw';
  src:  url('fonts/icons/faw.eot?v4wip8');
  src:  url('fonts/icons/faw.eot?v4wip8#iefix') format('embedded-opentype'),
    url('fonts/icons/faw.ttf?v4wip8') format('truetype'),
    url('fonts/icons/faw.woff?v4wip8') format('woff'),
    url('fonts/icons/faw.svg?v4wip8#faw') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="faw-"], [class*=" faw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'faw' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.faw-view-list:before {
  content: "\e93d";
}
.faw-add-one:before {
  content: "\e93e";
}
.faw-filter:before {
  content: "\e93c";
}
.faw-arrow_round:before {
  content: "\e928";
}
.faw-cup:before {
  content: "\e927";
}
.faw-arrow_up_based:before {
  content: "\e924";
}
.faw-plus:before {
  content: "\e923";
}
.faw-info_round-stroke:before {
  content: "\e922";
}
.faw-abey:before {
  content: "\e921";
}
.faw-wallet:before {
  content: "\e925";
}
.faw-stickers:before {
  content: "\e926";
}
.faw-zoom-in:before {
  content: "\e920";
}
.faw-gear:before {
  content: "\e91f";
}
.faw-delete:before {
  content: "\e91b";
}
.faw-unhappy:before {
  content: "\e91c";
}
.faw-clip:before {
  content: "\e91d";
}
.faw-spinner:before {
  content: "\e91e";
}
.faw-logo .path1:before {
  content: "\e916";
  color: rgb(147, 149, 152);
}
.faw-logo .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.faw-logo .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.faw-logo .path4:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(147, 149, 152);
}
.faw-logo .path5:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(147, 149, 152);
}
.faw-logo .path6:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.faw-logo .path7:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.faw-logo .path8:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.faw-logo .path9:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.faw-logo .path10:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.faw-logo .path11:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.faw-logo .path12:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.faw-vector_right:before {
  content: "\e917";
}
.faw-vector_left:before {
  content: "\e92a";
}
.faw-vector_down:before {
  content: "\e918";
}
.faw-vector_up:before {
  content: "\e92b";
}
.faw-trash:before {
  content: "\e919";
}
.faw-table-view:before {
  content: "\e91a";
}
.faw-location_fill:before {
  content: "\e913";
}
.faw-burger:before {
  content: "\e912";
}
.faw-map:before {
  content: "\e914";
}
.faw-pensil:before {
  content: "\e915";
}
.faw-location_stroke:before {
  content: "\e911";
}
.faw-info_round:before {
  content: "\e90f";
}
.faw-cross:before {
  content: "\e910";
}
.faw-clock_revert:before {
  content: "\e90c";
}
.faw-question_mark:before {
  content: "\e90d";
}
.faw-exclamation_point_round:before {
  content: "\e90e";
}
.faw-arrow_down:before {
  content: "\e90b";
}
.faw-arrow-up:before {
  content: "\e92c";
}
.faw-cart:before {
  content: "\e907";
}
.faw-calendar:before {
  content: "\e908";
}
.faw-card-view:before {
  content: "\e909";
}
.faw-copy:before {
  content: "\e90a";
}
.faw-eye_close:before {
  content: "\e905";
}
.faw-eye_open:before {
  content: "\e906";
}
.faw-eye_open_lite:before {
  content: "\e92f";
}
.faw-arrow_left:before {
  content: "\e904";
}
.faw-arrow_right:before {
  content: "\e92d";
}
.faw-arrow_start:before {
  content: "\e930";
}
.faw-arrow_end:before {
  content: "\e931";
}
.faw-clock:before {
  content: "\e901";
}
.faw-check:before {
  content: "\e902";
}
.faw-check_round:before {
  content: "\e932";
}
.faw-cross_round:before {
  content: "\e903";
}
.faw-exclamation_point:before {
  content: "\e900";
}
.faw-link:before {
  content: "\e929";
}
.faw-magnifier:before {
  content: "\e92e";
}
.faw-bell:before {
  content: "\e933";
}
.faw-bell_outline:before {
  content: "\e934";
}
.faw-arrow_up_right:before {
  content: "\e935";
}
.faw-arrow_down_right:before {
  content: "\e936";
}
.faw-arrow_up_left:before {
  content: "\e937";
}
.faw-arrow_down_left:before {
  content: "\e938";
}
.faw-star:before {
  content: "\e939";
}
.faw-sort-two:before {
  content: "\e93a";
}
.faw-local-pin:before {
  content: "\e93b";
}
