.game-center {
  background-image: url(../../assets/gamecenter_bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 0 !important;
  &--main {
    &-mobile {
      .game-center--main, &.game-center--main {
        &-container {
          background: none;
          margin-right: -16px;
          margin-left: -16px;
          &--right {
            width: 100%;
            padding: 16px !important;
            gap: 16px;
          }
        }
        &-head {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 24px;
          padding: 16px;
          gap: 8px;
          align-items: stretch;
          &--label {
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
          }
          &--value {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
          }
          &--exchange-btn {
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
            min-width: auto;
          }
        }
        &-body {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          padding: 0;
        }
        &-card {
          background: rgba(0, 0, 0, 0.3) !important;
          border-radius: 24px;
          &--title {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
          }
          &--btn {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            padding: 12px 24px;
          }
        }
        &-open {
          margin: 0;
          width: 100%;
          justify-content: center;
          &--mobile {
            width: calc(100% + 32px);
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(32px);
            padding: 24px;
            border-radius: 40px 40px 0px 0px;
            margin: 0 -16px;
          }
        }
      }
    }
    &-open {
      // @extend .clear;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      z-index: 2;
      padding: 24px 48px;
      gap: 8px;
      margin-top: 45vh;

      background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 52.6%), linear-gradient(90deg, #C85DD7 0%, #F0A920 100%);
      border: none;
      box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.4), 0px 0px 64px rgba(255, 255, 255, 0.5), 0px 4px 32px #000000, inset 0px 0px 32px rgba(255, 255, 255, 0.3);
      border-radius: 16px;

      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 120%;
      color: #FFFFFF !important;
      overflow: hidden;

      text-shadow: 0px 0px 8px #FFFFFF, 1px 1px 0px rgba(0, 0, 0, 0.1);
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -45%;
        width: 40%;
        height: 100%;
        transform: skewX(335deg);
        background-color: white;
        opacity: .5;
        filter: blur(8px);
      }
      &:hover {
        &::after {
          left: 130%;
          transition: left .5s;
        }
      }
      &:disabled {
        filter: grayscale(.6);
      }
    }
    &-container {
      // background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 100%;
      overflow-y: auto;
      
      background-size: 50%;
      background-repeat: no-repeat repeat;
      background-position-x: right;
      &--left {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 10%;
          left: 20%;
          right: 20%;
          bottom: 20%;
          background-image: url(../../assets/gamecenter_frontlogo.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
      &--right, &--left {
        width: 50%;
        // max-width: 672px;
        min-height: 600px;
        // height: 650px;
      }
      &--right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 32px 32px 0px;
        gap: 32px;
      }
    }
    &-head {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      gap: 8px;
      &--my-coin {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        user-select: none;
      }
      &--label {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 120%;
        color: #FFFFFF;
      }
      &--value {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        
        color: #FFFFFF;
      }
      &--exchange-btn {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        
        filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.5));
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        outline: 2px solid #FFFFFF;
        border-radius: 8px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #FFFFFF;

        text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
        border: none !important;
        transition: color .2s, border-color .2s;
        min-width: 145px;
        justify-content: center;
        &:hover, &:focus, &:active {
          color: #F0A920;
          outline: 2px solid #F0A920 !important;
        }
      }
    }
    &-body {
      padding: 0px 16px;
      // display: flex;
      // flex-direction: row;
      // align-items: flex-start;
      // justify-content: space-between;
      // gap: 32px;
      // flex-wrap: wrap;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      width: 100%;
    }
    &-card {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;

      // max-width: 180px;
      min-width: 160px;
      flex: 1;
      // width: 100%;
      &:hover {
        background-image: url(../../assets/rect_top_left.png), url(../../assets/rect_top_right.png), url(../../assets/rect_bottom_left.png), url(../../assets/rect_bottom_right.png);
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: left top, right top, left bottom, right bottom;
      }
      &:hover & {
        &--btn {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(93.1deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 101.31%), #F0A920;
        }
      }
      &--img-container {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      &--body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 4px;
      }
      &--title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        // text-align: center;
        
        color: #FFFFFF;
      }
      &--btn {
        width: 100%;
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 8px 16px;
        background: linear-gradient(93.1deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 101.31%), #F0A920;
        border-radius: 8px;

        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #FFFFFF;

        text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
      }
    }
    &-exchange {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px;
      gap: 24px;
      
      max-width: 400px;
      width: 100%;
      background: #F7F7F7;
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
      border-radius: 24px;
      &--title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        text-align: center;
        
        color: #2E3549;
      }
      &--form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        > span {
          flex: 1;
        }
        &--icon {
          height: 100%;
          i {
            margin-top: 14px;
            font-size: 10px;
            color: #4B5677;
          }
        }
      }
      &--info {
        display: flex;
        padding: var(--sp-3, 12px) var(--sp-4, 16px);
        align-items: center;
        gap: 8px;
        align-self: stretch;

        border-radius: var(--r-3, 12px);
        border: 1px solid var(--strokes-main-darken, #E0EBF6);
        background: var(--background-additional, #F3F5FB);
        &-icon {
          color: #414B67;
          font-size: 16;
        }
        &-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--sp-1, 4px);
          flex: 1 0 0;
        }
        &-title {
          color: var(--colors-ebony-clay, #2E3549);
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
        }
        &-text {
          color: var(--colors-blue-bayoux, #6C7284);
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
      &--devider {
        border: 1px solid #FFFFFF;
        height: 0px;
        width: 100%;
      }
      &--total {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        font-family: 'Nunito';
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        color: #2E3549;
        margin-bottom: -8px;
        &-label {
          font-weight: 400;
        }
        &-value {
          font-weight: 700;
        }
      }
      &--action {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        button {
          flex: 1;
        }
        &-cancel {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px 24px;
          
          width: 160px;
          height: 48px;
          
          border: 2px solid #F0A920;
          border-radius: 12px;

          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;

          color: #F0A920;
          background: transparent;
          &:hover {
            background: #F0A920;
            border: 2px solid #F0A920;
            color: #FFFFFF;
          }
          &:focus {
            background: transparent;
            border: 2px solid #FEAB68;
            outline: 4px solid #FEAB68;
            color: #F0A920;
          }
          &:active, &.active {
            background: #994401;
            color: #FFFFFF;
            border: 2px solid #994401;
          }
          &:disabled, &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
        &-confirm {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px 24px;
          
          width: 160px;
          height: 48px;
          
          border: 2px solid transparent !important;
          border-radius: 12px;

          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;

          color: #fff;
          background: #F0A920;
          &:hover {
            background: #994401;
          }
          &:focus {
            outline: 4px solid #FEAB68;
          }
          &:active, &.active {
            background: #7F3901;
          }
          &:disabled, &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
      }
    }
  }
  &--notify-bg {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
    backdrop-filter: blur(32px);
  }
}
