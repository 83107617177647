
.anno {
  &--load-more {
    width: 100%;
    margin-top: 16px;
  }
  .load-more {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    border:unset;

    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #009DC4;
    &:hover {
        color: #327DA0;
    }
    &:active {
        color: #327DA0;
    }
    &:focus:not(:active) {
        color: #327DA0;
        text-decoration-line: underline;
    }
  }
  &--wrap {
    max-width: 1200px;
    width: 100%;

  }
  &--container {
    display: flex;
    justify-content: center;
  }
  &--inner {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  &--item {
    display: flex;
    width: 808px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    border-radius: 24px;
    background: #FFF;
    &-date {
      color: #3F3F4B;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%;
    }
    &-title {
      color: #353451;
      font-family: Rubik;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
    &-body {
      width: 100%;
      color: #3F3F4B;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%;
      padding: 0% !important;
      video {
        max-width: 100%;
        border-radius: 24px;
        background-color: #000;
      }
      img { 
        max-width: 100%;
      }

    }
  }
}
.mobile {
  .anno {
    &--item {
      width: 100%;
      &-title {
        font-size: 24px;
      }
      &-body {
        font-size: 16px;
      }
    }
  }
}