.three {
  margin-top: 153px;
  width: 1224px;
  // height: 1281px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-bottom: 80px;
  > * {
    width: 100%;
  }
  .title {
    color: #353451;
    font-family: Rubik;
    font-size: 38px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    span:not(:last-child) {
      margin-right: .2em;
    }
    
    :global(.blue) {
      color: #3F50EC;
    }
    :global(.gray) {
      color: #6C6C78;
    }
    :global(.pink) {
      color: #DE3FEC;
    }
    img {
      width: 137px;
      height: 137px;
      float: right;
      margin-top: 13px;
      // margin-right: 85px;
    }
  }
}
.item {
  display: flex;
  justify-content: space-between;
  &_left {

  }
  &_right {
    display: flex;
    max-width: 935px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &_number {
    --color-rect: #94959e;
    position: relative;

    font-family: Rubik;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    -webkit-text-stroke: 1px #232330;
    -webkit-text-fill-color: #fff0;
    span {
      position: relative;
      z-index: 1;
    };
    &::before {
      position: absolute;
      content: '';
      display: block;
      background-color: var(--color-rect);
      bottom: 0;
      left: 0;
      right: 0 ;
      height: 26px;
    }
  }
  &_title {
    color: #353451;
    font-family: Rubik;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  &_text {
    color: #3F3F4B;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }
}
