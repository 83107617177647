.card {
    li {
        & + li {
            margin-top: 23px;
        }
    }
}
.confirmation {
    &-container {
        font-family: Nunito, sans-serif;
        .card {
            padding: 32px;
        }
        [class*=" image-gallery-"] {
            i {
                color:white;

                display: block;
                font-size:1.75rem;
            }
            button[type="button"] {
                border: none;
                outline: unset !important;
            }
        }
        .image-gallery-slide {
            &:focus-visible {
                outline: unset;
            }
        }
        .image-gallery-icon:focus {
            outline: unset !important;
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
            &:hover {
                i {
                    color:rgb(240, 169, 32);
                }
            }
        }
        .image-gallery-left-nav {
            padding: 48px 20vw 48px 48px;
            i {
                transform:rotate(180deg);
            }
        }
        .image-gallery-right-nav {
            padding: 48px 48px 48px 20vw;
        }
        .image-gallery-thumbnail {
            &.active {
                pointer-events: none;
            }
        }
    }
}
