// TOPOGRAPHY
@mixin bold__title_1 {
  font-size: 28px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
@mixin bold__title_2 {
  font-size: 21px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@mixin bold__body_2 {
  font-size: 16px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

@mixin bold__body_1 {
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@mixin bold__caption_1 {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

@mixin regular__body_2 {
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin regular__caption_1 {
  font-size: 12px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin medium__body_2 {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
