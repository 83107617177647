@mixin height-768 {
    @media (max-height: 768px) { @content; }
}
.lumberyard {
    overflow: hidden;
    // background-image: url('../../../public/green_field_background.png');
    // background-size: cover;
    // background-position-y: center;
    //margin-top: 78px;
    // padding: 32px;
    position: relative;
    .content {
        height: calc(100vh - 120px);
        &.mobile {
           height: calc(100vh - 253px);
        }
    }
    .store {
        display:none;
        position:absolute;
        visibility: hidden;
        top:0;
    }
    .plots {
        z-index:1000;
        p {
            color: #2E3549;
        }
        &__icon {
            padding: 8px 18px 10px;
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            text-align: center;
            &:hover, &.active {
                background: rgba(255, 255, 255, 0.75);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(32px)
            }
            &_label {
                margin-top:6px;
                font-size: 0.75rem;
                margin: 6px 0px 0px 0px;
            }
            img {
                width: 32px;
                height: 32px;
            }
        }
        .card {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 16px;
            top: 0px;
            z-index:0;
            .list-group {
                width: 224px;
                scrollbar-width: thin;
                overflow-y: auto;
                overflow-x: clip;
                max-height: calc(100vh - 479px);
                &-item {
                    background-color: transparent;
                    border-radius:8px;
                    &.current, &.canactivate:hover {
                        background-color: #ffffff;
                    }
                    & + .list-group-item {
                        margin-top: 12px;
                    }
                }
                &::scrollbar {
                    width: 4px;
                    border-radius: 2px;
                    background-color: #B5BDCA; /* or add it to the track */
                }
                &::scrollbar-thumb {
                    border-radius: 2px;
                    background: #231E2F;
                }
                &::-webkit-scrollbar {
                    width: 4px;
                    border-radius: 2px;
                    background-color: #B5BDCA; /* or add it to the track */
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 2px;
                    background: #231E2F;
                }
            }
        }
    }
    .book {
        &__link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 16px;
            padding: 12px;
            img {
                width: 24px;
                height: 24px;
            }
        }
        &__label {
            font-weight: 700;
            font-size: .75rem;
            color: #2E3549;
            margin-left: 8px;
        }
    }
    .shop {
        &__button {
            cursor: initial;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            display: flex;
            align-items:center;
            border-radius:16px;
            padding: 8px 16px 8px 8px;
            font-size: 0.75rem;
            font-weight: 700;
            max-width: 112px;
            color: #2E3549;
            & + .shop__button {
                margin-top: 16px;
            }
            & .button {
                &__icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    &-wrap {
                        position: relative;
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            hr {
                width: 1px;
                height: 31px;
                margin-top: 0;
                margin-bottom: 0;
                margin-left: 8px;
                margin-right: 9px;
                background-color: #E0EBF6;
                opacity:1;
            }
            svg {
                //margin-left: 17px;
            }
        }
        &__wrap {
            .card {
                position: absolute;
                background: rgba(255, 255, 255, 0.75);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(32px);
                top: -272px;
                left: -50%;
                border-radius:16px;
                z-index: 1;
                h5 {
                    color: #2E3549;
                    color: #2E3549;
                    font-size:1.3125rem;
                    font-weight:700;
                    margin-bottom: 5px;
                }
                .h5 {
                    margin-bottom: 10px;
                }
                .text-muted {
                    font-size: .75rem;
                    font-weight:400;
                    color: #4B5677;
                }
                .amount {
                    font-size: 1.125rem;
                    span {
                        font-size: .75rem;
                    }
                }
                .footer {
                    margin-top: 14px;
                    .btn {
                        border-radius: 8px;
                        // background: #3A324E;
                        font-size: .75rem;
                        font-weight: 600;
                    }
                }
                img {
                    width: 42px;
                    height: 42px;
                }
                .label {
                    font-weight:700;
                    font-size: .75rem;
                    color: #4B5677;
                    margin-bottom: 6px;
                }
            }
        }
    }
    h1 {
        font-family:'McLaren';
        font-size: 2.375rem;
        font-weight: 200;
        color: #3B500C;
        margin-bottom: 4px;
    }
    h5 {
        font-size: 1.125rem;
        font-weight: 700;
        color: #48620E;
    }
    .tree {
        position: relative;
        &__animation {
            &.axe {
                width: 7.8vw;
                height: 7.8vh;
                left: -10%;
                right: 0;
                margin: auto;
                top: unset;
                margin-bottom: 0;
                bottom: calc(9.8vw - 8%);
            }
            &.drops {

                width:12vw;
                height:12vh;
                top: -25%;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                //top: -10%;
                //left: 14%;
            }
        }
        &__container {
            position:relative;
            flex-direction: column;
            height: 48vh;
            display: flex;
            justify-content: flex-end;
            & + .tree__container {
                margin-left: 32px;
            }
            .pointer {
                text-align: center;
                color: #3B500C;
                margin-bottom: 28px;
                div {
                    font-size: 1.75rem;
                }
                .faw-arrow_down {
                    display:block;
                    color: #3B500C;
                    font-size: 1.125rem;
                    animation-duration: 0.8s;
                    animation-name: pointer;
                    animation-iteration-count: infinite;
                }
            }
            .clock {
                position:absolute;
                margin: auto;
                width: 48px;
                height: 48px;
                top: -70px;
                left: 0;
                right: 0;
                bottom: 0;
                img {
                    width: 48px;
                    height: 48px;
                }
            }
            .icon {
                position:absolute;
                top: calc(-10% - 32px);
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                margin: 0 auto;

            }
        }
        &__nimbus {
            position:absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: #ffffff;
            mix-blend-mode: overlay;
            filter: blur(96px);
            border-radius: 200px;
        }
        &_empty {
            height: 16vh;
        }
        &1 {
            height: 17vh;
        }
        &2 {
            height: 18vh;
        }
        &3 {
            height: 27vh;
        }
        &4 {
            height: 36vh;
        }
        &5 {
            height: 32vh;
        }
        &6 {
            height: 39vh;
        }
    }
    .dotted {
        margin-top: 24px;
        &__label {
            font-size: 0.75rem;
            @include height-768 {
                font-size: 0.5625rem;
            }
            //font-size:0.5625rem;
            color: #2E3549;
            text-align: center;
            margin-bottom: 2px;
        }
        &__line {
            display: flex;
            margin:auto;
            justify-content:center;
            border-radius:10px;
            background-color: rgba(35, 30, 47, 0.3);
            width: fit-content;
            padding: 4px;
        }
        &__item {
            height: 12px;
            //height:4px;
            //width:16px;
            //border-radius: 4px;
            //background-color: rgba(59, 80, 12, 0.5);
            img {
                width: 12px;
                height: 12px;
                opacity: .5;
                margin-top:-15px;
            }
            &.filled {
                //background-color: rgba(59, 80, 12, 1);
                img {
                    opacity:1;
                }
            }
            & + .dotted__item {
                margin-left: 2px;
            }
        }
    }
    .logo {
        width: 14.6vw;
        //height:12vh;
    }
    .qm {
        margin-left: 18px;
        width: 32px;
        height: 32px;
    }
    .btn {
        border-radius: 12px;
        border: unset;
        padding: 12px 24px;
        font-size: 1rem;
        height: 48px;

        &-info {
            background-color:#51466D;
            color:#ffffff;
            &:hover {
                color: #ffffff;
                background-color: #3A324E;
            }
            &:active {
                color: #ffffff;
                background-color: #231E2F;
            }
        }
        &-link {
            color: #009DC4;
            &:hover {
                color: #327DA0;
            }
            &:active {
                color: #327DA0;
                text-decoration: underline;
            }
        }
        &-outline-warning {
            color:#FE7202;
            border: 2px solid #FE7202;
            line-height: 1;
            &:hover {
                color: #ffffff;
                background-color:#FE7202;
            }
            &:active {
                color: #ffffff;
                background-color:#994401;
            }
        }
        &-warning {
            background-color:#FE7202;
            color: #ffffff;

            &:hover {
                color: #ffffff;
                background-color: #994401;
            }
            &:active {
                color: #ffffff;
                background-color: #7F3901;
            }
        }
    }
    .tint {
        position: fixed;
        top: 61px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 61px);
        background: rgba(35, 30, 47, 1);
        //backdrop-filter: blur(32px);
        //opacity: .75;
        &__rays {
            left: 0vw;
            top: -48vh;
            width: 100vw;
            height: 100vw;
            margin: auto;
            position: fixed;
            background-image: url('../../../src/assets/rays.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            animation:spin 40s linear infinite;
        }
        &__content {
            height: fit-content;
            z-index: 1;
            margin-top: auto;
            margin-bottom: auto;
            pointer-events: none;
            position: fixed;
            margin: auto;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            h4 {
                font-size: 2.375rem;
                color: #ffffff;
                margin-bottom: 48px;
            }
            p {
                color: #FBFE68;
                font-size: 1.3125rem;
                margin-bottom: 32px;
                font-weight: 400;
            }
            .btn-outline-light {
                font-weight: 600;
                border-color: #ffffff;
                font-size: 1.3125rem;
                text-transform: uppercase;
                border: 2px solid #ffffff;
                width: auto;
                padding: 16px 32px;
                line-height: 16px;
                height: 57px;
                border-radius: 16px;
                margin-top: 48px;
                pointer-events: all;
            }
            .diamond {
                width: 18vw;
                height: 25vh;
                display: block;
            }
        }
    }
    .zoom {
        transition: transform 1s;
        &:hover {
            transform: scale(1.4);
        }
    }
    .arrow-pointer {
        transition: transform 1s;
    }
    .warehouse {
        min-width: 240px;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        &__item {
            color: #2E3549;
            & + .warehouse__item {
                margin-top: 8px;
            }
        }
        img {
            width: 32px;
            height: 32px;
            @include height-768 {
                width: 32px;
                height: 32px;
            }
        }
    }
    .modal {
        .card {
            padding: 32px;
            background-color: #F7F7F7;
            border-radius: 24px;
            &-footer {
                border-top-color: #ffffff;
                background-color: #F7F7F7;
            }
        }
        &-purchase {
            .card {
                min-width: 380px;
                max-width: 380px;
            }
            .h5 {
                color: #2E3549;
                font-size: 1.75rem;
                font-weight: 700;
                margin-bottom: 24px;
            }
            p {
                color: #4B5677;
                font-size:1rem;
                font-weight:400;
                margin-bottom: 24px;
                .text-bold {
                    font-weight:700;
                }
            }
        }
        &-shop {
            h5 {
                font-size: 1.75rem;
                color: #2E3549;
                font-weight:700;
            }
            &.seeds {
                img {
                    width: 64px;
                }
                h5 {
                    margin-bottom: 11px;
                    margin-top: 4px;
                }
            }
            &.water {
                img {
                    width: 48px;
                    margin-top:-12px
                }
                .h5 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                h5 {
                    margin-bottom: 0px;
                    margin-top: 0px;
                }
                .text-muted {
                    margin-bottom: 18px;
                }
            }
            .title {
                svg {
                    margin-left: 8px;
                    width: 24px;
                    height: 24px;
                }
            }
            .label {
                color: #4B5677;
                font-weight:700;
                font-size: 0.75rem;
                margin-bottom: 8px;
            }
            .amount {
                color: #2E3549;
                font-size: 1.125rem;
                span {
                    font-size: .75rem;
                }
            }
            .text-muted {
                font-size: .75rem;
                font-weight: 400;
                color: #4B5677 !important;
            }
            input {
                font-weight: 700;
                border-radius: 20px;
                border: 1px solid #B5BDCA;
                background-color: transparent;
                text-align: center;
                width: 200px;
                margin-left: 9px;
                color: #2E3549;
                margin-right: 9px;
                font-size: 1.125rem;
            }
        }
    }
    .congratulations {
        &.card {
            width: 380px;
        }
        .h5 {
            color: #2E3549;
            font-size: 2.375rem;
        }
    }
    .icon {
        width: 40px;
        height: 40px;
        position:relative;
        z-index: 1;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 12px;
        img {
            width: 24px;
            height: 24px;
            margin: auto;
            position:absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}
.ly {
    &-tooltype {
        padding: 2px 8px;
        background: #231E2F;
        border-radius: 4px;
        margin-left: 8px;
        width: 30vw;
        z-index:20000;

        &.question {
            right:16px !important;
        }

        .tooltype--inner {
            color: #ffffff;
            font-size: 1.125rem;
            @include height-768 {
                font-size: 0.75rem;
            }
            font-weight: 400;
        }
    }
}
@mixin mobile_small {
    @media (max-height: 669px) { @content; }
}
.mobile {
    .ly-tooltype {
        width: 50vw;
        z-index: 20000;
    }
    .lumberyard {
        overflow: auto;
        padding: unset;
        height: calc(100vh - 110px);
        .tint {
            height: calc(100vh - 53px);;
            top: 53px;
        }
        &__container {
            height: calc(100% - 32px);
            margin: 16px auto;
        }
        &__trees {
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 10px;
            margin-bottom: 60px;
            @include mobile_small {
                margin-bottom: 32px;
            }
        }
        .tree {
            &__container {
                &:first-child {
                    margin-left: 16px;
                }
                &:last-child {
                    margin-right: 16px;
                }
            }
        }
        .logo {
            width: 28vw;
            height: auto;
        }
        footer {
            > div:not(:last-child) {
                padding-right: 8px;
                margin-right: 8px;
                border-right:  1px solid rgba(0, 0, 0, 0.1);
            }
            .shop {
                &__button {
                    min-width: 104px;
                    max-width: 104px;
                    padding: 5px 9px 4px;
                    border-radius: 12px;
                    .button {
                        &__icon-wrap {
                            &.seeds {
                                width: 24px;
                                height: 24px;
                            }
                            &.water {
                                width: 16px;
                                height: 16px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

            }
        }
    }
    .modal {
        &-ly-plots {
            font-family: 'Nunito', sans-serif;
            margin: auto 16px;
            &.modal-dialog {
                margin-top: 35.5px;
                margin-bottom: 35.5px;
                height: 100%;
                position: relative;
            }
            .modal {

                &-content {
                    background-color:#F7F7F7;
                    border-radius:24px;
                    padding: 16px 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: fit-content;
                    max-height: calc(100vh - 153px);
                }
                &-header {
                    border-bottom: unset;
                    padding: 0px;
                    padding-bottom: 20px;
                    .card-title {
                        font-size: 1.75rem;
                    }
                }
            }
            .plots {
                position:static !important;
                z-index:1000;
                p {
                    color: #2E3549;
                }
                &__icon {
                    padding: 8px 18px 10px;
                    background: rgba(255, 255, 255, 0.3);
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                    text-align: center;
                    &:hover, &.active {
                        background: rgba(255, 255, 255, 0.75);
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                        backdrop-filter: blur(32px)
                    }
                    &_label {
                        margin-top:6px;
                        font-size: 0.75rem;
                        margin: 6px 0px 0px 0px;
                    }
                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
                .card {
                    background: #F7F7F7;
                    border: unset;
                    backdrop-filter: blur(32px);
                    border-radius: 24px;
                    z-index:0;
                    &-body {
                        padding: 0px !important;
                    }
                    .list-group {
                        scrollbar-width: thin;
                        overflow-y: auto;
                        overflow-x: clip;
                        max-height: calc(100vh - 297px);
                        margin: auto;
                        &-item {
                            background-color: transparent;
                            border-radius:8px;
                            &.current, &:hover {
                                background-color: #ffffff;
                                cursor:pointer;
                            }
                            & + .list-group-item {
                                margin-top: 20px;
                            }
                        }
                        &::scrollbar {
                            width: 4px;
                            border-radius: 2px;
                            background-color: #B5BDCA; /* or add it to the track */
                        }
                        &::scrollbar-thumb {
                            border-radius: 2px;
                            background: #231E2F;
                        }
                        &::-webkit-scrollbar {
                            width: 4px;
                            border-radius: 2px;
                            background-color: #B5BDCA; /* or add it to the track */
                        }
                        &::-webkit-scrollbar-thumb {
                            border-radius: 2px;
                            background: #231E2F;
                        }
                    }
                }
            }
        }
        &-ly-warehouse {
            margin: auto 16px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: fit-content;
            color: #2E3549;
            .modal {
                &-content {
                    font-family: 'Nunito', sans-serif;
                    border-radius:24px;
                    background-color:#F7F7F7;
                }
                &-footer {

                }
            }
            .card {
                border-radius: 8px;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                background: rgba(255, 255, 255, 0.75);
                border: unset;
                & + .card {
                    margin-top: 16px;
                }
                &.session__timer {
                    hr {
                        background-color:#E0EBF6;
                    }
                }
                &.warehouse {
                    .warehouse {
                        &__item {
                            & + .warehouse__item {
                                margin-top: 8px;
                            }
                        }
                    }
                }
                &.rules {
                    padding: 16px 20px;
                    .card-body {
                        padding: 0;
                        .book__title {
                            font-size: 1rem;
                            margin-left: 13px;
                        }
                    }
                }
            }
        }
        &-shop {
            width: 100%;
            margin: auto 16px;
            input {
                width: auto;
            }
        }
        &-purchase {

            .card {
                min-width: unset;
                max-width: unset;
                width: calc(100vw - 32px);
            }
        }
    }
    .congratulations {
        .h5 {
            font-size: 1.5rem;
            margin-bottom: 24px;
        }
        &.card {
            width: calc(100vw - 32px);
        }
    }
    .tree {
        &__nimbus {
            display:none;
        }
        &__animation {
            &.drops {
                width: 52vw;
                height: 16vh;
                @include mobile_small {
                    width: 32vw;
                    height: 16vh;
                }
            }
            &.axe {
                width: 28vw;
                height: 10vh;
                top: unset;
                left: -9%;
                margin-bottom: 0;
                bottom: calc(32vw - 8%);
            }
        }
        &__container {
            height: 54vh;
            .position-relative {
                text-align: center;
            }
            @include mobile_small {
                height: 49vh;
                min-width: 220px;
            }
            & + .tree__container {
                margin-left: 8px;
            }
            .icon {
                @include mobile_small {
                    top: calc(10% - 64px);
                }
            }
        }
        &1 {
            height: 17vh;
            @include mobile_small {
                height: 11vh;
            }
        }
        &2 {
            height: 18vh;
            @include mobile_small {
                height: 11vh;
            }
        }
        &3 {
            height: 27vh;
            @include mobile_small {

            }
        }
        &4 {
            height: 36vh;
            @include mobile_small {
                height: 33vh;
            }
        }
        &5 {
            height: 32vh;
            @include mobile_small {
                height: 33vh;
            }
        }
        &6 {
            height: 39vh;
            @include mobile_small {
                height: 33vh;
            }
        }
    }
    .lumberyard {
        .tint__rays {
                top: 10vw;
        }
    }
    .tint {
        &__rays {
            left: -50vw;
            top: 0;
            width: 200vw;
            height: 200vw;
        }
        &__content {
            h4 {
                font-size: 1.75rem;
            }
            .diamond {
                width: 65vw;
                height:auto;
            }
        }
    }
}
@keyframes pointer {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(25px);
    }
}
