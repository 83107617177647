:root {
  --landing-top-height: 808px;
}

.landing {
  &__menu {
    &__wrap {
      z-index: 10;
      width: 100%;
      padding: 16px;
      position: relative;
    }
  }
}

.top,
.top * {
  box-sizing: border-box;
}
.top_bg {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  height: var(--landing-top-height);
  width: 100%;
  top: 0px;
  left: 0px;
  // background: linear-gradient(
  //   158.68deg,
  //   rgba(71, 83, 191, 1) 0%,
  //   rgba(255, 255, 255, 0.55) 100%
  // );
  background: linear-gradient(180deg, rgb(71, 83, 191) 0%, rgb(255 255 255 / 0%) 90%);
}
.top {
  // background: linear-gradient(
  //   158.68deg,
  //   rgba(71, 83, 191, 1) 0%,
  //   rgba(255, 255, 255, 0.55) 100%
  // );
  width: 1242px;
  height: var(--landing-top-height);
  position: relative;
  // z-index: 2;
}
.blur {
  pointer-events: none;
  width: 1600px;
  height: 872px;
  position: absolute;
  left: calc(50% - 804px);
  top: 26px;
}
.ellipse-4 {
  background: #28bdde;
  border-radius: 50%;
  opacity: 0.5;
  width: 657px;
  height: 657px;
  position: absolute;
  left: 0px;
  top: 0px;
  filter: blur(200px);
}
.ellipse-3 {
  background: #a428de;
  border-radius: 50%;
  opacity: 0.699999988079071;
  width: 728px;
  height: 872px;
  position: absolute;
  right: 0px;
  top: 0px;
  filter: blur(200px);
}
.img {
  pointer-events: none;
  pointer-events: none;
  user-select: none;
  width: 851px;
  height: 790px;
  position: absolute;
  // left: 721px;
  // top: 104px;
  left: 420px;
  top: 105px;
  z-index: 2;
}
._1-1 {
  width: 851px;
  height: 773px;
  position: absolute;
  left: 0px;
  top: -3px;
}
.ellipse-5 {
  width: 674px;
  height: 675px;
  position: absolute;
  left: 121px;
  top: 95px;
}
.new-world {
  display: none;
  width: 196.24px;
  height: 129.91px;
  position: absolute;
  left: 357px;
  top: 360.83px;
  transform-origin: 0 0;
  transform: rotate(-6.092deg) scale(1, 1);
}
.frame-4116 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 34.56px;
  top: 28.27px;
}
.we-build {
  color: #ffffff;
  text-align: left;
  font: 900 16px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.frame-4117 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 12.29px;
  top: 56.04px;
}
.new-worlds {
  color: #ffffff;
  text-align: left;
  font: 900 16px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.title {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 87px;
}
.frame-4225 {
  user-select: none;
  flex-shrink: 0;
  width: 798px;
  height: 248px;
  position: relative;
  z-index: 2;
}
.inter-world {
  color: #ffffff;
  text-align: left;
  font: 900 130px/100% "Rubik", sans-serif;
  margin-left: -7px;
}
.expmetvers {
  color: #ffffff;
  text-align: left;
  font: 400 28px/150% "Rubik", sans-serif;
  margin-top: 24px;
  width: 598px;
}
.button {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button2 {
  background: #bcfc37;
  border-radius: 40px;
  padding: 2px 2px 2px 48px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  &:hover, &:focus {
    background: #DDFD9B;
  }
}
.join-to-metaverse {
  color: #353451;
  text-align: left;
  font: 400 18px/150% "Rubik", sans-serif;
  position: relative;
}
.frame-4109 {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: relative;
}
.ellipse-1 {
  background: #ffffff;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon-arrow-right-up {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  overflow: visible;
}
.join {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-4111 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.group-28 {
  flex-shrink: 0;
  width: 90.67px;
  height: 32px;
  position: static;
}
.unsplash {
  background: linear-gradient(to left, #c4c4c4, #c4c4c4);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  &:not(:first-child) {
    margin-left: -16px;
  }
}
._200-members-joined {
  color: #3f3f4b;
  text-align: left;
  font: 300 18px/150% "Rubik", sans-serif;
  position: relative;
}
.menu {
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pasted-20230710-142852-1-1 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.nav_container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.item {
  color: var(--text-color, #ffffff);
  text-align: left;
  font: 400 16px/150% "Rubik", sans-serif;
  position: relative;
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
  &.active, &:active {
    color: var(--hover-color, #F3F5FB);
    text-decoration: underline;
  }
}

.desk-header-right-side {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 36px;
  position: relative;
}
.button-small {
  background: #ffffff;
  border-radius: 40px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  // box-shadow: 0px 20px 20px 0px rgba(60, 57, 209, 0.15);
  text-decoration: none !important;
  user-select: none;

  &:hover, &:focus {
    background: #F3F5FB;
    color: #353451;
  }
  &.dark {
    background: #7000BF;
    .log-in, & {
      color: #ffffff;
    }
    &:hover, &:focus {
      background: #9B1EF2;
    }
  }
}
.log-in {
  color: #353451;
  text-align: left;
  font: 400 16px/150% "Rubik", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sign-up {
  color: var(--text-color, #ffffff);
  text-align: left;
  font: 400 16px/150% "Rubik", sans-serif;
  position: relative;
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
  &.active, &:active {
    color: var(--hover-color, #F3F5FB);
    text-decoration: underline;
  }

}
.language {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  text-transform: capitalize;
}
.en {
  text-align: left;
  font: 400 16px/150% "Rubik", sans-serif;
  position: relative;

  color: var(--text-color, #ffffff);
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
}
.icon-down {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.top_btn {
  &__container {
    z-index: 10;
    margin-top: 40px;
    display: flex;
    gap: 8px;
  }
  &__download {
    user-select: none;
    display: flex;
    height: 48.2px;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;

    border-radius: 8px;
    background: #353451;
    svg {
      width: 25px;
      height: auto;
    }
    span {
      white-space: nowrap;
      color: #FFF;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: 1px;
    }
  }
}
