@use "./redesign/mixins.module.scss" as *;

.redesign {
    &-modal {
        &-conteiner {
            display: flex;
            max-height: 100%;
            min-width: 480px;
            max-width: var(--modal-max-width, 600px);
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            border-radius: 24px;
            overflow: hidden;
            
            box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.17);
            &.scroll-body {
                max-height: 100%;
                .redesign-modal-body {
                    overflow-y: auto;
                }
            }
        }

        &-header {
            display: flex;
            padding: 16px 24px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-bottom: 1px solid  $color-main-dark;
            background-color: $color-main; 
            text-overflow: ellipsis;
            font-size: 28px;
            font-weight: 700;
            line-height: 120%;
        }

        &-body {
            width: 100%;
            display: flex;
            padding:  24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            overflow-y: auto;
            background-color: $color-white;
        }

        &-footer {
            display: flex;
            padding:  16px 24px;
            justify-content: right;
            align-items: flex-start;
            gap: 24px;
            border-top: 1px solid $color-main-dark;
            width: 100%;
            background-color: $color-white;
        }
    }
}

.mobile {
    &.redesign {
        &-modal-page{

        }
    }
    .redesign {
        &-modal {
            &-conteiner {
                border-radius: 0px;
                min-width: 100%;
                width: 100%;
                height: 100%;
            }
    
            &-header {
                @include bold__title_2;
                line-height: 1;
                display: flex;
                padding: var(--sp-4, 16px);
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
            }
    
            &-body {
                flex: 1;
                max-width: 100%;
                overflow: auto;
                padding: 16px;
                gap: 16px
            }
    
            &-footer {
                gap: 16px;
                > * {
                    flex: 1;
                    width: 100%;
                }
            }
        }
    }
}

.modal {
    &-abey-wallet {
        .card {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 24px !important;
        }
        .icon {
            font-size: 2.75rem;
            margin: auto;
            margin-bottom: 18px;
        }
        .h5 {
            font-weight: 700;
            font-size: 38px;
            line-height: 120%;
            color: #2C3653;
        }
        .alert {
            border-radius: 16px;
            max-width: 574px;
            svg {
                //margin-left: 100px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
                color: #2E3549;
                margin-bottom: 0px;
            }

        }
        p {
            max-width: 574px;
        }
        .card-title {
            max-width: 574px;
        }
        .form {
            &-control {
                &-plaintext {
                    font-weight: 700;
                    font-size: 16px;
                    color: #2E3549;
                    width: 406px;
                    padding: 0;
                    font-family: Courier;
                }
            }
            &-label {
                font-weight: 400;
                font-size: 12px;
                color: #4B5677;
            }
        }
    }
    &-image-gallery {
        .gallery-wrapper {
            display: flex;
            height: 100vh;
            flex-direction: column;
        }
        h1 {
            color: #ffffff;
            font-size:2.375rem;
            font-weight:700;
            margin-top:64px;
            margin-bottom: 0px;
            line-height:1;
        }
        .faw-cross {
            cursor: pointer;
            font-size:1.125rem;
            color: white;
            position: absolute;
            display: block;
            right:32px;
            top:32px;
            border: 2px solid white;
            padding: 13px;
            border-radius:  32px;
            &:hover {
                color:rgb(240, 169, 32);
                border-color: rgb(240, 169, 32);
            }
            .mobile & {
                padding: 4px;
                font-size: 0.65rem;
            }
        }
        .image-gallery {
            width: 100vw;
            height: calc(100% - 102px);
            &-svg {
                width:16px !important;
                height:28px !important;
            }
            &-slide-wrapper {
                height:calc(100% - 112px);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-swipe {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-content {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
            }

            &-image {
                width: 31vw !important;
                height: 31vw !important;
                max-width: 594px !important;
                max-height: 594px !important;
                .mobile & {
                    width: 31vh !important;
                    height: 31vh !important;
                }
                display: none;
                .mobile & {
                    display: block;
                }
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 32px;
            }
            &-left-nav, &-right-nav {
                .mobile & {
                    padding: 0px 24px;
                }
            }
            &-thumbnail {
                width: 64px;
                border: unset;
                &s {
                    padding: unset;
                    &-wrapper {
                        padding: 16px 0px 32px ;
                    }
                }
                &:not(:first-child) {
                    margin-left: 16px;
                }
                &.active, &:hover {
                    outline: none;
                    border: none;
                }
                &-image {
                    width:64px !important;
                    height:64px !important;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 8px;
                }
            }
        }
    }
    &-pictures-viewer {
        .gallery-wrapper {
            display: flex;
            flex-direction: column;
        }
        .faw-cross {
            cursor: pointer;
            font-size:1.125rem;
            color: white;
            position: absolute;
            display: block;
            right:32px;
            top:32px;
            border: 2px solid white;
            padding: 13px;
            border-radius:  32px;
            z-index: 1;
            &:hover {
                color:rgb(240, 169, 32);
                border-color: rgb(240, 169, 32);
            }
            .mobile & {
                right:24px;
                top:32px;
                padding: 13px;
                font-size: 1.125rem;
            }
        }
        .image-gallery {
            width: 100vw;
            height: calc(100% - 102px);
            &-svg {
                width:16px !important;
                height:28px !important;
            }
            &-slide-wrapper {

            }
            &-swipe {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-content {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
            }

            &-image {
                width: 31vw !important;
                height: 31vw !important;
                max-width: 594px !important;
                max-height: 594px !important;
                .mobile & {
                    width: 31vh !important;
                    height: 31vh !important;
                }
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 32px;
            }
            &-left-nav, &-right-nav {
                .mobile & {
                    margin-top: -47.5px;
                    padding: 0px 24px;
                }
            }
        }
        .cart-container {
            .mobile & {
                padding: unset;
            }
        }
        .market-buildings {
            &--table-item--img-pic {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
            &--table-item--img-view {
                width: calc(100vw - 32px);
                height: calc((100vw - 32px) / 2);
                border-radius: 16px;
                max-height: calc(100vh - 280px);
                &.is-video-mobile {
                    height: fit-content;
                    .events-page--overlay-video {
                        height: 50px;
                    }
                }
            }
            &--slide-button {
                .mobile & {
                    margin-top: 32px;
                }
            }
        }
    }
    .gold-rush-modal{

        .modal-content {
            padding: 24px;
            background: #F7F7F7;
            -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
            border-radius: 24px;
            border: unset;
            .modal-header {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 120%;
                color: #2E3549;
                border: 0;
                padding-right: 0;
                padding-left: 0;
            }
            .modal-body{
                padding-right: 0;
                padding-left: 0;
                .info-text{
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    text-align: center;
                    color: #4B5677;
                }
            }
            .modal-footer{
                flex-direction: column;
                padding: 0;
                border: 0;
            }
        }
    }
    &-shovel {
        min-width: 310px !important;
        background-color: #F7F7F7;
        h5 {
            color: #2E3549;
            font-size: 1.75rem;
            margin-bottom: 0;
        }
        .shovel {
            &--question_mark {
                width: 24px;
                height: 24px;
            }
        }
        img {
            width: 48px;
            height: 48px;
        }
        .label {
            font-weight:700;
            font-size: .75rem;
            color: #4B5677;
            margin-bottom: 6px;
        }
        .amount {
            font-size: 1.125rem;
            span {
                font-size: .75rem;
            }
        }
        .nimbus {
            position:absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: inherit;
            //mix-blend-mode: overlay;
            filter: blur(11px);
        }
        &.card {
            border-radius: 24px !important;
        }
        .card {
            &-footer {
                border-top-color: transparent !important;
                div {
                    margin-top: 20px;
                }
            }
        }
    }
    &-wallet-confirmation {
        .card {
            max-width: 414px;
            border-radius: 24px !important;
        }
        .mobile & {
            .card {
                margin: auto;
                top: 30px;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        .h5 {
            color: rgba(44, 54, 83, 1);
            font-weight:700;
            font-size:2.375rem;
        }
        p {
            color: rgba(46, 53, 73, 1);
            font-weight:400;
            font-size:1rem;
        }
        .form-check {
            padding-left: unset;
            display: flex;
            justify-content:center;
            &-label {
                cursor:pointer;
                font-weight:400;
                font-size: 1rem;
                padding-left: 8px;
                line-height: 20px;
                color: rgba(75, 86, 119, 1);
                text-align: left;
            }
            &-input {
                cursor:pointer;
                min-width: 20px;
                min-height: 20px;
                max-width: 20px;
                max-height: 20px;
                float: unset !important;
                margin-left: unset !important;
            }
        }
    }
    &-logout {
        .card {
            min-width: 414px;
            .mobile & {
                padding: 24px;
                width: calc(100% - 32px);
            }
        }
        .icon {
            width:44px;
            height:44px;
            border-radius:32px;
            background-color:#ECAB04;
            color:#ffffff;
            margin:auto;
            display: flex;
            justify-content:center;
            align-items:center;
            font-size: 50px;
            i {
                display: block;
            }
        }
        .card {
            &-title {
                font-size: 2.375rem;
                margin-bottom: 24px;
                margin-top: 18px;
            }
        }
    }
    &-aw-balances {
        .mobile & {
            .card {
                border-radius: 24px;
                width: 100%;
                &-title {
                    //styleName: Bold/Large Title;
                    font-size: 2.375rem !important;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #2C3653;
                    margin-bottom: 24px !important;
                }
            }
            .alert {
                border: unset;
            }
            .form-label {
                font-weight: 400;
                line-height: 1;
                letter-spacing: 0em;
                color: #4B5677 !important;
                margin-bottom: 10px !important;
            }
            .aw-mobile-balances {
                line-height: 1;
                .mobile-balance--abey-value {
                    margin-left: 4px;
                    margin-top: unset;
                    line-height: 1.5;
                }
                &.orange {
                    .faw-abey, .mobile-balance--abey-value {
                        color: #F0A920 !important;
                    }

                }
                &.purple {
                    .faw-abey, .mobile-balance--abey-value {
                        color: #7A85EB !important;
                    }
                }
                &.apple {
                    .faw-abey, .mobile-balance--abey-value {
                        color: #5BBC43 !important;
                    }
                }
            }
            hr {
                background: #FFFFFF;
                margin: 24px 0px;
            }
            .btn-warning {
                border-radius: 12px;
            }
        }
    }
}
[class^="modal-"], [class*=" modal-"] {
    .card {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
    }
}
.mobile {
    .modal {
        overflow: hidden;
        &-abey-wallet {
            .card {
                margin: 16px;
                padding: 24px;
            }
            .h5 {
                font-size: 1.5rem;
                margin-bottom: 0px;
            }
            .form-label {
                margin-bottom: 8px;
            }
        }
        .gold-rush-modal {
            margin: 16px;
        }
        &-ds-wallet {
            .h5 {
                font-weight: 700;
                font-size: 28px;
                line-height: 120%;
            }
        }
        &-pictures-viewer {
            
        }
        
    }
    .market-buildings--table-item--img-view {
        height: calc(100vw - 32px) !important;
        max-height: calc(100vw - 32px) !important;
    }
}

.modal-aw-transfer {
    .card {
        background: #F7F7F7;
    }
    .transfer-group {
        padding: 12px 8px 8px 8px;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #E0EBF6;
        border-radius: 16px;
        .alert {
            background: #FFFFFF;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
        }
        >label {
            font-weight: 600;
            color: #2E3549;
        }
    }
    hr {
        background: #FFFFFF;
        margin: 24px 0;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #4B5677;
    }
    overflow: auto !important;
    .block-data {
        .form-label {
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
        }
        .form-control {
            background-color: #FFFFFF;
            border: none;
        }
    }
    .mobile & {
        h5 {
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
        }
    }
}

.modal-tickets {
    .form-label {
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
    }
    .form-value {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }
}
