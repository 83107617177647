@use "./mixins.module.scss" as *;

.anno {
  &__title {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    color: var(--text-basic, #2E3549);

    @include bold__title_1;
  }
  &__pages {
    &__load_more {
      display: flex;
      padding: var(--sp-2, 8px) 0px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      color: var(--scheme-link, #0BA1E1);

      @include medium__body_2;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      &:hover, &:focus, &:active {
        text-decoration: underline;
      }
    }
    &__container {
      width: 100%;
      max-width: 1281px;
      display: flex;
      padding-bottom: 0px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
    }
    &__item {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      border-radius: 24px;
      background: var(--colors-white, #FFF);
    }
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
    }
    &__date {
      color: var(--text-additional-alt, #6C7284);

      @include regular__caption_1;
    }
    &__title {
      align-self: stretch;

      color: var(--colors-ebony-clay, #2E3549);

      @include bold__title_1;
    }
    &__body {
      margin: 0 !important;
      padding: 0 !important;
      align-self: stretch;

      color: var(--text-additional, #414B67);

      @include regular__body_2;
      video {
        max-width: 100%;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }
  }

}

.mobile {
  .anno {
    &__pages {
      &__container {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.tutorials {
  &__page {
    display: flex;
    align-items: stretch;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    &__pagination {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      min-width: 100%;
    }
    &__item {
      min-width: 20%;
      display: flex;
      // min-height: 180px;
      padding: 16px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;

      border-radius: var(--r-6, 24px);
      background: var(--colors-white, #FFF);
    }
    &__head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
    &__title {
      align-self: stretch;

      color: var(--text-basic, #2E3549);

      @include bold__body_2;
      &.sm {
        font-size: 12px;
      }
    }
    &__size {
      display: flex;
      align-items: flex-start;
      gap: 4px;

      color: var(--text-additional-alt, #6C7284);

      @include regular__caption_1;
    }
  }
}

.mobile {
  .tutorials {
    &__page {
      &__item {
        min-width: 100%;
      }
    }
  }
}
