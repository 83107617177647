$color-orange: #FE7202;
$color-orange-aw: #FF8A2C;

$color-blue: #7A85EB;
$color-blue-pacific: #009DC4;
$color-astral: #327DA0;

$color-accent: #4D0CB9;
$color-accent-dark: #390887;
$color-accent-lite: #7929FF;

$color-white: #FFFFFF;
$color-red: #F44949;

$color-green: #5BBC43;
$color-toxic-green: #BCFC37;
$color-toxic-green-dark: #A0DC24;
$color-toxic-green-lite: #DDFD9B;

$color-primary: #2E3549;
$color-additional: #414B67;
$color-additional-alt: #6C7284;
$color-button: #28262C;
$color-martinique: #3A324E;
$color-disabled: #B5BDCA;

$color-main: #F3F5FB;
$color-main-dark: #E0EBF6;

$br-1: 4px;
$br-2: 8px;
$br-3: 12px;
$br-4: 16px;
$br-5: 20px;
$br-6: 24px;

$base-desctop-width: 1600px;

$orange: #f67205;
$green: #5BBC43;
$primary: #2C3653;
$secondary: #505D77;
$primary-night: #A9B7C5;

$modal-backdrop-bg:#000000;
$modal-backdrop-opacity:0.75;
$link-color: $primary;
$link-decoration: none;
$navbar-padding-y: 1rem;

$navbar-light-color: $primary;
$navbar-brand-margin-end: 40px;

$card-border-radius: 32px;
$form-switch-checked-color: $orange;
$form-switch-color: #CDD5E4;
$form-check-input-bg: white;

$input-border-radius: 9px;

//$h4-font-size: 1.5rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1rem;

$headings-font-weight: 700;
$card-spacer-y: 32px;
$card-spacer-x: 32px;
$form-check-input-focus-box-shadow: none;
$form-check-padding-start: 2.5rem;
$form-check-input-width: 1.5rem;

$form-floating-height: 51px;

$form-floating-padding-y: 11px;
$form-floating-padding-x: 22px;

$form-check-input-heigth: 1.75rem;

:root {
  --aw-primary: $primary;
  --aw-secondary: $secondary;
}

$table-border-width: 0;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1.5rem;
$table-hover-bg: #fff;

$table-td-radius: 16px;
$navbar-light-active-color:#994401;

$dropdown-min-width:8rem;
$dropdown-link-color: #2E3549;
$form-select-border-radius: 12px;
$form-select-bg: transparent;

