@use "./mixins.module.scss" as *;

.archive_table {
  &__dropdawn {
    color: var(--text-basic, #2E3549);
    user-select: none;
    @include medium__body_2;

    position: relative;
    display: flex;
    // padding: var(--sp-1, 4px) 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    &__text {
      min-width: 120px;
      white-space: nowrap;
    }
    &__content {
      position: absolute;
      display: flex;
      flex-direction: column;
      &:not(.open) {
        display: none;
      }
      border-radius: var(--r-4, 16px);
      background: var(--background-main, #FFF);
      box-shadow: 0px 4px 24px 0px #E8EAF0;
      padding: 12px 16px;
      top: -12px;
      min-width: calc(100% + 32px);
      right: -16px;
      z-index: 1;
    }
    &__item {
      gap: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
    }
  }
}
