.redesign {
    .block-title {
        font-size: 21px;
        font-weight: 700;
        line-height: 120%;
    }
    &-text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-text-break-all {
        word-break: break-all;
        overflow: hidden;
    }
    &-text-nowrap {
        white-space: nowrap;
    }
}

.redesign-modal-conteiner, .redesign {
    .label {
        &-sm {
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            color: $color-additional-alt;
        }
        &-state {
            display: flex;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            color: $color-white;
            font-size: 9px;
            font-weight: 700;
            line-height: 120%;
            border-radius: 4px;
            text-transform: uppercase;
            &.upgrade, &.minted, &.staked {
                background-color: $color-accent-lite;
            }
            &.exchange, &.owned, &.approved {
                background-color: $color-green;
            }
            &.downgrade, &.pending {
                background-color:  $color-orange;
            }
            &.declined {
                background-color:  $color-red;
            }
            
        }
    }
    .alert-block {
        border-radius: 12px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 16px;
        
        &.info {
            color: $color-additional; 
            border: 1px solid $color-main-dark; 
            background: $color-main; 
        }
        &.warning {
            color: $color-orange; 
            border: 1px solid  $color-orange; 
            background: $color-main; 
        }
        &.dangerous {
            color: $color-red; 
            border: 1px solid $color-red; 
            background: var(--scheme-red-opaque, rgba(244, 73, 73, 0.05));
        }
        i {
            font-size: 16px;
        }
    }
}

.text-green {
    color: $green !important;
}
.text-blue {
    color: $color-blue !important;
}
.text-orange {
    color: $orange !important;
}
.text-orange-ads {
    color: #FE7202;
}
.text-ebony {
    color: #2E3549;
}
.text-16 {
    font-size: 16px;
}
.text-18 {
    font-size: 18px;
}
.text-20 {
    font-size: 20px;
}
.text-21 {
    font-size: 21px;
}
.text-shade {
    color: #4B5677;
}
.text-primary-night {
    color: $primary-night;
}
.text-accent {
    color: $color-accent !important;
}
.text-link {
    color: rgba(0, 157, 196, 1) !important;
}
.text-teritary {
    color: #7B91B7;
}
.text-red {
    color: #F44949 !important;
}
.text-yellow {
    color: #ECAB04 !important;
}
.text-yellow-2 {
    color: #F0A920 !important;
}
.text-ab-balance {
    color: #5BBC43 !important;
}
.text-bold {
    font-weight: 700 !important;
}
.text-regular {
    font-weight: 400;
}
.text-gray {
    color: #B5BDCA;
}
.fw-600 {
    font-weight: 600;
}
.fw-400 {
    font-weight: 400 !important;
}
