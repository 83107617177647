.redesign, .redesign-modal-page {
    input[type="radio"] {
        border: 1px solid $color-primary;
        background: #FFF;
        width: 16px;
        height: 16px;
        &:checked {
            border: 4px solid $color-accent;
        }
        margin-right: 8px;
    }

    input[type="checkbox"] {
        width: 24px;
        height: 24px;
        border: 1px solid $color-primary;
        background-color: #FFF;
        &:checked {
            background-color: $color-accent;
        }
    }
    .custom-switch {
        padding: 0 !important;
        display: flex;
        flex-direction: row-reverse;
        input {
            min-width: 36px !important;
        }
        .form-check-label {
            font-size: 14px;
            &:disabled {
                cursor: default;
            }
        }
        .form-check-input {
            border: 0 !important;
            margin-left: 12px;
            cursor: pointer;
            width: 36px !important;
            height: 20px !important;
            border-radius: 8px !important;
            &:disabled {
                cursor: default;
            }
            &:checked {
                background-image: url('../../../public/check-true.svg');
                border: 0;
                background-color: transparent !important;
            }
            &:not(:checked) {
                background-color: #F44949 !important;
                background-image: url('../../../public/check-false.svg');
            }
        }
    }
    .form-check {
        display: flex;
        align-items: center;
        label {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: $color-primary;
            &.check-type {
                font-weight: 400;
                color:  $color-additional;
            }
        }
    }
}

.form {
    &-label {
        color: $secondary;
        font-size: .75rem;
        margin-bottom: 2px;
    }
    &-control {
        background: transparent;
        border-color: #B5BDCA;
        padding: 12px 16px;
        border-radius: 12px;
    }
}

input[type="date"]:not(.iw_input) {
    &:after {
        @extend [class*=" faw-"];
        content: "\e908";
        font-size: 1.3125rem;
        color: #4B5677;
        position: absolute !important;
        right: 8px !important;
        top: calc(50% - 0.656rem) !important;
    }
    padding-right: calc(1.3125rem + 8px);
    min-width: 130px;
    position: relative;
    &::-webkit-calendar-picker-indicator {
        background: transparent;
        color: transparent;
        cursor: pointer;
        margin-right: -22px;
        z-index: 22;
    }
}

