.redesign {
    &-btn {
        font-family: Nunito !important;
        cursor: pointer;
        &:focus, &:active{
            outline: none;
        }
        &[disabled] {
            pointer-events: none;
        }

        &.btn-tab {
            user-select: none;
            border-radius: 24px;
            background-color: transparent;
            border: 1px solid var(--strokes-main-darken, #E0EBF6);
            padding: 8px 24px;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            white-space: nowrap;
            outline: none !important;
            &:hover, &:focus, &.active {
                border: 1px solid var(--scheme-buttons-button, #28262C);
            }
            &.active {
                background: var(--scheme-buttons-button, #28262C);
                color: $color-white;
                box-shadow: 0px 4px 16px 0px rgba(40, 38, 44, 0.38);
            }
            &[disabled] {
                pointer-events: none;
                color: var(--scheme-buttons-button-disabled, rgba(40, 38, 44, 0.20)) !important;
                border: 1px solid var(--strokes-main-darken, #E0EBF6) !important;
            }
        }

        &.btn-pagination {
            border-radius: 20px;
            border: 1px solid $color-main-dark;
            padding: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            height: 40px;
            min-width: 40px;
            color: $color-button;
            i {
                font-size: 14px;
            }
            &:hover {
                border: 1px solid $color-accent;
            }
            &.active {
                border: 1px solid $color-accent-dark;
                background: $color-accent-dark;
                color: $color-white;
                box-shadow: 0px 4px 15px 0px rgba(40, 38, 44, 0.38);
            }
            &[disabled] {
                color: #6C7284 !important;
            }
        }

        &.btn-mint, &.btn-minted {
            padding: 8px 16px;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            border: none;
            background: $color-white;
        }
        &.btn-mint {
            color: $color-button;
        }
        &.btn-minted {
            color: $color-green;
        }


        &.btn-square {
            border-radius: 8px;
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
            &[disabled] {
                background-color:rgba(40, 38, 44, 0.2) !important;
                color: $color-additional-alt !important;
            }
            &.filled {
                border: none;
                color: $color-white;
                background-color: $color-accent;
                &:hover {
                    background-color: $color-accent-lite;
                }
            }
            &.ghost {
                color: $color-accent;
                background-color: rgba(77, 12, 185, 0.07);
                border: none;
                &:hover {
                    background-color: rgba(121, 41, 255, 0.07);
                    color: $color-accent-lite;
                }
                &.active {
                    background-color: rgba(57, 8, 135, 0.07);
                    color: $color-accent-dark;
                }
            }

            &.outline-common {
                border: 1px solid $color-button;
                color: $color-button;
                &:hover {
                    background-color: $color-button;
                    color: $color-white;
                }

            }
            &.outline-danger {
                border: 1px solid $color-red;
                color: $color-red;
            }
            &[class^='outline'], &[class*=' outline'] {
                padding: 7px 16px;
                background: none;
                &[disabled] {
                    padding: 8px 16px !important;;
                    background-color:rgba(40, 38, 44, 0.2) !important;
                    color: #6C7284 !important;
                    border: none !important;
                }
            }
        }

        &.btn-round {
            display: flex;
            padding:  8px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            background: $color-button;
            color: $color-white;
            border-radius: 24px;
            border: none;
            &.add-right {
                padding-right: 12px;
            }
            &.add-left {
                padding-left: 12px;
            }
            &.accent {
                background: $color-accent;
                box-shadow: 0px 5px 15px 0px rgba(77, 12, 185, 0.35);
            }
            &.ghost {
                color: $color-accent;
                background-color: rgba(77, 12, 185, 0.07);
                border: none;
                &:hover {
                    background-color: rgba(121, 41, 255, 0.07);
                    color: $color-accent-lite;
                }
                &.active {
                    background-color: rgba(57, 8, 135, 0.07);
                    color: $color-accent-dark;
                }
            }
            &[disabled] {
                background-color:rgba(40, 38, 44, 0.2) !important;
                color: $color-white !important;
            }
            &.md {
                padding: var(--sp-3, 12px) var(--sp-5, 20px);
                gap: var(--sp-2, 8px);
                border-radius: 24px;
                font-size: 16px;
            }
        }

        &.btn-banner, &.btn-banner-invert {
            display: flex;
            padding: 2px 2px 2px 32px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            background-color: $color-toxic-green;
            border: none;
            border-radius: 40px;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            i {
                background: $color-white;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                display: flex;
                &::before {
                    margin: auto;
                }
            }
            &:hover {
                background-color: $color-toxic-green-lite;
            }
            &.active {
                background-color: $color-toxic-green-dark;
            }
            &[disabled] {
                background-color:rgba(40, 38, 44, 0.2);
                color: $color-white;
                i {
                    color: rgba(40, 38, 44, 0.2);
                }
            }
        }

        &.btn-banner-invert {
            background: $color-white;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
            i {
                background: $color-toxic-green;
            }
            &:hover {
                background-color: $color-main;
            }
        }
        &.modal-close {
            padding: 8px;
            border-radius: 8px;
            border: none;
            background: none;
            display: flex;
            font-size: 24px;
            &:hover {
                background-color: $color-red;
                color: $color-white;
            }
        }
    }
}


.btn {
    border: 2px solid transparent;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    &.pagination-btn {
        border: 1px solid #51466D;
        border-radius: 8px;
        padding: 4px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        height: 34px;
        min-width: 34px;
        [class^="faw-"] {
            font-size: 9.5px;
        }
        a {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: inherit !important;
        }
    }
    &-r-12 {
        border-radius: 12px;
    }
    &:hover {
        border-color: transparent;
    }
    &-primary {
        background-color: #F0A920;
        color: #ffffff;
        &:hover {
            background-color: #994401;
        }
        &[disabled] {
            opacity: .4;
        }
        &:active {
            background-color: #7F3901;
        }
        &:focus {
            background-color: #F0A920;
            box-shadow: 0 0 0 0.25rem #FEAB68;
        }
    }
    &-warning {
        background-color:#FE7202;
        color: #ffffff;
        &:hover {
            color: #ffffff;
            background-color: #994401;
        }
        &[disabled] {
            background-color:rgba(254, 114, 2, 1);
            color: #ffffff;
        }
        &:active, &:focus {
            color: #ffffff;
            border-color: #7F3901;
            background-color: #7F3901;
            box-shadow: unset;
        }
    }
    &-info {
        background-color: #51466d;
        border-color: #51466d;
        color:#ffffff;
        &:hover {
            color: #ffffff;
            background-color: #3A324E;
        }
        &:active, &:focus {
            color: #ffffff;
            border-color: #231E2F;
            background-color: #231E2F;
            box-shadow: unset !important;
        }
        &[disabled] {
            background-color: #3A324E;
            border-color: #3A324E;
            color: rgba(255, 255, 255, 1);
        }
    }
    &-outline-info {
        border: 2px solid #51466d;
        background-color: transparent;
        color:#51466d;
        &:hover {
            color: #ffffff;
            background-color: #3A324E;
        }
        &:active, &:focus {
            color: #ffffff;
            border-color: #231E2F;
            background-color: #231E2F;
            box-shadow: unset;
        }
        &[disabled] {
            background-color: #3A324E;
            border-color: #3A324E;
            color: rgba(255, 255, 255, 1);
        }
    }
    &-outline-warning {
        border: 2px solid #FE7202;
        background-color: transparent !important;
        color:#FE7202;
        &:hover {
            color: #ffffff;
            background-color: #FE7202 !important;
        }
        &:active, &:focus {
            color: #ffffff;
            border-color: #994401;
            background-color: #994401 !important;
            box-shadow: unset;
        }
    }
    &-link {
        color: #009DC4;
        &:hover {
            color: #327DA0;
        }
        &:active {
            color: #327DA0;
            text-decoration: underline;
        }
    }
    &-filter {
        display: flex;
        align-items: center;
        &.dropdown-toggle::after {
            margin-left: auto !important;
        }
        border-radius: 16px;
    }
    &.dropdown-toggle {
        &:after {
            border: unset;
            font-family: 'faw' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            content: "\e918";
            font-size: .333125rem;
        }
    }
}


@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .btn-header + .dropdown-menu, .special-menu {
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        -webkit-backdrop-filter: blur(32px);
    }
}

.special-menu {
    padding: 8px !important;
    border-radius: 16px !important;
}

button.load-more {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    border: unset;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #009DC4;
    transition: background-color .2s;
    &:hover, &:active {
        color: #327DA0;
        background-color: rgb(255, 255, 255);
    }
    &:focus:not(:active) {
        color: #327DA0;
        text-decoration-line: underline;
    }
}

.aw-ubtn {
    &__var {
        &--ads-decline {
            --aw-ubtn-bg: #BC4343;
            --aw-ubtn-hover: #BC3333;
            --aw-ubtn-active: #BC2323;
        }
        &--ads-approve {
            --aw-ubtn-bg: #5BBC43;
            --aw-ubtn-hover: #47a013;
            --aw-ubtn-active: #60ba0e;
            order: 1;
        }
    }
    &, &:focus{
        outline: none;
        background: transparent;
        border: 0;
      }

      &:active{
          outline: none;
          background: transparent;
          border: 0;
      }
      &--base {
        width: 100%;
        font-family: Nunito, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        padding: 12px 24px;
        border-radius: 12px;
        color: #FFFFFF;
        background: #FE7202;
        &.disabled, &:disabled {
            opacity: .4;
            pointer-events: none;
        }
        &:hover {
            background: #994401;
        }
        &:active {
            background: #7F3901;
        }
        &:focus:not(:active) {
            background: #FE7202;
            outline: 4px solid #FEAB68 !important;
        }
    }
    &--solid {
        width: 100%;
        font-family: Nunito, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        padding: 12px 24px;
        border-radius: 12px;
        color: #FFFFFF;
        background: var(--aw-ubtn-bg, #51466D);
        &.disabled, &:disabled {
            opacity: .4;
            pointer-events: none;
        }
        &:hover {
            background: var(--aw-ubtn-hover, #3A324E);
        }
        &:active {
            background: var(--aw-ubtn-active,#231E2F);
        }
        &:focus:not(:active) {
            background: var(--aw-ubtn-hover, #3A324E);
            outline: 4px solid var(--aw-ubtn-bg, #51466D) !important;
        }
      }
      &--outline {
        width: 100%;
        font-family: Nunito, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        padding: 12px 24px;
        border-radius: 12px;
        color: #3A324E;
        background: transparent;
        border: 1px solid #3A324E;
        &.disabled, &:disabled {
            opacity: .4;
            pointer-events: none;
        }
        &:hover {
          background: #3A324E;
          color: #FFFFFF;
        }
        &:active {
          color: #FFFFFF;
          background: #231E2F;
          border: 1px solid #231E2F;
        }
        &:focus:not(:active) {
            border: 1px solid transparent;
            box-shadow: 0 0 0 4px #3A324E;
        }
      }
}
