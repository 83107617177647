.gold__rush {
    height: 100%;
    // overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.mobile-map-area{
        pointer-events: auto;
        background-position: center;
        height: initial;
        padding: 0;
        overflow: hidden;
        .title {
            font-weight: 900;
            font-size: 48px;
            line-height: 120%;
        }
        .book__link{
            position: initial;
            margin: 0;
            padding: 0;
            background: transparent;
            backdrop-filter: none;
            box-shadow: none;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .hole {
            width: 286px;
            left: initial;
            height: 273px;
            top: -24px;
            svg {
                width: 100% !important;
                height: 100%  !important;
            }
        }
        .progress{
            width: inherit;
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 24px;
        }
        .progress-timer{
            left: calc(50vw - 57px);
        }
        .content {
            height: 100%;
        }
        .mobile-map-zoom{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px;

            position: static;
            width: 48px;
            height: 48px;
            left: 321px;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 16px;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px 10px;
        }
        .tint {
            height: calc(100vh - 53px);;
            top: 53px;
            bottom: 0;
        }
        .tint__content {
            .diamond-wrap {
                flex: initial !important;
                .diamond {
                    max-width: calc(100vw - 108px) !important;
                }
            }
            margin: auto;
        }
        .shovel{
            width: initial !important;
        }
        .history {
            background: transparent;
            backdrop-filter: initial;
            box-shadow: initial;
            .card-title{
                color: #FFFFFF !important;
                text-shadow: 0px 2px 0px #7cb2ee;
                .mobile-map-zoom {
                    width: 32px;
                    height: 32px;
                    img {
                        width: 14px;
                    }
                }
            }
            .shovel-durability {
                font-size: 28px;
            }


            .locator {
                display: block;
                font-size: 1.25rem;
                color: #4B5677;
                cursor:pointer;
            }
        }
        .mobile-map-area--footer-item{
            .confirmation-container{
                top: -50vh;
            }
        }
        .mobile-map-area--footer-inner{
            overflow: initial;
        }
        .mobile-map-area--footer-tail{
            z-index: 0;
        }
        .confirmation-container {
            .card{
                min-width: inherit;
                max-width: inherit;
                width: 100%;
                padding: 16px;
                margin: 16px;
            }
        }

        .overlay-container {
            align-items: inherit;
            .mobile-cart--container {
                overflow: hidden;
                margin: 16px;
                padding: 0;
                z-index: 100;
            }
            .myland, .myminerals, .redeem  {
                width: 100%;
                padding: 24px 24px 20px 24px !important;
                height: inherit;
                background: #F7F7F7;
                .list-group {
                    max-height: initial;
                    overflow-y: hidden;
                }
                .card-body {
                    flex: auto;
                    overflow: auto;
                }
                .card-footer {
                    border: 0;
                    background: none;
                    .btn-link{
                        height: auto;
                        padding-top: 20px !important;
                    }
                }
            }
            .redeem{
                .card-body {
                    margin-right: -24px !important;
                    margin-left: -24px !important;
                }
                .header--tooltype{
                    &.show {
                        right: -30px !important;
                        z-index: 20000;
                    }
                }
            }
            .redeem, .myminerals {
                .card-title{
                    .right-buttons{
                        .faw-info_round {
                            font-size: 1.375rem;
                            color: #4B5677;
                            &:hover {
                                color: #994401;
                            }
                        }
                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
                .card-body {
                    .redeem__list::-webkit-scrollbar {
                        height: 4px;
                    }
                    .redeem__list {
                        display: flex;
                        flex-direction: row;
                        overflow-y: hidden;
                        overflow-x: auto;
                        height: inherit;
                        .redeem_m {
                            width: 242px;
                            height: 300px;
                            display: flex;
                            flex: none;
                            flex-direction: column;
                            padding: 16px;
                            margin: 0 8px;
                            background-color: #FFFFFF;
                            .input-container {
                                margin: 0 auto;
                                svg {
                                    width: 22px;
                                    height: 22px;
                                }
                            }
                            img {
                                width: 80px;
                                height: 80px;
                                flex: 1;
                            }
                            input {
                                font-weight: 700;
                                border-radius: 20px;
                                border: 1px solid #B5BDCA;
                                background-color: transparent;
                                text-align: center;
                                width: 100%;
                                margin-left: 9px;
                                color: #2E3549;
                                margin-right: 9px;
                                font-size: 1.125rem;
                            }
                            button {
                                width: 22px;
                                height: 22px;
                                padding: 0;
                            }
                        }
                        .redeem__name{
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 120%;
                            color: #2E3549 !important;
                            small{
                                font-size: 12px;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }
        }
    }
    .title {
        text-shadow:0px 4px #80bbfa;
        font-weight:900;
        font-size:6rem;
        color:#FFFFFF;
        z-index: 0;
    }

    .card {
        background: rgba(255, 255, 255, 0.75);

        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        border: unset;
    }
    .shovel {
        width: 240px;
        hr {
            margin: 0 13px;
            width: 1px;
            height: 28px;
            background-color: #E0EBF6;
            opacity:1;
        }
        p {
            font-size: 1.3125rem !important;
            font-weight: 700 !important;
            color: #2E3549;
            line-height: 25.2px;
        }
        img {
            width: 32px;
            height: 32px;
        }
        .img {
            &__shovel {
                .mobile & {
                    width: 60px;
                    height: 60px;
                }
            }
        }
        .text-muted {
            font-weight:700;
            font-size: .75rem;
            color: #2E3549 !important;
            .mobile & {
                font-size: 1rem;
                font-weight:400;
                color: #4b5677 !important;
            }
        }
        span {

        }
        &--durability_value {
            color: #F44949 !important;
            font-weight: 700;
            &.middle {
                color: #FE7202 !important;
            }
            &.new, &.full {
                color: #5BBC43 !important;
            }
        }

        .mobile & {
            width: 380px;
            p {
                font-size: 1.3125rem !important;
                font-weight: 700 !important;
                color: #2E3549;
                line-height: 25.2px;
            }
            span {

            }
            &--durability_value {
                color: #F44949 !important;
                font-weight: 700;
                &.middle {
                    color: #FE7202 !important;
                }
                &.new, &.full {
                    color: #5BBC43 !important;
                }
            }
            &--question_mark {
                width: 16px;
                height: 16px;
            }
        }
    }
    .hole {
        width: 583px;
        height: 498px;
        margin: auto;
        position: relative;
        left: 31px;
        svg, img {
            width: 30vw !important;
            height: 46vh !important;
        }
        & > div {
            width: 100%;
            height: 100%;
            position: relative;
        }
        svg {
            width: 100% !important;
            height: 100%  !important;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .myland {
        width: 240px;
        //max-height: 100%;
        top: 0;
        bottom: 0;
        .mobile & {
            margin-top: 66px;
        }
        &__home {
            width: 48px;
            height: 48px;
        }
        &__plot {
            font-size: 1rem;
            font-weight: 700;
            color: #2E3549;
        }
        &__level {
            font-weight: 400;
            color: #4B5677;
            font-size: 0.75rem;
        }
        &__shovel {
            visibility: hidden;
            width: 48px;
            height: 48px;
            background: transparent;
            box-shadow: none;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__timer {
            color: #F44949;
            font-size: 1rem;
            font-weight: 700;
        }
        .list-group {
            //max-height: 560px;
            overflow-y: auto;
            overflow-x: clip;
            max-height: calc(100vh - 487px);
            scrollbar-width: thin;
            &-item {
                padding: 4px;
                border-radius: 12px;
                opacity: 1;
                .myland {
                    &__shovel {
                        visibility: visible;
                    }
                }
                &:hover, &.active {
                    background-color: #ffffff;
                }
                & + .list-group-item {
                    margin-top: 10px;
                }
            }
            &::scrollbar {
                width: 4px;
                border-radius: 2px;
                background-color: #B5BDCA; /* or add it to the track */
            }
            &::scrollbar-thumb {
                border-radius: 2px;
                background: #231E2F;
            }
            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 2px;
                background-color: #B5BDCA; /* or add it to the track */
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background: #231E2F;
            }
        }
        .mobile & {
            .list-group {
                &-item {
                    padding: 4px;
                    border-radius: 12px;
                    opacity: .4;
                    .myland__shovel {
                        visibility: hidden;
                        background-color: transparent;
                    }
                    &.canactivate {
                        opacity: 1;
                    }
                    &.canactivate:hover, &.active {
                        background-color: #ffffff;
                    }
                    &.canactivate:hover {
                        .myland {
                            &__shovel {
                                visibility: visible;
                            }
                        }
                    }
                    & + .list-group-item {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    @mixin content-title-middle {
        @media (max-height: 794px) { @content; }
    }
    @mixin content-title-small {
        @media (max-height: 700px) { @content; }
    }
    .content {
        height: calc(100vh - 150px);
        .img {
            &__shovel_hole {
                position:relative;
                width:583px;
                height:498px;
                margin:auto;
                top:-48px;
                left: 31px;
            }
        }
        h5 {
            @include content-title-middle {
                font-size: 5rem;
            }
            @include content-title-small {
                font-size: 4rem;
            }
        }
    }
    .plots {
        &__circle {
            & + hr {
                margin: 0 8px;
                width: 1px;
                height: 18px;
                background-color: #E0EBF6;
                opacity: 1;
            }
        }
    }
    .myminerals {
        width: 240px;
        height: fit-content;
        img {
            width: 40px;
            height: 40px;
            margin-right: 8px;
        }
        .list-group, .react-loading-skeleton {
            margin-bottom: 26px;
        }
        & li + li {
            margin-top: 8px !important;
        }
        .value {
            color: $color-accent;
        }
        p {
            color: #2E3549;
        }
        .btn {
            height:34px;
            font-size: .75rem;
            line-height: .8;
            border-radius:8px;
        }
    }
    .text-muted {
        color: #4B5677 !important;
        font-weight: 400;
    }
    .btn {
        &-info {
            & + .btn-link {
                margin-top: 11px;
            }
        }
    }
    .btn {
        border-radius: 12px;
        border: unset;
        padding: 12px 24px;
        font-size: 1rem;
        height: 48px;

        &-info {
            background-color:#51466D;
            color:#ffffff;
            &:hover {
                color: #ffffff;
                background-color: #3A324E;
            }
            &:active {
                color: #ffffff;
                background-color: #231E2F;
            }
        }
        &-link {
            color: #009DC4;
            &:hover {
                color: #327DA0;
            }
            &:active {
                color: #327DA0;
                text-decoration: underline;
            }
        }
        &-outline-warning {
            color:#FE7202;
            border: 2px solid #FE7202;
            line-height: 1;
            &:hover {
                color: #ffffff;
                background-color:#FE7202;
            }
            &:active {
                color: #ffffff;
                background-color:#994401;
            }
        }
        &-warning {
            background-color:#FE7202;
            color: #ffffff;

            &:hover {
                color: #ffffff;
                background-color: #994401;
            }
            &:active {
                color: #ffffff;
                background-color: #7F3901;
            }
        }
    }

    .card-title {
        color: #2E3549;
        margin-bottom: 16px;
        font-size: 1.75rem;
    }
    .list-group-item {
        background-color: transparent;
    }
    .progress {
        width: 438px;
        margin-top: -64px;
        margin-left: auto;
        margin-right: auto;
        height: 64px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.16);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        img {
            width: 32px;
            height: 32px;
        }
        &-wrap {
            width: fit-content;
        }
        &-timer {
            position: absolute;
            top: -64px;
            width: 100%;
            height: 64px;
            .text-wrap {
                font-size: 1.3125rem !important;
            }
        }
        &-bar {
            background: rgba(255, 255, 255, 0.59);
            opacity: 0.75;
        }
    }
    .tint {
        position: fixed;
        top: 61px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 61px);
        background: rgba(35, 30, 47, 0.85);
        backdrop-filter: blur(32px);
        opacity: .75;
        &__rays {
            left: 0vw;
            top: -48vh;
            width: 100vw;
            height: 100vw;
            margin: auto;
            position: fixed;
            background-image: url('../../../src/assets/rays.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            animation:spin 40s linear infinite;
        }
        &__content {
            height: fit-content;
            z-index: 1;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;
            h4 {
                font-size: 2.375rem;
                color: #ffffff;
                margin-bottom: 16px;
            }
            p {
                color: #FBFE68;
                font-size: 1.3125rem;
                margin-bottom: 32px;
                font-weight: 400;
            }
            .btn-outline-light {
                font-weight: 600;
                border-color: #ffffff;
                font-size: 1.3125rem;
                text-transform: uppercase;
                border: 2px solid #ffffff;
                width: auto;
                padding: 16px 32px;
                line-height: 16px;
                height: 57px;
                border-radius: 16px;
                margin-top: 32px;
                pointer-events: all;
            }
            .diamond {
                &-wrap {
                    flex: 1;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                display: block;
                height: 49.257vh;
            }
        }
    }
    .redeem {
        width: 47%;
        margin: auto;
        min-width: 667px;
        max-width: 860px;
        .mobile & {
            min-width:unset;
            max-width:unset;
        }
        &__list {
            height: calc(100vh - 494.58px);
            overflow-y: auto;
            overflow-x:hidden;
            scrollbar-width: thin;
            &::scrollbar {
                width: 4px;
                border-radius: 2px;
                background-color: #B5BDCA; /* or add it to the track */
            }
            &::scrollbar-thumb {
                border-radius: 2px;
                background: #231E2F;
            }
            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 2px;
                background-color: #B5BDCA; /* or add it to the track */
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background: #231E2F;
            }
        }
        .text-muted {
            font-weight: 400;
            font-size: 0.75rem;
            color: #4B5677 !important;
        }
        &__grand {
            font-size: 1.3125rem;
            color: #2E3549;
        }
        &__title {
             border-bottom: 1px solid #ffffff;
            .mobile & {
                border-bottom: unset;
            }
        }
        &__item {
            margin-left: 12px;
        }
        &__name {
            font-size: 1.3125rem;
        }
        &__value {
            font-size: 1.125rem;
            color: #4B5677;
        }
        &__total {
            color: #4D0CB9;
            font-size: 1.3125rem;
            small {
                font-size: 1rem;
                text-transform: uppercase;
            }
        }
        &__gave {
            background-color: #FFFFFF;
            border-radius: 16px;
            padding: 16px;
            .name {
                color: #2E3549;
                font-size: 1rem;
            }
            .amount {
                font-size: 1.125rem;
                color: #4D0CB9;
            }
            .currency {
                font-size: 0.75rem;
                margin-left: 4px;
            }
        }
        .card {
            &-title {
                a {
                    color: #009DC4;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
            &-body {
                margin-top: 7px;
                .alert {
                    margin-top: -6px;
                    padding: 12px 16px;
                    &-light {
                        border-radius: 16px;
                        color: #4B5677;
                        font-weight: 400;
                    }
                }
                .table {
                    th {
                        padding-bottom: 8px;
                        padding-top: 0px;
                        line-height: 150%;
                    }
                    td {
                        font-weight: 700;
                        padding: .5rem 1.5rem;
                        small {
                            font-size: 0.75rem;
                        }
                    }
                    img {
                        width: 64px;
                        height: 64px;
                    }
                    &-hover {
                        .redeem {
                            &__value {
                                color: #4B5677 !important;
                            }
                            &__name {
                                color: #2E3549 !important;
                            }
                        }
                    }
                    input {
                        font-weight: 700;
                        border-radius: 20px;
                        border: 1px solid #B5BDCA;
                        background-color: transparent;
                        text-align: center;
                        width: 133px;
                        margin-left: 9px;
                        color: #2E3549;
                        margin-right: 9px;
                        font-size: 1.125rem;
                    }
                    button {
                        width: 22px;
                        height: 22px;
                        padding: 0;
                    }
                }
            }
            &-footer {
                margin-top: 4px;
                background-color: transparent;
                border-color: #ffffff;
            }
        }
    }
    .confirmation-container {
        .card {
            padding: 32px;
            min-width: 380px;
            max-width: 380px;
            background-color: #F7F7F7;
            border-radius:24px;
            &-footer {
                border-top-color: #ffffff;
                background-color: #F7F7F7;
            }
        }
        .h5 {
            color: #2E3549;
            font-size: 2.375rem;
        }
        p {
            color: #4B5677;
            font-size:1rem;
            font-weight:400;
            .text-bold {
                font-weight:700;
            }
        }
        .funded {
            font-size: 1.3125rem;
            color: #4D0CB9;
            font-weight:700;
        }
        .redeemed {
            font-size: 1.3125rem;
            color: #5BBC43;
            font-weight:700;
        }
        .shovel {
            .img {
                &__shovel {
                    width: 60px;
                    height: 60px;
                }
                &__check {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
    .history {
        margin-top:36px;
        margin-bottom:36px;
        min-width: 1160px;
        .mobile & {
            min-width: unset;
        }
        &__redeem {
            width: 66%;
            margin:36px auto;
        }
        .react-loading-skeleton {
            border-radius: 16px;
        }
        .nothovered > * {
            --bs-table-accent-bg: transparent !important;
        }
    }
    .react-loading-skeleton {
        border-radius: 24px;
    }
    .book {
        &__link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 16px;
            padding: 12px;
            img {
                width: 24px;
                height: 24px;
            }
        }
        &__label {
            font-weight: 700;
            font-size: .75rem;
            color: #2E3549;
            margin-left: 8px;
        }
    }
    .mobile-balance {
        &--half_wrap {
            .session__timer {
                margin-bottom: 0px;
                width: 100%;
                .card {
                    &-body {
                        hr {
                            background-color:#E0EBF6;
                        }
                    }
                }
            }
        }
    }
    &.mobile-map-area {
        .progress {
            width: calc(100vw - 48px);
            &-timer {
                left: unset;
            }
        }
        .hole {
            top: -92px;
        }
    }
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

@media (max-device-width: 840px) {
    .shovel--tooltype {
        max-width: 320px;
    }
}

.header--tooltype{
    &.mobile{
        &.show {
            z-index: 20000;
            span {
                background: rgba(255, 255, 255, 0.75);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(32px);
                color: #2E3549;
            }
        }
    }
}
.text-left{
    text-align: left !important;
}

.mineral-icon {
    width: 32px;
    height: 32px;
}

.minaral-gained {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #2E3549;
}
