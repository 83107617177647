@import 'src/styles/variables';

@import "src/styles/utils";
@import "src/styles/redesign/view";
@import "src/styles/redesign/adnet/index";
@import "src/styles/redesign/support";
@import "src/styles/redesign/buildingexchage";
@import "src/styles/redesign/inputs";
@import "src/styles/redesign/modal_success";
@import "src/styles/redesign/archive_table";
@import "src/styles/typography";
@import "src/styles/forms";
@import "src/styles/card";
@import "src/styles/modal";
@import "src/styles/icons";
@import "~bootstrap/scss/bootstrap";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

html, body {
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    color: $color-primary;
    background-color: $color-main;
    font-weight: 400;
    line-height: 150%;
    height: 100%;
    min-height: 100%;
}
h3 {
    font-size: 3rem;
    font-weight: bold;
    color: #2C3653;
}
.cursor-pointer {
    cursor: pointer;
    user-select: none;
}
ul {
    list-style-type: none;
    padding-left: 0px;
}

.lds-default__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    // background-color: #fff;
    z-index: 1000;
}
.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #7a85eb;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

@import "src/styles/components/components";
@import "src/styles/pages/pages";
