table.images-table td img {
    border-radius: 8px;
    height: 65px;
    width: 91px;
}

.live2earn {
    input[type="date"]:after {
        right: 16px !important;
    }
    input[type="date"] {
        padding-right: calc(1.3125rem + 16px) !important;
    }

    .status_approved {
        color: #5BBC43 !important;
    }
    .status_pending {
        color: #FE7202 !important;
    }
    .status_declined {
        color: #F44949 !important;
    }
    button {
        border-radius: 12px;
    }
    .inner__container {
        background-image: none;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), #FFFFFF;
        border-top: 1px solid #E0EBF6;
        .wrap {
            max-width: 1215px;
            margin: 0 auto;
            padding-top: 48px;
            .page-title {
                margin: 0;
                color: #2E3549;
            }
            .add-photo {
                padding: 10px 24px;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                height: 48px;
            }
            .totals {
                margin-top: 24px;
                width: 100%;
                .record {
                    background: #FFFFFF;
                    border: 1px solid #E0EBF6;
                    border-radius: 16px;
                    padding: 16px 24px;
                    flex: 1;
                    .label {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 150%;
                        color: #505D77;
                        margin-bottom: 8px;
                    }
                    .value {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 120%;
                    }
                    &.r1 {
                        color: #F0A920;
                    }
                    &.r2 {
                        color: #5BBC43;
                    }
                    &.r3 {
                        color: #FE7202;
                    }
                    &.r4 {
                        color: #7A85EB;
                    }
                }
                
            }
            .filter-form {
                > div {
                    flex: 1;
                }
                input, select {
                    height: 48px;
                }
                button{
                    padding-left: 24px;
                    padding-right: 24px;
                }
                .btn-outline-info {
                    border: 1px solid #3A324E !important;
                    
                    padding-top: 11px;
                    padding-bottom: 11px;
                }
            }
            .no-data {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #4B5677;
            }
            table {&.images-table {
                background: white;
                border-radius: 16px;
                border-collapse: collapse;
                border-style: hidden;
                box-shadow: 0 0 0 1px #E0EBF6;
                margin: 0;
                width: 100%;
                overflow: hidden;
                thead {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 150%;
                    color: #4B5677;
                    th {
                        background: #F9FBFD;
                        vertical-align: middle;
                    }
                }
                tr:nth-child(even) {
                    background-color: #F9FBFD;
                }
                td:first-child,  th:first-child {
                    width: 123px;
                }
                th, td {
                    border: 1px solid #E0EBF6;
                    padding: 8px 16px !important;
                }
                td {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    &.date {
                        color: #3A324E;
                    }
                    &.l2ap {
                        color: #4B5677;
                    }
                    
                    img {
                        width: 91px;
                        height: 65px;
                        border-radius: 8px;
                    }
                    .faw-exclamation_point {
                        font-size: 14px;
                        color: #F44949;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                    
                }
            }}
            .table-records {
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                color: #505D77;
                .value {
                    color: #2E3549;
                    margin-left: 8px;
                }
            }
        }
    }
    &.confirmation-container {
        overflow: auto;
        display: inline-grid;
        .card {
            min-width: 414px;
            width: 414px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 24px;
            padding: 32px 32px 22px 32px;
            height: fit-content;
        }
        .card:focus {outline: none;}
        .header-icon {
            font-size: 44px;
            margin-bottom: 18px;
            &.faw-cross_round {
                color: #F44949;
            }
            &.faw-check_round {
                color: #5BBC43;
            }
        }
        .estimate {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 16px;
            gap: 16px;
            background: #FFFFFF;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #2E3549;
            i {
                &.faw-cross {
                    color: #F44949;
                    font-size: 12px;
                    margin-right: 12px;
                    margin-left: 2px;
                }
                &.faw-check {
                    color: #5BBC43;
                    margin-right: 10px;
                }
            }
        }
        h4 {
            margin: 0;
            font-weight: 700;
            font-size: 38px;
            line-height: 120%;
            color: #2C3653;
            text-align: center;
        }
        .photo-limit {
            color: #4B5677;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
        }
        .inform {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #231E2F;
        }
        textarea {
            background: rgba(255, 255, 255, 0.6);
            border: 1px solid #B5BDCA;
            color: #231E2F;
            padding: 16px;
        }
        .text-counter {
            margin-top: -32px;
            margin-bottom: 14px;

            text-align: end;
            padding-right: 16px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            &.limit {
                color: #BC4343;
            }
        }
        
    }
}

.mobile {
    .live2earn {
        .filters-wrap {
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            z-index: 4000;
            bottom: 0;
            background: #FFFFFF;
            padding: 44px 16px 0 16px;
            .filters-header {
                margin-bottom: 44px;
                .clear {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #FE7202;
                }
                .close {
                    font-size: 12px;
                }
            }
            form {
                flex-direction: column;
                input, select {
                    height: 48px;
                }
            }
        }
        .upload-wrap {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #FFFFFF;
            z-index: 10;
            box-shadow: 0px -4px 15px rgba(225, 232, 236, 0.43);
        }
        &.confirmation-container {
            overflow: auto;
            display: flex;
            .card {
                min-width: fit-content;
                width: 100%;
            }
            
        }
        .inner__container {
            padding: 0 16px 96px 16px;
            .wrap {
                padding-top: 32px;
                h4 {
                    font-size: 28px;
                }
                .totals {
                    flex-direction: column;
                    margin-top: 16px;
                    .record {
                        width: 100%;
                    }
                }
                .table-records {
                    font-size: 16px;
                    margin-top: 32px;
                    margin-bottom: 24px;
                }
                .images-table-wrap> :not(:first-child) {
                    margin-top: 32px;
                }
                .images-table {
                    border: none;
                    display: flex;
                    flex-direction: column;
                    .record {
                        padding: 16px;
                        background: #FFFFFF;
                        border: 1px solid #E0EBF6;
                        border-radius: 16px;
                        color: #2E3549;
                        .label {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 150%;
                            color: #4B5677;
                            margin-bottom: 8px;
                        }
                        .value {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 120%;
                            
                            .faw-exclamation_point {
                                font-size: 15px;
                                color: #F44949;
                                margin-left: 8px;
                                cursor: pointer;
                            }
                        }
                        img {
                            width: 100%;
                            
                            border-radius: 12px;
                        }
                    }
                }
                .filter-btn {
                    background: #FFFFFF;
                    border: 1px solid #E0EBF6;
                    border-radius: 16px;
                    padding: 12px 24px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    color: #51466D;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 16px;
                    i {
                        margin-left: auto;
                        font-size: 9px;
                    }
                }
            }
        }
    }
}

