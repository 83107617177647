.second,
.second * {
  box-sizing: border-box;
}
.second {
  z-index: 3;
  width: 100%;
  position: relative;
  margin-top: 80px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.next-gen {
  color: #353451;
  text-align: left;
  z-index: 1;
  font: 900 40px/100% "Rubik", sans-serif;
  order: 1;
  &-text {
    order: 3;
    color: #3f3f4b;
    text-align: left;
    font: 400 16px/200% "Rubik", sans-serif;
  }
}
.img {
  margin-top: -45px;
  order: 2;
  width: 100%;
  img {
    width: 100%;
    max-width: 100%;
  }
}