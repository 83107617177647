.blur,
.blur * {
  box-sizing: border-box;
}
.blur {
  height: 592px;
  position: absolute;
  top: 103px;
  left: 0%;
  right: 0%;
  &::before {
    content: "";
    display: block;
    background: url('../../blur.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    width: 100%;
    height: 1291px;
    position: absolute;
    top: -103px;
    left: 0px;
    max-height: 100vh;
  }
}
.ellipse-3 {
  display: none;
  background: #a428de;
  border-radius: 50%;
  opacity: 0.699999988079071;
  width: 549px;
  height: 658px;
  position: absolute;
  right: 86px;
  top: 130px;
  filter: blur(200px);
}
.ellipse-4 {
  display: none;
  background: #28bdde;
  border-radius: 50%;
  opacity: 0.5;
  width: 100%;
  height: 657px;
  position: absolute;
  left: 0px;
  top: -65px;
  filter: blur(200px);
}

.new-worlds {
  color: #ffffff;
  text-align: center;
  font: 900 16px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}
.frame-4225 {
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.inter-world {
  color: #ffffff;
  text-align: center;
  font: 900 88px/100% "Rubik", sans-serif;
}
.expmetvers {
  color: #ffffff;
  text-align: center;
  font: 400 18px/150% "Rubik", sans-serif;
  margin-top: 24px;
  width: 100%;
  padding: 0 20px;
}
.button {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button2 {
  background: #bcfc37;
  border-radius: 40px;
  padding: 2px 2px 2px 48px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  &:hover, &:focus {
    background: #DDFD9B;
  }
}
.join-to-metaverse {
  color: #353451;
  text-align: left;
  font: 400 18px/150% "Rubik", sans-serif;
  position: relative;
}
.frame-4109 {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: relative;
}
.ellipse-1 {
  background: #ffffff;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon-arrow-right-up {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  overflow: visible;
}
.join {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-4111 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.group-28 {
  flex-shrink: 0;
  width: 90.67px;
  height: 32px;
  position: static;
}
.unsplash {
  background: linear-gradient(to left, #c4c4c4, #c4c4c4);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  &:not(:first-child) {
    margin-left: -16px;
  }
}
._200-members-joined {
  color: #3f3f4b;
  text-align: left;
  font: 300 18px/150% "Rubik", sans-serif;
  position: relative;
}




.new-world {
  display: none;
  width: 151.64px;
  height: 74px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(2.329deg) scale(1, 1);
}
.frame-4116 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 59.39px);
  top: 10.34px;
}
.we-build {
  color: #ffffff;
  text-align: left;
  font: 900 12px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.frame-4117 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 73.46px);
  top: 33.48px;
}
.new-worlds {
  color: #ffffff;
  text-align: left;
  font: 900 12px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}

.top_btn {
  &__container {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &__download {
    user-select: none;
    display: flex;
    width: 100%;
    height: 48.2px;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;

    border-radius: 8px;
    background: #353451;
    svg {
      width: 25px;
      height: auto;
    }
    span {
      white-space: nowrap;
      color: #FFF;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: 1px;
    }
  }
}
