.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 25px;
  &, .footer * {
    box-sizing: border-box;
  }
  &__bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 16px;
  }
  &__address {
    text-align: center;
    color: #505D77;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &__menu {
    flex-shrink: 0;
    position: relative;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
  }
  &__item {
    color: #353451;
    text-align: left;
    font: 400 16px/200% "Rubik", sans-serif;
    &:hover, &:focus, &:active, &.active {
      color: #7000BF;
    }
  }

  &__copyright {
    color: #353451;
    text-align: center;
    font: 400 16px/200% "Rubik", sans-serif;
    position: relative;
  }
}

