.staff-events {
  &--devider-1 {
    border: 1px solid #E0EBF6;
    width: 0;
    height: 130px;
  }
  &--devider-2 {
    border: 1px solid #E0EBF6;
    width: 100%;
    height: 0;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  &--inputs {
    flex: 1;
  }
  &-cover {
    &--title {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;

      color: #2E3549;
    }
    &--add {
      &-container {
        cursor: pointer;
        box-sizing: border-box;
        width: 137px;
        height: 90px;
        background: #F7F7F7;
        --color: #4B5677;
        --label-color: #009DC4;
        border: 1px dashed var(--color);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        user-select: none;
        &:hover, &:active, &:focus {
          --color: #FE7202;
          --label-color: #327DA0;
        }
      }
      &-icon {
        display: block;
        font-size: 21px;
        color: var(--color);
      }
      &-label {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;

        color: var(--label-color);
      }
    }
    &--setted {
      &-container {
        width: 137px;
        height: 90px;
        background-image: var(--cover, none);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }
      &-delete {
        cursor: pointer;
        margin: 4px;
        background: rgba(35, 30, 47, 0.85);
        border-radius: 4px;
        padding: 5px;
        i {
          color: #FFFFFF;
          font-size: 16px;
        }
        &:hover, &:active, &:focus {
          background: #FE7202;
        }
      }
    }
  }
  &-editor {
    display: flex;
    gap: 24px;
    .quill {
      height: auto !important;
      margin-bottom: 0;
      flex: 1;
    }
    &--devider {
      border: 1px solid #E0EBF6;
      width: 0;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
    }
    &--video {
      &-container {
        width: 596px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        &--count {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 120%;
          color: #2E3549;
        }
        &--source {
          display: flex;
          gap: 8px;
          &-label {
            user-select: none;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;

            color: #4B5677;
          }
          .support-main--toggle {
            span {
              font-family: 'Nunito';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;

              color: #009DC4;
            }
          }
          .red {
            span {
              color: #994401;
            }
            svg path {
              fill: #994401;
            }
          }
        }
      }
      &-body {
        width: 100%;
        height: 487px;
        overflow-y: auto;
        overflow-x: hidden;
        &--add {
          cursor: pointer;
          width: 100%;
          height: 100%;
          user-select: none;
          --color: #4B5677;
          --label-color: #009DC4;
          border: 1px dashed var(--color);
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap: 28px;
          align-items: center;
          justify-content: center;
          &-icon {
            display: block;
            font-size: 64px;
            color: var(--color);
          }
          &-label {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;

            color: var(--label-color);
          }
          &:hover, &:active, &:focus {
            --color: #FE7202;
            --label-color: #327DA0;
          }
        }
        &--list {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: auto;
          gap: 16px;
          &.disabled {
            opacity: .5;
            pointer-events: none;
          }
          &-add, &-item {
            border-radius: 8px;
            height: 97px;
            overflow: hidden;
          }
          &-add {
            cursor: pointer;
            --color: #4B5677;
            border: 1px dashed var(--color);
            background: #F7F7F7;
            display: flex;
            align-items: center;
            justify-content: center;
            &--optional {
              background: var(--color);
              border-radius: 4px;
              padding: 0 8px;
              margin-bottom: 8px;
              font-family: 'Nunito';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
              text-align: center;

              color: #FFFFFF;
            }
            &-icon {
              font-size: 21px;
              display: block;
              color: var(--color);
            }
            &:hover, &:active, &:focus {
              --color: #FE7202;
            }
          }
          &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            padding: 4px;
            gap: 4px;
            background-color: #E9EBFC;
            border: 1px solid #D3D6F8;
            background-image: var(--cover, none), url(../../assets/video_icon.svg);
            background-repeat: no-repeat, no-repeat;
            background-size: cover, 40% 40%;
            background-position: center, center;
            box-sizing: border-box;
            &--action {
              cursor: pointer;
              display: flex;
              background: rgba(35, 30, 47, 0.85);
              border-radius: 4px;
              width: 24px;
              height: 24px;
              align-items: center;
              justify-content: center;
              i {
                color: #FFFFFF;
              }
              &:hover, &:active, &:focus {
                background: #FE7202;
              }
            }
            &-wrap {
                position: relative;
                .staff-events-editor--video-body--list-item--action-container {
                    position: absolute;
                    display: grid;
                    gap: 4px;
                    top: 4px;
                    right: 4px;
                }
                &.dragged {
                  position: initial;
                  opacity:  0.5;
                  transform-origin: '0 0';
                }
            }
          }
        }
      }
    }
    &--modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px;
      gap: 32px;

      background: #FFFFFF;
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
      border-radius: 24px;
      &-overlay {
        position: fixed;
        z-index: 10000;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(24px);
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &-header {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 120%;
        text-align: center;

        color: #2E3549;
      }
      &-body {
        display: flex;
        gap: 24px;
      }
      &-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        .staff-events-editor--video-body--list-add, .staff-events-editor--video-body--list-item {
          width: 220px;
          height: 156px;
          &-icon {
            font-size: 34px;
          }
        }
        &--title {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 120%;
          text-align: center;

          color: #2E3549;
        }
      }
      &-devider {
        border: 1px solid #E0EBF6;
        align-self: stretch;
        width: 0px;
        height: auto;
      }
      &-action {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        button {
          flex: 1;
        }
      }
    }
  }
}

.events {
  &-list {
    &--container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 24px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
      backdrop-filter: blur(32px);
      border-radius: 24px;
      margin-bottom: 24px;
    }
    &--pin {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: 8px;

      background: #FFFFFF;

      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      &-img {
        border-radius: 8px;
        min-width: 360px;
        max-width: 780px;
        width: 100%;
        background-image: var(--cover, none);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &-body {
        flex: 1;
        padding: 24px 16px 12px 24px;
        min-width: 360px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
      &-title {
        color: var(--colors-ebony-clay, #2E3549);

        /* Bold/Title 1 */
        font-family: Nunito;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 33.6px */

      }
      &-date {
        order: -1;
        width: 100%;

        color: var(--colors-blue-bayoux, #6C7284);

        /* Regular/Caption 1 */
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
      }
      &-text {
        width: 100%;
        padding: 8px 8px 0px;
        height: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: #2E3549;
      }
      &-action {
        padding-top: 24px;
        width: 100%;
        &--button {
          @extend button, .clear;
          user-select: none;
          display: flex;
          gap: 8px;

          font-family: 'Nunito';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;

          align-items: center;
          justify-content: center;
          padding: 12px 24px;
          width: 100%;

          border-radius: 12px;
          transition: background-color .2s;

          &.disabled, &:disabled {
              opacity: .4;
              pointer-events: none;
          }
        }
        &--icon {
          transform: rotate(180deg);
          font-size: 12px;
        }
      }
    }
    &--other {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px 0px;
      gap: 16px;
      &-title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;

        color: #2E3549;
      }
      &-body {
        display: grid;
        row-gap: 16px;
        column-gap: 24px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
      }
    }
    &--card {
      display: flex;
      flex-direction: column;
      padding: 8px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 16px;
      &-img {
        border-radius: 8px;
        width: 100%;
        height: 137px;
        background-image: var(--cover, none);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 8px;
        gap: 24px;
        &--wrap {
          display: flex;
          flex-direction: column;
          padding: 0px;
          gap: 4px;
        }
      }
      &-date {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;

        color: #4B5677;
      }
      &-title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        word-break: break-word;
        color: #2E3549;
      }
      &-action {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: flex-end;
        &--button {
          @extend button, .clear;
          user-select: none;
          display: flex;
          gap: 8px;

          font-family: 'Nunito';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;

          align-items: center;
          justify-content: center;
          padding: 8px 16px;

          width: 100%;

          border-radius: 8px;
          transition: background-color .2s;

          &.disabled, &:disabled {
              opacity: .4;
              pointer-events: none;
          }
        }
        &--icon {
          transform: rotate(180deg);
          font-size: 8px;
        }
      }
    }
  }
  &-page {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;

    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
    backdrop-filter: blur(32px);
    border-radius: 24px;
    &--back {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;

      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #2E3549;
      &-icon {
        font-size: 14px;
      }
    }
    &--card {
      display: flex;
      flex-direction: column;
      padding: 8px;

      background: rgba(255, 255, 255, 0.6);
      border-radius: 16px;
      &-cover {
        height: 320px;

        background-image: var(--cover, none);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
      }
      &-body {
        padding: 24px 16px 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &-title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        word-break: break-word;
        color: #2E3549;
      }
      &-date {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 8px 0px;
        gap: 8px;
        border-top: 1px solid #E0EBF6;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #4D0CB9;
      }
      &-text {
        padding: 16px 0px 0px;
        border-top: 1px solid #E0EBF6;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2E3549;
      }
      &-video {
        &--list {
          border-top: 1px solid #E0EBF6;
          padding-top: 16px;
          display: grid;
          gap: 8px;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: auto;
        }
        &--item {
          cursor: pointer;
          position: relative;
          border-radius: 8px;
          height: 122px;
          background-image: var(--cover, none);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: #E9EBFC;
          &-play {
            width: 32px;
            height: 32px;
            padding-left: 3px;
            background: rgba(35, 30, 47, 0.85);
            border-radius: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &-icon {
            width: 12px;
            height: auto;
          }
        }
      }
    }
    &--overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      z-index: 10000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
      backdrop-filter: blur(24px);
      &-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 32px;
        cursor: pointer;
        --color: #fff;
        i {
            display: block;
            transition: color .1s, border-color .1s;
            color: var(--color);
            padding: 12px;
            border: 2px solid var(--color);
            border-radius: 40px;
        }
        &:hover {
            --color: #FE7202;
        }
      }
      &-video {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
      }
    }
  }
}
.mobile .events {
  &-list {
    &--title {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
      margin-top: 32px;
      margin-bottom: 24px;
    }
    &--container {
      padding: 8px;
      border-radius: 24px;
      gap: 0;
    }
    &--other {
      gap: 16px;
      &-title {
        align-self: center;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
      }
      &-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    &--card {
      &-date {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
      }
      &-title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
      }
      &-action {
        &--button {
          font-size: 12px;
          padding: 8px 16px;
          border-radius: 8px;
        }
        &--icon {
          font-size: 8px;
        }
      }
    }
  }
  &-page {
    margin-top: 32px;
    padding: 8px;
    gap: 16px;
    --margin: -16px;
    margin-left: var(--margin);
    margin-right: var(--margin);
    &--back {
      padding: 8px 0px 0px 8px;
      gap: 10px;
    }
    &--card {
      &-cover {
        height: 200px;
        border-radius: 8px;
      }
      &-body {
        padding: 16px 0 0;
        gap: 8px;
      }
      &-title {
        padding: 0px 8px;
        gap: 16px;
      }
      &-date {
        padding: 8px 8px 0px;
        gap: 8px;
      }
      &-text {
        padding: 8px;
        font-size: 16px;
      }
      &-video {
        &--item {
          height: 87px;
        }
      }
    }
  }
}
.staff-event--table-title {
  max-width: 520px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
