@font-face {
    font-family: 'McLaren';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/McLaren-Regular.ttf');
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../../public/fonts/Jost/92zatBhPNqw73oTd4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
