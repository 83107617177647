.session {
    &__timer {
        padding: 12px 13px 8px;
        margin-bottom: 16px;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        .card-body {
            padding: 0;
            img {
                margin-top:-3px;
            }
            hr {
                margin: 0 12px;
                width: 1px;
                height: 52px;
                background-color: #ffffff;
                opacity:1;
            }
            .text-muted {
                font-size: .75rem;
                font-weight: 400 !important;
                margin-left: -1px;
                &.kr {
                    font-size:.625rem !important;
                }
            }
        }
    }
    &__countdown {
        font-weight: 600;
        font-size: 1.75rem;
        color: #2E3549;
        margin-top: -4px;
        margin-left: -1px;
    }
}
