@use "./mixins.module.scss" as *;

.building_exchange {
  &__tab {
    &__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 4px;
    }
    &__btn {
      
    }
  }
  &__card {
    display: flex;
    padding: var(--sp-4, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--sp-4, 16px);
    align-self: stretch;

    border-radius: var(--r-6, 24px);
    background: var(--background-additional, #F3F5FB);
    &__downgrade {
      &__info {
        cursor: pointer;
        color: var(--scheme-link, #0BA1E1);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    &__viewer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      &__item {
        min-height: 152px;
        user-select: none;
        position: relative;
        display: flex;
        padding: var(--sp-6, 24px) 16px 16px 16px;
        flex-direction: column;
        align-items: center;
        gap: var(--sp-4, 16px);
        flex: 1 0 0;

        border-radius: var(--r-2, 8px);
        background: var(--background-main, #FFF);
        &.multi {
          padding: 16px;
          gap: 0;
        }
        &__down {
          display: flex;
          padding: var(--sp-1, 4px) 0px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          &:not(:first-child) {
            border-top: 1px solid #E0EBF6;
          }
          &__body {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .image {
            width: 32px;
            height: 32px;
            background-image: var(--img);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .title {
            overflow: hidden;
            color: var(--text-basic, #2E3549);
            text-align: center;
            text-overflow: ellipsis;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
          .icon {
            cursor: pointer;
            width: 12px;
            color: #414B67;
          }
        }
        &.empty {
          cursor: pointer;
          padding: 16px;
          justify-content: center;
          align-self: stretch;
          border-radius: var(--r-2, 8px);
          border: 1px dashed var(--text-additional-alt, #6C7284);
          background-color: transparent;
        }
        &__remove {
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
          border-radius: var(--r-4, 16px);
          background-color: #fff;
          cursor: pointer;
          display: flex;
          padding: 2px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          position: absolute;
          right: -4px;
          top: -4px;
          i {
            color: #414B67;
            font-size: 24px;
          }
          &:hover {
            i {
              color: #F44949;
            }
          }
        }
        &__icon {
          width: 24px;
          height: 24px;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
          border-radius: var(--r-4, 16px);
          background-color: #fff;
          display: flex;
          border: 2px solid #fff;
          justify-content: center;
          align-items: center;
          gap: 8px;

          position: absolute;
          right: -4px;
          top: -4px;
          &.down {
            background-color: #0BA1E1;
          }
          &.up {
            background-color: #7A85EB;
          }
          i {
            color: #414B67;
            font-size: 24px;
            &.green {
              color: #5BBC43;
            }
            &.white {
              color: #fff;
              font-size: 12px;
            }
          }
        }
        &__img {
          position: relative;
          width: 100%;
          height: 83px;
          z-index: 1;
          &::after, &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: var(--r-3, 12px);
            background-image: var(--img);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          &::before {
            opacity: 0.5;
            background-color: rgb(211 211 211 / 50%);
            filter: blur(16px);
          }
        }
        &__title {
          width: 100%;
          overflow: hidden;
          color: var(--text-basic, #2E3549);
          text-align: center;
          text-overflow: ellipsis;
          font-family: Nunito;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
        &__icon_plus {
          margin: 0;
          display: block;
          font-size: 32px;
          color: #414B67;
        }
      }
      &__arrow {
        font-size: 24px;
        pointer-events: none;
        color: #28262C;
      }
    }

    &__line {
      width: 100%;
      height: 1px;

      background: var(--strokes-main-darken, #E0EBF6);
    }
    &__price {
      display: flex;
      min-width: 58px;
      padding: var(--sp-2, 8px) var(--sp-4, 16px);
      align-items: flex-start;
      gap: var(--sp-4, 16px);
      align-self: stretch;

      border-radius: var(--r-2, 8px);
      background: var(--background-main, #FFF);
      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--sp-1, 4px);
        flex: 1 0 0;
      }
      &__label {
        color: var(--text-additional-alt, #6C7284);

        @include bold__caption_1;
      }
      &__value {
        display: flex;
        align-items: center;
        gap: var(--sp-1, 4px);

        color: var(--text-basic, #2E3549);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }
  &__confirm {
    width: 100%;
    display: flex;
    padding: var(--sp-3, 12px) var(--sp-4, 16px);
    align-items: center;
    gap: var(--sp-3, 12px);
    align-self: stretch;

    border-radius: var(--r-3, 12px);
    border: 1px solid var(--strokes-main-darken, #E0EBF6);
    background: var(--background-additional, #F3F5FB);
    &__icon {
      color: #414B67;
      font-size: 16px;
      &.red {
        color: #F44949;
      }
    }
    &__text {
      flex: 1;
      color: var(--text-additional, #414B67);
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  &__select {
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      &.sticky {
        position: sticky;
        top: -24px;
        z-index: 3;
        background-color: rgb(243, 245, 251);
        padding: 24px;
        margin: -24px;
      }
      &__tabs {
        display: flex;
        align-items: flex-start;
        gap: 4px;
      }
      &__pills {
        display: flex;
        align-items: center;
        gap: var(--sp-2, 8px);
        .label {
          white-space: nowrap;
          color: var(--text-additional, #414B67);
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .container {
          display: flex;
          align-items: center;
          gap: var(1px);
        }
        .item {
          user-select: none;
          cursor: pointer;
          display: flex;
          padding: var(--sp-1, 4px) var(--sp-3, 12px);
          align-items: center;
          gap: 8px;

          background: var(--background-main, #FFF);

          color: var(--text-additional, #414B67);
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 115%;
          &:first-child {
            border-radius: 12px 0px 0px 12px;
          }
          &:last-child {
            border-radius: 0px 12px 12px 0px;
          }
          &.active {
            display: flex;
            padding: var(--sp-1, 4px) var(--sp-3, 12px);
            align-items: center;
            gap: 8px;
            color: #fff;
            background: linear-gradient(0deg, #5BBC43 0%, #5BBC43 100%), var(--strokes-main-darken, #E0EBF6);
            box-shadow: 0px 4px 8px 0px rgba(91, 188, 67, 0.30);
          }
        }
      }
    }
    &__body {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      .loading-ref {
        grid-column: 1 / 5;
        height: 1px;
      }
    }
    &__item {
      position: relative;
      display: flex;
      width: 100%;

      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-4, 16px);
      flex: 1 0 0;
      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: var(--r-5, 20px);
        background: var(--background-main, #FFF);
      }
      &__head {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
      }
      &__image {
        position: relative;
        width: 100%;
        height: 124px;
        &::after, &::before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: var(--r-3, 12px);
          background-image: var(--img);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        &::before {
          opacity: 0.5;
          background-color: rgb(211 211 211 / 50%);
          filter: blur(16px);
        }
      }
      &__checked {
        pointer-events: none;
        z-index: 2;
        display: flex;
        padding: 2px;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        position: absolute;
        right: -8px;
        top: -8px;

        border-radius: var(--r-6, 24px);
        background: var(--background-main, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
        i {
          font-size: 24px;
          color: #5BBC43;
        }
      }
      &__templates {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: var(--base-card-pagination, 8px);
        &__btn {
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          border-radius: var(--r-4, 16px);
          background: transparent;
          color: var(--text-basic, #2E3549);
          text-align: center;
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */

          border: 1px solid var(--strokes-main-darken, #E0EBF6);
          &.active {
            border-color: transparent;
            border-radius: var(--r-4, 16px);
            background: var(--scheme-buttons-button, #28262C);
            box-shadow: 0px 4px 8px 0px rgba(40, 38, 44, 0.19);
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
          }
        }
      }
      &__body {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--sp-4, 16px);
        align-self: stretch;
      }
      &__title {
        flex: 1;
        overflow: hidden;
        color: var(--text-basic, #2E3549);
        text-align: center;
        text-overflow: ellipsis;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
      }
      &__action {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}

.mobile {
  .building_exchange {
    &__card {
      &__viewer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        &__item {
          width: 100%;
        }
      }
      &__arrow {
        transform: rotate(90deg);
      }
    }
    &__select {
      &__head {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        row-gap: var(--sp-4, 16px);
        &.sticky {
          top: -16px;
          padding: 16px 0;
          margin: 0;
          margin-top: -16px;
        }
      }
      &__body {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .loading-ref {
          grid-column: 1;
          height: 1px;
        }
      }

    }
  }
}