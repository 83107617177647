.redesign {
    nav.main-header {
        z-index:5;
        padding: 12px 0;
        & > * {
            z-index:1;
        }
    }
    .navbar-brand {
        display: flex;
        align-items: center;
        h1 {
            margin: 0 0 0 8px;
            color:  $color-primary;
            font-size: 16px;
            font-weight: 900;
            line-height: 150%;
        }
    }
    .container-fluid {
        max-width: $base-desctop-width;
        margin: 0 auto;
        padding: 0 24px;
        height: 37px;
    }

    .with-submenu {
        .dropdown-menu {
            position: absolute;
            top: -16px;
            left: calc(-99% - 4px);
            &.submenu {
                display:none !important;
                background-color: transparent;
                &.show-sub {
                    display: block !important;
                    & + .submenu__background {
                        display: block;
                    }
                }
            }
        }
        &.show-submenu {
            & + .submenu {
                display: inline-block;
            }
        }
        .submenu {
            display:none;
            &__background {
                width: 160px;
                height: 100%;
                top: -8px;
                left: calc(-99% - 4px);
                display: none;
                background: rgba(255, 255, 255, 0.75);
                border-radius: 16px;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px $color-white;
                backdrop-filter: blur(32px);
                position:absolute;
            }
        }
    }

    .link {
        &__linked-wallet {
            border-radius: 8px;
            &.valid {
                color: $color-green;
                background: rgba(91, 188, 67, 0.1);
                &:hover {
                    background: $color-green;
                    color: $color-white;
                }
            }
            &.invalid {
                color: $color-red;
                background: rgba(244, 73, 73, 0.1);
                &:hover {
                    background: $color-red;
                    color: $color-white;
                }
            }
            &.notconfirmed {
                color: #ECAB04;
                background: rgba(255, 184, 0, 0.1);
                &:hover {
                    background: rgba(236, 171, 4, 1);
                    color: $color-white;
                }
            }
        }
    }

    .nav-item {
        &-balance {
            font-size: 14px;
            font-weight: 700;
            border: none;
            white-space: nowrap;
            .img__wallet {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }
            &.balance {
                color: $color-orange-aw !important;
            }
            &.points, &.sysWalletBalance {
                color: $color-green !important;
            }
            &.tickets {
                color: $color-blue-pacific !important;
            }
            .faw-cross_round {
                color: $color-disabled !important;
                margin-left: 12.5px;
            }
        }
        &-balance:hover {
            .faw-cross_round {
                color: $color-accent !important;
            }
        }
    }
    .dropdown {
        .dropdown-toggle::after {
            margin-left: 8px;
            margin-right: -4px;
            margin-top: 0px;
        }
        &-divider {
            width: 1px;
            height: 12px;
            background-color: #CDD5E4;
            margin: 0;
        }
        &-menu {
            border: unset;
            margin-top: 8px;
            .dropdown-divider {
                border-color: #fff !important;
                height: 1px !important;
                width: 100%;
                margin: 0;
                opacity: 1 !important;
            }
            &.langs {
                margin-left: -20px;
            }

        }
    }

    .navbar-nav:first-child {
        gap: 8px;
    }

    .navbar-nav {
        gap: 16px;
        align-items: baseline;
        padding: 8px 0 !important;
        .nav-link, .btn-header {
            padding: 0 16px !important;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            border: none;
            color: $color-primary;
            &:hover {
                color: $color-accent !important;
            }
            &.active {
                color: $color-accent-dark !important;
            }
        }
        .btn-header {
            &:focus {
                box-shadow: none;
            }
            & + .dropdown-menu, .special-menu {
                padding-left: 8px ;
                padding-right: 8px;
                background: rgba(255, 255, 255, 0.75);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px $color-white;
                backdrop-filter: blur(32px);
                border-radius: 16px;
                &:first-child {
                    padding-top: 8px;
                }
                &:last-child {
                    padding-top: 8px;
                    z-index: 2;
                }
                & .dropdown-item {
                    padding: 8px 16px;
                    font-size: 14px;
                    font-weight: 700;
                    border-radius: 8px;
                    &:hover, &.hover {
                        color: $color-accent !important;
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    }
                    &.active, &:active {
                        color: $color-accent-dark !important;
                        background-color: $color-white;
                    }
                    
                }
            }
        }
    }
}

.mobile {
    .redesign {
        nav.main-header {
            padding: 8px 0;
            width: 100vw;
        } 
    }
   
    &-header {
        &-menu {
        .dropdown-menu {
            &.mobile-menu  {
                &.show {
                    left: calc(56px - 100vw);
                    width: 100vw;
                    height: 100vh;
                    background: $color-white;
                    margin-top: 0;

                }
            }

        }
        &:focus{
            outline: none;
            background: transparent;
            border: 0;
        }

        &:active, &.active {
            outline: none;
            background: transparent;
            border: 0;
        }
        img {
            margin-left: -1px;
            margin-top: -1px;
        }
        filter: grayscale(1);
        width: 40px;
        border: none;
        height: 40px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 12px;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0);
        --aw-time: .4s;
        transition: background-color var(--aw-time), box-shadow var(--aw-time), filter var(--aw-time), box-shadow var(--aw-time);
        &.active {
            filter: grayscale(0);
            background-color: $color-white;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        }
            .mobile & {
                margin-left: 0px !important;
            }
        }
    }
    &-menu {
        z-index: 2000;
        background: #fff;
        backdrop-filter: blur(32px);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 52px;
        height: calc(100vh - 52px);
        border-top: 1px solid $color-main-dark;
        animation: start .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) .2s both;
        padding: 16px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        hr {
            width: 48px;
            margin: 8px auto;
            border: 1px solid;
        }
        &.owner-menu {
            background: #F3F5FB;
        }
        .resource-list {
            display: flex;
            flex-wrap: wrap;
            gap: 4px 16px;
            &-item {
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                min-width: 87px;
            }
            &-icon{
                height: 24px;
            }
            
        }
        @keyframes start {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        &--section {
            
            &.with-border {
                border-top: 1px solid $color-main-dark;
            }
            & + .mobile-menu--section:not(.with-border) {
                padding-top: 0px;
            }
        }
        &--h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 21px;
            line-height: 120%;
            color: $color-primary;
            padding: 16px 12px;
            &.active-submenu {
                color: $color-accent-dark;
                border-radius: 16px ;
                background:  #F3F5FB;
            }
            &.open-submenu {
                color: $color-accent-dark;
                border-radius: 16px 16px 0px 0px;
                background:  #F3F5FB;
            }
            
            .faw-vector_right {
                font-size: 16px;
            }
            .faw-vector_down {
                font-size: 12px;
            }
        }
        &--item {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-size: 18px;
            line-height: 120%;
            display: inline-flex;
            color: $color-primary;
            font-weight:700;
            padding: 8px 16px;
            &.active {
                background: #FFFF;
                border-radius: 8px;
                color: $color-accent-dark;
            }
            
        }
        &--list {
            display: flex;
            flex-direction: column;
            padding: 0 8px 8px 8px;
            background: #F3F5FB;
            border-radius: 0px 0px 16px 16px ;
        }
        &--label {
            color: $color-additional;
            font-size: 18px;
            line-height: 120%;
            display: inline-flex;
            font-weight:700;
            padding: 8px;
        }
        .badge__soon {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
            color: $color-white;
            padding: 4px 8px;
            background-color: $color-orange;
            border-radius: 6px;
            margin-left: 12px;
            align-self: end;
        }
    }  
}

.header {
    &--tooltype {
        z-index: 10;
        pointer-events: none;
        display: inline-flex;
        padding-left: 2rem;
        padding-top: .5rem;
        &:not(.show) {
            visibility: hidden;
        }
        .tooltype--inner {
            padding: 1rem;
            background: rgba(46, 53, 73, 0.79);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(40px);
            border-radius: .5rem;

            font-family: Nunito, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 120%;
            color: #F9FBFD;
        }
    }
    &--balances-dropdown {
        position: relative;
        right: -16px;
        button.dropdown-toggle {
            .nav-item-balance {
                margin-top: unset;
            }
        }
        .faw-cross_round {
            display: none;
        }
        .dropdown-menu {
            padding: 8px;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px $color-white;
            backdrop-filter: blur(16px);
            border-radius: 16px;
        }
        .dropdown-toggle {
            .nav-item-balance {
                color: $color-orange-aw !important;
            }
        }
        .dropdown-item {
            padding: 8px 12px !important;
            min-width: 229px;
            cursor: default;
            background: #fff;
            .nav-item-balance {
                cursor: default;
            }
            & + .dropdown-item {
                margin-top: 4px;
            }
            &.balance {
                .nav-item-balance {
                    color: $color-orange-aw !important;
                }
            }
            &.commissions {
                .nav-item-balance {
                    color: $color-blue !important;
                }
            }
            &.itw {
                .nav-item-balance {
                    color: #5BBC43 !important;
                }
            }
            &-text {
                font-family: Nunito;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0em;
                text-align: left;
                color: #4B5677;
                padding: 0px;
            }
        }
    }
}

