.second,
.second * {
  box-sizing: border-box;
}
.second {
  z-index: 3;
  width: 1222.47px;
  height: 629px;
  min-height: 629px;
  position: relative;
}
.next-gen {
  color: #353451;
  text-align: left;
  font: 900 38px/100% "Rubik", sans-serif;
  position: absolute;
  margin-left: -6px;
  margin-top: 39px;
  width: 1228px;
  &-text {
    color: #3f3f4b;
    text-align: left;
    font: 400 18px/200% "Rubik", sans-serif;
    position: absolute;
    left: 783px;
    bottom: 68px;
    width: 464px;
    height: 285px;
  }
}
.img {
  width: 733px;
  height: 388px;
  position: absolute;
  left: 4px;
  top: 202px;
}
._1 {
  width: 733px;
  height: 388px;
  position: absolute;
  left: 0px;
  top: 0px;
}
