@use "./fonts.scss" as *;

.temp-svg {
  position: fixed;
  top: -200px;
  left: 0;
  right: 0;
  height: calc(100vh + 200px);
  pointer-events: none;
}

.landing_other_page {
  max-width: 1242px;
  margin: 0 auto;
  padding-top: 42px;
  :global{
    .btn-warning {
      &:hover {
        color: #ffffff;
        background-color: #7000BF;
      }
      &[disabled] {
          background-color:#52008d;
          color: #ffffff;
      }
      &:active, &:focus {
          color: #ffffff;
          border-color: #9B1EF2;
          background-color: #9B1EF2;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  background: #FFF;
  a:focus {
    outline: none;
  }
  --text-color: #FFF;
  --hover-color: #F3F5FB;

}
:global(.dark) {
  --text-color: #2C3653;
  --hover-color: #7000BF;
}
.dropdown {
  position: relative;
  &-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,.2);
    padding: 8px 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  &-item {
    white-space: nowrap;
    width: 100%;
    display: block;
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    &:hover {
      background: #EEE;
    }
  }
}

