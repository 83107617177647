.points {
    &-table {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 16px;
        margin-bottom: 0;
        &__total, &__total.sudo td {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;

            color: #4B5677;
        }
        &--wrap {
            overflow: hidden;
            border-radius: 16px;
            border: 1px solid #E0EBF6;
        }
        td, th {
            &:not(:first-child) {
                border-left: 1px solid #E0EBF6;
            }
        }
        tr:not(:last-child), thead tr {
            border-bottom: 1px solid #E0EBF6;
        }
        tr:nth-child(even) td {
            background: #F9FBFD;
            border-radius: 0;
        }
        td, th {
            padding: 8px 16px;
        }
        th {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;

            color: #4B5677;
        }
        td {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            color: #3A324E;
        }
    }
    &--page {
        max-width: 1302px;
        margin-left: auto;
        margin-right: auto;
        h4 {
            margin: 48px 0px 32px;
        }
        &-title {
            text-align: left !important;
            color: #2E3549;
        }
    }
    &--card {
        margin-bottom: 48px;
    }
    &--title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 120%;
        color: #2E3549;
        margin-bottom: 24px;
    }
    &--note {
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        margin-bottom: 16px;
        padding: 16px 24px;
        color: #2E3549;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        p {
            margin-bottom: unset;
        }
    }
    &--container {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 24px;
        padding: 24px;
        &_program {
            padding: 8px;
        }
        &_details {
            padding: 24px 24px 16px;
        }
        p {
            margin-bottom: unset;
        }
        hr {
            background: #E0EBF6;
            margin: 16px 0px;
        }
        .table {
            .faw-info_round {
                cursor: pointer;
            }
            &.plan {
                width: 36%;
                font-weight: 400;
                margin-bottom: 0px;
                td, th {
                    padding: 8px 0;
                    font-family: Nunito;
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                }
                & * {
                    color: #4B5677;
                }
                &.total {
                    td {
                        font-weight: 600;
                    }
                }
            }
            &.program {
                &.table {
                    margin-bottom: 0px;
                }
                tr {
                    &:first-child {
                        td {
                            padding: 0px 0px 16px;
                        }
                    }
                    &:last-child {
                        td {
                            padding: 16px 0px 0px;
                        }
                    }
                    td {
                        padding: 16px 0px;
                        color: #3A324E;
                        &:first-child {
                            color: #4B5677;
                        }
                    }
                }
                th {
                    padding: 0px 0px 24px;
                }
                .program {
                    &--link {
                        a {
                            color: #009DC4;
                            font-weight: 400;
                        }
                    }
                    &--status {
                        &.calculation {
                            color: #F0A920;
                        }
                        &.paid {
                            color: #5BBC43;
                        }
                    }
                }
            }
            &.details {
                margin-bottom: 0px;
                th {
                    padding: 0px;
                    font-size: 0.75rem;
                }
                tr {
                    &:first-child {
                        td {
                            padding-top: 12px;
                        }
                    }
                    &:last-child {
                        td {
                            padding-bottom: 0px;
                        }
                    }
                    td {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;

                        padding: 8px 0px;
                    }
                }
            }
        }
        ul {
            margin-bottom: 0px;
        }
        li {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 150%;
            &:not(:first-child) {
                margin-top: 8px;
            }
        }
        .program {
            &--link {
                a {
                    color: #009DC4;
                    font-weight: 400;
                }
            }
            &--status {
                &.calculation {
                    color: #F0A920;
                }
                &.paid {
                    color: #5BBC43;
                }
            }
        }
        .details {
            &--ap-value {
                color: #7A85EB !important;
            }
        }
    }
    &--back-link {
        display: flex;
        align-items: center;
        font-size: 21px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #2E3549;
        i {
            font-size: 0.875rem;
        }
        span {
            display: block;
            margin-left: 18px;
            font-size: 1.3125rem;
        }
    }
}
.mobile {
    .points {
        &--container {
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 24px;
            display: grid;
            gap: 8px;
            &-list {
                display: grid;
                gap: 16px;
                &_details {
                    gap: 8px;
                }
                &_founders {
                    gap: 8px;
                    margin-bottom: 16px;
                }
            }
            &_founders {
                grid-template-areas: "PHASE PHASE" "START_DATE START_DATE" "END_DATE END_DATE" "LAND_QUALIFICATION BUILDING_QUALIFICATION" "QUALIFY AP";
                .phase {
                    grid-area: PHASE;
                }
                .start_date {
                    grid-area: START_DATE;
                }
                .end_date {
                    grid-area: END_DATE;
                }
                .land_qualification {
                    grid-area: LAND_QUALIFICATION;
                }
                .building_qualification {
                    grid-area: BUILDING_QUALIFICATION;
                }
                .qualify {
                    grid-area: QUALIFY;
                }
                .ap {
                    grid-area: AP;
                }
            }
            &_totals {
                grid-template-areas: "TOTALS TOTALS";
                .totals {
                    grid-area: TOTALS;
                }
            }
            &_program {
                grid-template-areas: "MONTH REVENUE_AP" "REVENUE_ABEY REVENUE_ABEY" "STATUS STATUS" "UPDATED_AT UPDATED_AT";
                .month {
                    grid-area: MONTH;
                }
                .ap_received {
                    grid-area: AP_RECEIVED;
                }
                .revenue_ap {
                    grid-area: REVENUE_AP;
                    .program--link {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        i {
                            padding-left: 12px;
                            border-left: 1px solid #E0EBF6;
                        }
                    }
                }
                .revenue_abey {
                    grid-area: REVENUE_ABEY;
                }
                .status {
                    grid-area: STATUS;
                }
                .updated_at {
                    grid-area: UPDATED_AT;
                }
            }
            .program {
                &--link {
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
        &--card {
            margin-bottom: 32px;
        }
        &--container {
            padding: 8px;
            ul {
                padding: 8px;
                //margin-top: 16px;
                //padding: 0px 16px 16px;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                li:not(:first-child) {
                    margin-top: 8px;
                }
            }
            &-item {
                padding: 16px;
                background: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 16px;
                &-title {
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 8px;
                    color: #4B5677;
                }
                &-value {
                    font-size: 1.125rem;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #2E3549;
                }
                .total {
                    font-weight: 700;
                }
            }
        }
        &--page {
            h4 {
                margin: 16px 0px 24px;
            }
            &-title {
                font-size: 1.75rem !important;
            }
        }
        &--title {
            font-size: 1.3125rem;
            margin-bottom: 16px;
        }
        &--back-link {
            padding: 16px 18px;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 24px;
            margin-bottom: 16px;
            span {
                font-size: 1rem;
                margin-left: 10px;
            }
        }
    }
}
#pointsTooltip {
    border-radius:8px;
    padding:4px 8px;
    background-color:#2E3549;
    &:before {
        border-width: 0.4rem 0.4rem 0;
        position: absolute;
        content: "";
        border-color: transparent;
        border-top-color: rgba(35, 30, 47, 0.85);
        border-style: solid;

    }
    &.d {
        max-width: 519px;
        height: fit-content;
        margin-bottom: 8px;
    }
    &.m {
        width: calc(100% - 48px);
        margin-bottom: 8px;
        left: -19px !important;
    }
    &.d:before {
        top: 100%;
        left: 50%;
    }
    &.m:before {
        top: 100%;
        right: 18px;
    }
}
