@mixin header-min {
    @media (max-width: 1342px) { @content; }
}

hr {
    color: #E0EBF6 !important;
    opacity: 1 !important;
    
}

.promt-parent {
    filter: url('#goo');
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.tooltip {
    &.show {
        z-index: 10001;
    }
}

.rotate-45 {
    transform: rotate(45deg);
}
.sm-icon {
    font-size: 11px;
}
.br-3 {
    border-radius: 16px !important;
}
.br-12px {
    border-radius: 12px !important;
}

.no-data-wrap {
    .no-data-content {
        text-align: center !important;
        padding: 1rem;
        background:  transparent ;
        border-radius: 0;
        margin: 0;
    }
}

.mobile {
    .no-data-wrap {
        padding: 8px !important;
        background: #fff;
       
        border-radius: 24px;
        .no-data-content {
            
        }
    }
}

.page-list-dd {
    max-height: 200px;
    overflow: auto;
}
.z-5000 {
    z-index: 5000;
}

.badge__soon {
    display: inline;
    text-transform: uppercase;
    font-size: 0.5625rem;
    background-color: #FE7202;
    color: #fff;
    border-radius: 4px;
    padding: 2px 4px;
    position: relative;
    top: -2px;
    left: 2px;
}