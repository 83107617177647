.usdt-history {
    .status-cell {
        &.pending {
            color: #ECAB04;
        }
        &.completed {
            color: #5BBC43;
        }
        &.failed {
            color: red;
        }
        &.expired  {
            color: gray;
        }
    }
    .mobile-table--cell {
        border-radius: 16px;
    }
    .nodata {
        background: transparent !important;
        border-radius: 0px !important;
    }
    .hash-value {
        word-wrap: break-word;
        max-width: 28vw;
        &.mobile-table--cell-value {
            max-width: initial;
        }
    }
}

.usdt-history, .assets-history {
    .special-container {
        input, select, button {
            padding: 6px 32px 6px 8px;
            line-height: 1.25;
            display: flex;
            align-items: center;
            border-radius: 8px;
        }
        input[type="date"] {
            // padding: 5px 8px;
            line-height: 1.15;
            &:after {
                top: 11px;
            }
        }
        button {
            padding: 6px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            border-radius: 8px;
        }
    }
}
