.staff-td--checkbox {
    padding-right: 38px !important;
    padding-left: 38px !important;
    .form-check-input {
        width: 46px;
        display: flex;
    }
}
.multi-click {
    cursor: pointer;
}
.staff-body, .staff-title {
    font-family: 'Nunito';
    font-style: normal;
}
.staff-title{
    height: 80px;
      a.navbar-brand {
        .faw-logo {
            font-size: 3rem;
        }
      }
}
.staff-nav {
  margin-left: 56px;
  &.in-nav-dd {
    padding: 0;
    .dropdown-toggle {
        padding: 0;
    }
  }
}

.back-btn {
    position: absolute;
    margin-left: -100px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    padding: 0 !important;
    margin-top: -24px !important;
    border: none !important;
}
.staff-body {
    height: unset;
    height: calc(100vh - 80px);
    overflow: auto;
    width: auto;
    background-image: url('../../../public/bg_abstract.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 48px 32px 72px 32px;
    input, select {
        background-color: #fff;
    }
    .dropdown-menu {
        max-height: 300px;
        overflow-x: auto;
      }
    .main-container & {
        // margin-top: 80px;
    }
    h1 {
        font-size: 38px;
        color: #2E3549;
    }
    .dropdown-toggle::after{
        @extend [class*=" faw-"];
        content: "\e918";
        font-size: .5835rem;
        color: white;
        border: 0;
        margin-left: 13.5px;
    }
    .btn {
        padding-left: 24px;
        padding-right: 24px;
        font-weight: 600;
        font-size: 16px;
        &.btn-light{
          border: 1px solid #3A324E;
          padding-top: 11px;
          padding-bottom: 11px;
        }
    }
    .login-page {
        color: #2E3549;
        margin: auto;
        .login-form {
            max-width: 342px;
            margin: 0 auto;
            padding: 24px;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 24px;
        }

    }
    .title-nav-tab {
        color: #2E3549;
        opacity: 0.3;
        margin-left: 48px;
    }
    .table-wrap {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        // overflow: auto;
        border-radius: 24px;
        .announcements-table {
            thead {
                tr {
                    th {
                        padding-bottom: 8px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding-bottom: 17px !important;
                        padding-top: 17px !important;
                        font-size: 18px;
                        &.title {
                            font-weight: 700;
                        }
                    }
                }
            }
            tr {
                td:last-child {
                    border-radius: 0;
                }
                td:first-child {
                    border-radius: 0;
                }
                th {
                    white-space: nowrap;
                }
                &.opened {

                    border-bottom: 1px solid #E0EBF6;
                    td:first-child {
                        border-top-left-radius: 16px !important;
                    }
                    td:last-child {
                        border-top-right-radius: 16px !important;
                    }
                }
                &.last {
                    td:first-child {
                        border-bottom-left-radius: 16px !important;
                    }
                    td:last-child {
                        border-bottom-right-radius: 16px !important;
                    }
                }
                &.inner-row {
                    td {
                        padding-bottom: 13px !important;
                        padding-top: 13px !important;
                    }
                }
                .td-btn{
                    padding: 16px 34px 16px 0;
                }
            }
        }
        .pt-management {
            thead {
                tr {
                    th {
                        padding-bottom: 8px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding-bottom: 8px !important;
                        padding-top: 8px !important;
                        max-height: 24px;
                        color: var(--colors-ebony-clay, #2E3549);
                        font-family: Nunito, sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        &.title {
                            font-weight: 700;
                        }
                    }
                }
            }
            tr {
                td:last-child {
                    border-radius: 0;
                }
                td:first-child {
                    border-radius: 0;
                }
                th {
                    white-space: nowrap;
                }
            }
        }
    }
    .staff-td--checkbox {
        .form-check-input {
            background-image: url('../../../public/check-false.svg');
            border: 0;
            background-color: transparent;
            width: 36px;
            height: 20px;
        }
        .form-check-input:checked {
            background-image: url('../../../public/check-true.svg');
            border: 0;
            background-color: transparent;
        }
    }
    .anonsment-check-input {
        display: flex;
        padding: 0;
        cursor: pointer;
        .form-check-input{
            width: 20px;
            height: 20px;
            margin: auto 0;
            cursor: pointer !important;
        }
        .form-check-label{
            margin-left: 8px;
            color: #4B5677;
            font-weight: 400;
            cursor: pointer !important;
        }
    }
    .form-select, .form-control{
        padding: 12px 32px 12px 16px;
        height: 48px;
    }

    .form-switch{
        padding-left: 0;
        .form-check-input {
            margin-left: 0;
            cursor: pointer;
        }
    }
    .edit-anonsment-form {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        height: 100%;

        .tab-pane {
            &.active{
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;

            }
        }
        .nav {
            &.nav-tabs{
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                border: 0;
                padding-bottom: 0px !important;
                button {
                    &.active {
                        @extend .btn;
                        @extend .btn-info;
                        @extend .btn-sm;
                        padding: 6px 16px;
                    }
                    &.active:focus {
                        background-color: #51466D;
                    }
                    height: 38px !important;
                    padding: 6px 16px;
                    margin-right: 8px;
                }

            }
        }
        .quill {
            height: calc(100vh - 656px);
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .ql-toolbar{
                flex: 0;
            }
            .ql-container {
                flex: auto;
                font-weight: 400;
            }
        }
    }
    .management-filter {
        .form-select, .form-control{
            width: 178px;
        }
    }
    .edit-user-wrapper {
        width: 376px;
        margin: 0 auto;
        .edit-user-form {
            border-radius: 24px;
            background: rgba(255, 255, 255, 0.6) !important;
            .invalid-feedback {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #F44949;
            }
            h1 {
                font-size: 21px;
            }
        }
    }
    .edit-member-wrapper {
        width: 938px;
        margin: 0 auto;
        .edit-member-form {
            border-radius: 24px;
            background: rgba(255, 255, 255, 0.6) !important;
            .icon-wallet {
                font-size: 30px;
                align-self: center;
            }
            .block-info {
                border-radius: 16px;
                padding: 24px;
                background: #FFFFFF;
                color: #2E3549;
                &.status-confirmed {
                    background: rgba(91, 188, 67, 0.1);
                }
                &.status-added {
                    background: rgba(236, 171, 4, 0.1);
                }
                label {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 150%;
                    color: #4B5677;
                }
                hr {
                    margin-top: 8px;
                }

            }
            .header {
                font-weight: 700;
                font-size: 21px;
                line-height: 120%;
                align-self: center;
            }
            .value {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
            }
        }
    }

    select {
        // background-color: transparent;
    }
}
.single-form {

    max-width: 376px;
    margin: 0 auto;
    padding: 24px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
    backdrop-filter: blur(32px);
    border-radius: 24px;
    .form {
        &-label {
            color: #505D77;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
        &-title {
            color: #2E3549;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 90%;
        }
    }
    .btn {
        border-radius: 12px !important;
    }
}
.congratulations-popup {
    display: flex;
    width: 523px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: auto;
    .card {
        border-radius: 24px;
        &-title {
            font-size: 1.75rem;
        }
    }
    p {
        color:  #2E3549;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }
    .faw-check_round {
        font-size: 3rem;
    }
}
.flex-1 {
    flex: 1;
}
.flex-0 {
    flex: 0;
}
.flex-auto {
    flex: auto;
}
.w-20 {
    width: 20%;
}
.staff-divider {
    border-top: 1px solid #E0EBF6;
    margin: 0 24px 16px 24px;
}
.bg-transperent {
    background: transparent;
}
.bg-transperent:disabled {
    background: transparent;
}
.ql-source {
    padding: 0 !important;
}
.ql-source:after {
  content: "</>";
}
.announcements-alert {
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 80px;
}

.staff-tutorial-files-container {
    overflow-x: auto;
    .img_loader--body {
        min-width: 120px;
    }
}

.staff-submenu {
    &--container {
        display: flex;
        padding: 4px;
        align-items: center;
        background: rgba(255, 255, 255, .6);
        border-radius: 12px;
    }
    &--link {
        display: inline-flex;
        white-space: nowrap;
        padding: 8px 24px;
        background: transparent;
        border-radius: 8px;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #4B5677;
        &.active {
            background: #51466D;
            color: #FFFFFF;
        }
    }
}

.staff-support {
    &--avatar {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E0EBF6;
        background-image: var(--avatar, none);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &--status {
        &-in-progress {
            color: #327DA0;
        }
        &-open {
            color: #5BBC43;
        }
    }
    &--detail {
        color: #009DC4;
        &:hover {
            color: #327DA0;
        }
        &:active {
            color: #327DA0;
            text-decoration: underline;
        }
    }
    &--tr {
        td {
            &:first-child {
                border-left: 8px solid transparent;
                border-radius: 16px 0 0 16px !important;
            }
            &:last-child {
                border-right: 8px solid transparent;
                border-radius: 0 16px 16px 0 !important;
            }
        }
        &:hover {
            td {
                background-color: #FFFFFF;
            }
        }
    }
    &--color-state {
        &--open {
            color: #5BBC43 !important;
        }
        &--in-progress {
            color: #327DA0 !important;
        }
        &--closed {
            color: $color-accent !important;
        }
    }
    &--assigned {
        &-container {
            display: flex;
            align-items: flex-start;
            margin-left: -12px;
            margin-right: -12px;
            > * {
                margin-left: 12px;
                margin-right: 12px;
                width: 100%;
            }
        }
        &-card {
            user-select: none;
            display: flex;
            flex-direction: column;
            // align-items: center;
            padding: 16px;
            gap: 24px;

            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);

            border-radius: 24px;
            &--header {
                display: flex;
                align-items: center;
            }
            &--state {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 120%;
            }
            &--title, &--title a {
                flex: 1;
                user-select: none;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #2E3549;
            }
            &--body {
                gap: 16px;
                display: flex;
                flex-direction: column;
            }
            &--collapser {
                cursor: pointer;
                &.close {
                    transform: rotateX(180deg);
                }
            }
        }
        &-item {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
            background: #FFFFFF;
            border-radius: 16px;
            &.member:hover, &.member:focus {
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            }
            &--title {
                user-select: text;
                font-family: 'Nunito', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 120%;

                color: #2E3549;
                height: 50px;
                width: 100%;
                overflow-y: hidden;
                text-overflow: ellipsis;
            }
            &--new {
                width: 6px;
                height: 6px;
                background: #F0A920;
                border-radius: 50%;
                margin-left: 2px;
            }
            &--devider {
                margin: 16px 0;
                height: 0px;
                border: 1px solid #E0EBF6;
            }
            &--subtitle {
                font-family: 'Nunito', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;

                color: #4B5677;
                margin-bottom: 2px;
            }
            &--date {
                font-family: 'Nunito', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #2E3549;
            }
        }
    }
}

.support-ticket {
    .mobile & {
        &--create {
            &-inner {
                padding: unset;
                align-items: baseline;
                background-color: transparent;
                backdrop-filter: unset;
                box-shadow: unset;
                gap:unset;
                &.mt-4 {
                    margin-top: 25px !important;
                }
            }
            &-form {
                margin-top: 24px;
            }
            &-title {
                text-align:left;
            }
            &-container {
                padding: 16px;
                background: rgba(255, 255, 255, 0.6);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(32px);
                border-radius: 24px;
            }
        }
    }
    &--create {
        &-container {
            width: 100%;
            max-width: 523px;
        }
        &-back {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: #2E3549;

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            img {
                width: 14px;
                height: auto;
            }
        }
        &-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 32px;
            gap: 24px;

            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 24px;
        }
        &-title {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
            text-align: center;

            color: #2E3549;
        }
        &-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }
    }
    &--file {
        &-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            gap: 16px;

            background: #FFFFFF;
            border-radius: 16px;
        }
        &-img {
            img {
                width: auto;
                max-height: 36px;
            }
        }
        &-body {
            flex: 1;
        }
        &-title {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;

            color: #2E3549;

            overflow: hidden;
            max-height: 43.19px;
            text-overflow: ellipsis;
            line-break: anywhere;
        }
        &-subtitle {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;

            color: #4B5677;
        }
        &-delete {
            color: #51466D;
            &:hover, &:focus {
                color: #4D0CB9;
            }
        }
        &-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;

            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            &.link {
                cursor: pointer;
                color: #009DC4;
                &:hover {
                    color: #327DA0;
                  }
                &:active {
                    color: #327DA0;
                }
            }
        }
    }
}

.support-main {
    &--container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 24px;
        height: fit-content;

        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);

        border-radius: 24px;
    }
    &--left {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        overflow: hidden;
    }
    &--right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        gap: 40px;
        min-width: 430px;
        height: 100%;

        background: #FFFFFF;
        border-radius: 16px;
    }
    &--close {
        button {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
        }
    }
    &--info {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 24px;
        }
        &-label {
            width: 100px;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #4B5677;
        }
        &-col {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: #2E3549;
        }
    }
    &--creator {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        &-header {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #4B5677;
        }
        &-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
        }
        &-avatar {
            width: 48px;
            height: 48px;
            background-color: #F7F7F7;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover, auto;
            background-image: var(--avatar, none), url(../../assets/image.svg);
            border-radius: 32px;
        }
        &-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 2px;
        }
        &-name {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;

            color: #2E3549;
        }
        &-action {
            button {
                outline: none !important;
                box-shadow: none !important;
                background: transparent;
                border: 0;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                color: #009DC4;
                padding: 0 !important;
                &:hover {
                    color: #327DA0;
                }
                &:active {
                    color: #327DA0;
                    text-decoration: underline;
                }
            }
        }

    }
    &--back {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
        &-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;

            color: #2E3549;
        }
    }
    &--header {
        width: 100%;
        padding: 16px;
    }
    &--title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;

        color: #2E3549;
        &-body {
            margin-top: 32px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
        }
    }
    &--subtitle {
        display: inline-flex;
        align-items: center;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        color: #2E3549;
        &-b {
            font-weight: 900;
        }
    }
    &--tabs {
        width: 100%;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 1033px 0px 0px;
        gap: 16px;

        border-bottom: 1px solid #E0EBF6;
    }
    &--tab {
        cursor: pointer;
        user-select: none;
        box-sizing: border-box;
        text-transform: capitalize;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 8px;
        gap: 8px;

        border-bottom: 1px solid transparent;
        margin-bottom: -1px;

        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;

        color: #4B5677;
        &.active {
            border-bottom: 1px solid #4D0CB9;
            color: #4D0CB9;
        }
    }
    &--chat {
        width: 100%;
        min-height:323px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        &-messages {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        &-group {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
            &.user {
                .msg {
                    &-avatar {
                        background: rgba(35, 30, 47, 0.85);
                        color: #FFFFFF;
                    }
                    &-body {
                        background: rgba(35, 30, 47, 0.85);
                    }
                    &-text {
                        color: #FFFFFF;
                    }
                    &-date {
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }
            &.staff {
                .msg {
                    &-avatar {
                        background: #FFFFFF;
                        color: #2E3549;
                    }
                    &-body {
                        background: #FFFFFF;
                    }
                    &-text {
                        color: #2E3549;
                    }
                    &-date {
                        color: #B5BDCA;
                    }
                }
            }
            &--body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;
                flex: 1;
            }
            &--message {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                padding: 16px 24px;
                gap: 8px;

                border-radius: 0px 12px 12px 12px;

                &-text {
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 120%;
                    white-space: pre-wrap;
                    line-break: anywhere;
                    a {
                        font-weight: 400;
                    }
                }
                &-date {
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 150%;
                    align-self: flex-end;
                }
                &-file {
                    display: flex;
                    gap: 8px;
                    margin-right: 8px;
                    &--image {
                        img {
                            height: 38px;
                            width: auto;
                        }
                    }
                    &--body {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                    }
                    &--name {
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 120%;
                    }
                    &--size {
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                    }
                }
            }
        }
        &-avatar {
            width: 48px;
            height: 48px;
            background-color: #ffffff;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: var(--avatar, none);
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-input {

        }
    }
    &--input {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 16px;
        gap: 4px;
        &-file {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            gap: 16px;

            background: rgba(255, 255, 255, 0.6);
            &--body {
                display: flex;
                align-items: center;
                gap: 8px;
            }
            &--img {
                display: flex;
                img {
                    height: 16px;
                    width: auto;
                }
            }
            &--name {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 120%;

                color: #2E3549;
            }
            &--size {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;

                color: #4B5677;
            }
            &--close {
                cursor: pointer;
                user-select: none;
                &:hover, &:focus {
                    color: #F0A920;
                }
            }
        }
        &-container {
            background: #FFFFFF;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 12px 24px;
            gap: 16px;
        }
        &-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
            flex: 1;
        }
        &--select-file {
            cursor: pointer;
            user-select: none;
            color: #B5BDCA;
            &:hover, &:focus {
                color: #4B5677;
            }
        }
        &-field {
            display: flex;
            align-items: center;
            position: relative;
            max-height: 242px;
            overflow-y: auto;
            textarea {
                min-height: 28px;
                width: 100%;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 120%;
                color: #2E3549;
                border: none !important;
                box-shadow: none !important;
                outline: none !important;
                resize: none;
                overflow: hidden;
                &::placeholder {
                    color: #B5BDCA;
                }
            }
        }
        &-send {
            cursor: pointer;
            user-select: none;
            filter: grayscale(1) !important;
            &:hover, &:focus {
                filter: grayscale(0) !important;
            }
        }
    }
    &--history {
        width: 100%;
        flex: 1;
        padding: 16px;
        padding-top: 0;
        overflow: auto;
        &-table {
            table {

            }
            th, td {
                padding: 16px 8px;
            }
            th {
                width: 25%;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #4B5677;
                padding-top: 0;
            }
            td {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 120%;
                color: #2E3549;
            }
        }
    }
    .mobile & {
        &--container {
            height: auto;
        }
        &--history {
            &-table {
                th {
                    &:not(:first-child) {
                        padding-left: 32px;
                    }
                    &:last-child {
                        padding-right: 32px;
                    }
                }
                td {
                    &:not(:first-child) {
                        padding-left: 32px;
                    }
                    &:last-child {
                        padding-right: 32px;
                    }
                }
            }
        }
        &--title {
            font-size: 1.3125rem;
            &-body {
                margin-bottom: 28px;
            }
        }
        &--left {
            background-color: #ffffff;
            border-radius: 16px;
        }
        &--right {
            min-width: unset;
            width: 100%;
            padding: 16px;
            gap: 25px;
            height: fit-content;
        }
        &--subtitle {
            font-size: 12px;
            span {
                display: contents;
            }
        }
        &--chat {
            min-height: unset;
            max-height:calc(100vh - 506px);
        }
        &--chat-avatar {
            width: 32px;
            height: 32px;
        }
        &--back {
            justify-content: space-between;
            font-size: 1.375rem;
            color: #51466D;
        }
        &--chat-group--message {
            padding: 8px 16px;
        }
        &--info {
            overflow: unset;
        }
        &--info-item {
          flex-direction: column;
          align-items: baseline;
          gap: 4px;
        }
        &--info-label {
            white-space: nowrap;
        }
        &--close {
            margin-top:12px;
            width: 100%;
        }
        &--input-send {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
.support-attachments-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px !important;
    .sam-item {
        color: #2C3653;
        text-decoration: none;
        font-weight: 400;
        display: flex;
        align-items: center;
    }
    .sam-name {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        width: 240px;
        text-overflow: ellipsis;
    }
}
.aw-custom-dropdown {
    position: initial;
    &--list {
        overflow-y: auto;
        max-height: 50vh;
    }
    &_lang {
        .dropdown {
            &-menu {
                border: none;
                // border-radius: 12px;

            }
            &-item {
                padding: 8px 16px;
                font-weight: 600;
                border-radius: 8px;
                &:hover, &.hover, &.active {
                    background: #FFFFFF;
                    color: #FE7202;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                }
                &.active {
                    color: #994401 !important;
                }
                &.hover {
                    color: #FE7202 !important;
                }
            }
        }
    }
}
.v-line {
    border-left: 1px solid #E0EBF6;
    &.d-24px {
        margin-left: 24px;
        padding-left: 24px;
    }
}

.grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }
  .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
  .grow-wrap > textarea,
  .grow-wrap::after {
    /* Identical styling required!! */
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }

img {
    &.member-avatar {
        width: 48px;
        height: 48px;
    }
}

.purchases {
    .table-wrap {
        min-width: 1061px;
    }
    .staff-divider {
        opacity: 1;
        margin: 0px 16px 16px 16px;
    }
    .filter {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px 20px 16px;
        .btn {
            margin-bottom: 0px;
        }
        &--form {
            display: flex;
            form {
                display: flex;
                label {
                    line-height: 1.5;
                }
                & > div {
                    display: flex;
                    flex-direction: column;
                }
                & > *:not(:first-child) {
                    margin-left: 16px;
                }
                #product {
                    text-overflow: ellipsis;
                    padding-right: 32px;
                    max-width: 179px;
                }
                #properties {
                    text-overflow: ellipsis;
                    padding-right: 32px;
                    max-width: 220px;
                }
                #status {
                    width: 124px;
                }
            }
        }
        &--info {
            display: flex;
            align-items: flex-end;
            margin-left: 16px;
            .total {
                &--card {
                    border-left: 1px solid #E0EBF6;
                    padding-bottom: 2px;
                }
            }
            & + .staff-divider {
                margin-top: 16px;
            }
        }
        &--controller {
            display: flex;
            align-items: flex-end;
        }
        &--btn {
            &--submit, &--reset {
                margin-top: 20px;
            }
        }
        .form-control, .form-select {
            height: 34px !important;
            border-radius: 8px;
        }
        .form-select {
            padding: 5px 8px;
            option {
                font-size: 1rem;
            }
        }
        .form-control {
            padding: 5px 8px !important;
            &#awPurchaseId {
                width: 95px;
            }
            &#memberId, &#staffId {
                width: 84px;
            }
            &#product {
                width: fit-content;
            }
            &#status {
                width: fit-content;
            }
            &#fromId, &#toId {
                width: 132px;
                position: relative;
                &:after {
                    position: absolute !important;
                    right: 8px !important;
                }
            }
            &[type=date] {
                padding: 5px 31px 5px 8px !important;
            }
        }
        button {
            padding: 8px 16px !important;
            height: 34px;
            font-size: 12px;
            line-height: 1;
        }
    }
    .total {
        &--card {
            padding: 0px 16px;
            display: flex;
            height: 24px;
            justify-content: center;
            align-items: center;
            &:first-child {
                border-left: unset;
                padding-left: 0px;
            }
        }
        &--title {
            font-weight: 700;
            font-size: .75rem;
            line-height: 1;
            color: #505D77;
        }
        &--value {
            margin-left: 8px;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1;
            color: #2E3549;
        }
    }
    th {
        font-size: .75rem;
        padding: 6px 20px !important;
    }
    td {
        font-size: 1rem !important;
        padding: 8px 20px !important;
    }
    .table {
        width: calc(100% - 16px);
        margin: auto;
        td {
            &:first-child {
                border-bottom-left-radius: unset;
            }
            &:last-child {
                border-bottom-right-radius: unset;
            }
        }
        .bg-light {
            td:first-child {
                border-top-left-radius: 16px !important;
            }
            td:last-child {
                border-top-right-radius: 16px !important;
            }
            & + .bg-light {
                td:first-child {
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 16px !important;
                }
                td:last-child {
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 16px !important;
                }
            }
        }
    }
    .totals {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #E0EBF6;
        border-bottom: 1px solid #E0EBF6;
        padding: 16px 0px;
        margin: 4px 16px 18px 16px;
        .total {
            &--card {
                display: block;
                height: initial;
            }
            &--title {
                color: #505d77;
                font-size: 12px;
                font-weight: 700;
                line-height: 150%;
                margin-bottom: 8px;
            }
            &--value {
                color: #2e3549;
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                margin-left: 0;
            }
            &--delimiter {
                width: 1px;
                background-color: #E0EBF6;
            }
        }
    }

}
.purchase-detail {
    width: 926px;
    margin: 0 auto;
    .faw-arrow_left {
        font-size: 0.875rem;
    }
    &--content {
        padding: 16px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        h5 {
            font-size: 1.3125rem;
            color: #2E3549;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 12px;
        }
        &_transparency {
            background-color: transparent;
            box-shadow: unset;
        }
    }
    &--info {
        display: block;
        //flex-wrap: wrap;
        //flex-direction: column;
        &-title {
            font-size: 1.3125rem;
            color: #2E3549;
            margin-bottom: 8px;
            .purchase-detail--info-item + & {
                margin-top: 24px;
            }
        }
        &-item {
            flex-grow: 1;
            flex-basis: 40%;
            padding: 24px;
            background-color: #ffffff;
            border-radius: 16px;
            &_with-table {
                padding: 16px;
                .table {
                    th {
                        padding: 0px 0px 12px;
                    }
                    tr:last-child {
                        td {
                            padding-bottom: 0px;
                        }
                    }
                    td {
                        padding: 12px 0px;
                    }
                }
            }
            & > div:not(:last-child) {
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;
            }
            .table {
                margin-bottom: 0px;
            }
            .form {
                &-label {
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.75rem;
                    margin-bottom: 4px;
                    color:#4B5677;
                }
                &-control-plaintext {
                    font-size: 1rem;
                    padding-top: 0px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #E0EBF6;
                    border-top: unset;
                }
            }
            textarea.form-control {
                font-size: 1rem;
                padding: 0px;
                padding-bottom: 8px;
                border: none;
                border-bottom: 1px solid #E0EBF6;
                border-radius: 0;
                min-height: 160px;
            }
        }
    }
    .coord {
        font-size: 1rem;
        font-weight: 400;
        font-family: 'Nunito';
    }
    .currency-price {
        font-size: 1rem;
        font-weight: 400;
        color: #2E3549;
        border-bottom: 1px solid #E0EBF6;
        padding-bottom: 4px;
    }

}
.refund-confirmation {
    &.card {
        margin: 0 auto;
        width: 414px;
        padding: 32px;
    }
    .alert {
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        color: #2E3549;
        margin-bottom: 24px;
    }
    .card {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        &-title {
            font-size: 2.375rem;
            font-weight: 700;
            line-height: 46px;
            letter-spacing: 0em;
            margin-top: 18px;
            margin-bottom: 24px;
        }
    }
    .icon {
        width:44px;
        height:44px;
        border-radius:32px;
        background-color:#ECAB04;
        color:#ffffff;
        margin:auto;
        display: flex;
        justify-content:center;
        align-items:center;
        font-size: 50px;
        i {
            display: block;
        }
    }
}
.td-btn {
    button i {
        &:hover, &:active, &:focus {
            color: #FE7202 !important;
        }
    }
}
.plot-sales {
    margin: 0px;
    padding-top: 0px;
    .table {
        margin-bottom: 8px;
    }
    .table-wrap {
        padding: 0px 8px;
        margin-bottom: 0px;
    }
    .page-header {
        margin-top: 48px;
        margin-bottom: 24px;
        h4 {
            color: #2E3549;
            font-size: 2.375rem;
        }
    }
    th {
        font-size: 0.75rem;
        padding: 12px 0px;
    }
    td {
        padding: 8px 0px;
        &:first-child {
            padding-left: 16px;
            color: #4B5677;
            font-style: normal;
            font-weight: 700;
            font-size: 0.75rem;
        }
    }
}
.game-coins {
    padding: 0px 32px 32px;
    .page-header {
        h4 {
            font-size: 2.375rem;
            margin-top: 48px;
            margin-bottom: 24px;
        }
    }
    .table-wrap {
        padding: 24px;
        table {
            margin-bottom: 0px;
            th {
                padding-top: 0px;
            }
            th, td {
                &:first-child {
                    padding-left: 0px;
                }
                &:last-child {
                    padding-right: 0px;
                }
            }
            tbody {
                tr:last-child {
                    td {
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
    .filter {
        &--form {
            form {
                display: flex;
                & > * {
                    margin-right: 16px;
                }
                [type="number"] {
                    width: 122px;
                }
                [type="select"] {
                    width: 184px;
                }
                [type="date"] {
                    width: 155px;
                }
                [type="submit"], [type="button"] {
                    margin-top: 25px;
                    height: 46px;
                    border-radius: 12px;
                }
            }
        }
    }
    .totals {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #E0EBF6;
        border-bottom: 1px solid #E0EBF6;
        padding: 24px 0px;
        margin-top: 24px;
        margin-bottom: 32px;
    }
    .total {
        &--title {
            color: #505D77;
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 8px;
        }
        &--value {
            color: #2E3549;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
        }
        &--delimiter {
            width: 1px;
            background-color: #E0EBF6;
        }
    }
}
.game-center-staff {
    @extend .game-coins;
    .page-header {
        &.with-icon {
            display: flex;
            margin-top: 48px;
            margin-bottom: 24px;
            align-items: center;
            cursor: pointer;
            h4 {
                margin-top: unset;
                margin-bottom: unset;
                margin-left: 16px;
            }
            .icon {
                &--wrap {
                    width: 56px;
                    height: 56px;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 36px;
                    i {
                        display: block;
                    }
                }
            }
        }
    }
    .table {
        &-wrap {
            min-width: 1168px;
        }
        th, td {
            &:last-child {
                text-align: right;
            }
        }
    }
    .top {
        &--list {
            display: inline-grid;
            gap: 24px;
            grid-template-columns: repeat(3, 33%);
            width:100%;
        }
        &--item {
            cursor: pointer;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
            color: #FFFFFF;
            border-radius: 16px;
            padding: 24px 32px;
            position: relative;
            overflow: hidden;
            &:after {
                display: block;
                @extend [class^="faw-"];
                content: "\e927";
                font-size: 5rem;
                position: absolute;
                top: 0px;
                right: 0px;
            }
            &.blue {
                background: #96C3FD;
                text-shadow: 0px 4px 4px #78B2FC;
                box-shadow: 0px 4px 16px rgba(150, 195, 253, 0.5);
                &:after {
                    color: #82B8FD;
                }
            }
            &.red {
                background: #E6B3DB;
                text-shadow: 0px 4px 4px #DB94CD;
                box-shadow: 0px 4px 16px rgba(229, 178, 219, 0.5);
                &:after {
                    color: #DFA0D2;
                }
            }
            &.purple {
                background: #AAB1EE;
                text-shadow: 0px 4px 4px #919AE9;
                box-shadow: 0px 4px 16px rgba(170, 177, 238, 0.5);
                &:after {
                    color: #99A1EB;
                }
            }
        }
    }
    .total--delimiter {
        margin-right: 24px;
        margin-left: 24px;
    }
    .filter {
        display: flex;
        padding-bottom: 24px;
        margin-bottom: 28px;
        border-bottom: 1px solid #E0EBF6;
        &--tops {
            width: calc(100% - 316px);
            min-width: 800px;
        }
        &--form {
            display: flex;
            form {
                align-items: flex-end;
                [type="submit"] {
                    margin-right: 0px;
                }
            }
        }
    }
    .details {
        width: 926px;
        margin: 49px auto 0 auto;
        .faw-arrow_left {
            font-size: 0.875rem;
        }
        &--content {
            padding: 20px 16px 16px;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(32px);
            border-radius: 24px;
        }
        &--info {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            &-item {
                flex-grow: 1;
                flex-basis: 40%;
                padding: 24px;
                background-color: #ffffff;
                border-radius: 16px;
                & > div:not(:last-child) {
                    margin-bottom: 11px;
                }
                .table {
                    margin-bottom: 0px;
                }
                .form {
                    &-label {
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.75rem;
                        margin-bottom: 2px;
                        color:#4B5677;
                    }
                    &-control-plaintext {
                        font-size: 1rem;
                        padding-top: 0px;
                        padding-bottom: 4px;
                        border-bottom: 1px solid #E0EBF6;
                    }
                }
            }
        }
        .table-wrap {
            min-width: unset !important;
        }
    }
}
.pagination {
    & .form-control {
        width: 85px !important;
        text-align: center;
    }
}
.icon {
    &--currency {
        width: 16px;
        height: 16px;
    }
}
.nodata {
    font-size: 2.5rem !important;
    font-weight: 800 !important;
}

.adnet, .live2earn {
    &-mod {
        &_status-tag {
            box-sizing: border-box;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            padding: 2px 8px;
            padding-top: 3px;
            border-radius: 4px;

            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            text-transform: uppercase;
            line-height: 1;

            color: #FFFFFF;
        }
    }
}
.live2earn {
    &-staff {
        .table-img {
            width: 91px;
            height: 65px;
            border-radius: 8px;
        }
        .content {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
            width: 100%;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 24px;
            .id {
                font-weight: 700;
                font-size: 21px;
                line-height: 120%;
                color: #2E3549;
            }
            .details {
                padding: 24px;
                gap: 24px;
                width: 100%;
                background: #FFFFFF;
                border-radius: 16px;
                display: flex;
                .live2earn-mod_status-tag {
                    width: fit-content;
                    margin-bottom: 24px;
                }
                .img {
                    flex: 1;
                    width: 100%;
                    img {
                        border-radius: 16px;
                        width: 100%;
                        max-height: 100%;
                    }
                }
                .info {
                    width: 319px;
                    display: flex;
                    flex-direction: column;
                    .label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                        color: #4B5677;
                    }
                    .value {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        &.estimate {
                            i {
                                margin-left: auto;
                                &.faw-check {
                                    color: #5BBC43;
                                }
                                &.faw-cross {
                                    color: #F44949;
                                    font-size: 12px;
                                    margin-right: 2px;
                                }
                            }
                        }
                        .true {
                            color: #5BBC43;
                        }
                        .false {
                            color: #BC4343;
                        }
                    }
                }
            }
        }
    }
    &-mod {
        &_status-tag {
            &:not(.outline) {
                &.approved {
                    background: #5BBC43;
                    border: 1px solid #5BBC43;
                }
                &.pending {
                    background: #FE7202;
                    border: 1px solid #FE7202;
                }
                &.declined, &.rejected, &.incomplete {
                    background: #F44949;
                    border: 1px solid #F44949;
                }
            }
            &.outline {
                background-color: transparent;
                &.approved {
                    border: 1px solid #5BBC43;
                    color: #5BBC43;
                }
                &.pending {
                    color: #FE7202;
                    border: 1px solid #FE7202;
                }
                &.declined, &.rejected, &.incomplete {
                    color: #F44949;
                    border: 1px solid #F44949;
                }
            }
        }
    }
}
.adnet {
    &-mod {
        &_status-tag {
            &:not(.outline) {
                &.approved {
                    background: #5BBC43;
                    border: 1px solid #5BBC43;
                }
                &.pending {
                    background: #7A85EB;
                    border: 1px solid #7A85EB;
                }
                &.declined, &.rejected, &.incomplete {
                    background: #F44949;
                    border: 1px solid #F44949;
                }
            }
            &.outline {
                background-color: transparent;
                &.approved {
                    border: 1px solid #5BBC43;
                    color: #5BBC43;
                }
                &.pending {
                    color: #7A85EB;
                    border: 1px solid #7A85EB;
                }
                &.declined, &.rejected, &.incomplete {
                    color: #F44949;
                    border: 1px solid #F44949;
                }
            }
        }
    }
}

.aw-673 {
    h4 {
        font-size: 38px;
        margin-bottom: 24px;
    }
    .table-wrap {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(16px);
        border-radius: 24px;
        padding: 8px;
        .filter {
            padding: 0;
        }
        hr {
            background-color: #E0EBF6;
            margin: 16px 0 24px 0;
        }
        table {
            background: white;
            border-radius: 16px;
            border-collapse: collapse;
            border-style: hidden;
            box-shadow: 0 0 0 1px  #E0EBF6;
            margin: 0;
            width: 100%;
            overflow: hidden;
            thead {
                th {
                    padding: 4px 16px !important;
                    background: #F9FBFD;
                }
            }
            td[rowspan] {
                vertical-align: text-top;
            }
            td {
                white-space: nowrap;
                padding: 8px 16px !important;
            }
            tr:nth-child(even) {
                background-color: #F9FBFD;
            }
        }
         th, td {
            border: 1px solid #E0EBF6;
        }
    }
}
.staff_plot_ticket_status__ {
    &active {
        --colors-ebony-clay: #5BBC43;
        color: var(--colors-ebony-clay)
    }
    &refunded {
        --colors-ebony-clay: #FE7202;
        color: var(--colors-ebony-clay)
    }
    &expired {
        --colors-ebony-clay: #F44949;
        color: var(--colors-ebony-clay)
    }
    &spent {
        --colors-ebony-clay: #7A85EB;
        color: var(--colors-ebony-clay)
    }
}
