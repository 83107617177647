@use "../mixins.module.scss" as *;

.ads_manager {
  &__right_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }
  &__title {
    color: var(--text-basic, #2E3549);

    @include bold__title_1;
    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: flex-start;
      align-self: stretch;
      flex-wrap: wrap;
    }
    &_action {
      display: flex;
      align-items: flex-start;
    }
  }
  &__stats {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--sp-4, 16px);
    align-self: stretch;
    flex-wrap: wrap;
    &_card {
      display: flex;
      min-width: 128px;
      height: 100%;
      padding: var(--sp-4, 16px) var(--sp-6, 24px);
      align-items: flex-start;
      align-items: center;
      gap: 16px;

      border-radius: var(--sp-4, 16px);
      background: var(--colors-white, #FFF);
      &__wrap {
        display: flex;
        align-items: flex-start;
        gap: 24px;
      }
      &__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
      &__title {
        display: flex;
        align-items: center;
        gap: 8px;

        color: var(--text-additional-alt, #6C7284);

        @include bold__caption_1;
      }
      &__subtitle {
        display: flex;
        align-items: center;
        gap: 8px;


      }
      &__credits {
        color: var(--colors-atomic-tangerine, #FE7202);

        @include bold__body_1;
      }
      &__icon {
        i {
          display: block;
          transform: rotate(45deg);
          font-size: 16px;
          color: #B5BDCA;
        }
      }
      &__about {

      }
      &__update {
        color: var(--text-basic, #2E3549);

        @include bold__body_1;
      }
      &__auth {
        @include bold__body_1;
        &.green {
          color: var(--scheme-green, #5BBC43);
        }
        &.red {
          color: var(--scheme-red, #F44949);
        }
        &.yellow {
          color: var(--scheme-yellow, #F0A920);
        }
      }
      &__ext {
        align-self: normal;
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-left: 1px solid var(--strokes-main, #F3F5FB);
        &_warn {
          display: flex;
          width: 100%;
          max-width: 340px;
          padding: var(--sp-2, 8px) var(--sp-3, 12px);
          align-items: flex-start;
          gap: 8px;

          border-radius: var(--r-3, 12px);
          border: 1px solid var(--strokes-main-darken, #E0EBF6);
          background: var(--background-additional, #F3F5FB);
          .text {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;

            color: var(--text-additional, #414B67);
            font-family: Nunito;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }
          .icon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
        }
      }
      &__overview {
        width: 24px;
        height: 24px;
        font-size: 12px;
        border-radius: 50%;
        background: #414B67;
        color: #FFF !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__body {
    display: flex;
    padding-top: var(--sp-4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sp-4, 16px);
    align-self: stretch;
    &__title {
      display: flex;
      align-items: flex-start;
      align-self: stretch;

      color: var(--text-basic, #2E3549);

      @include bold__title_2;
    }
  }
  &__filter {
    display: flex;
    align-items: center;
    gap: var(--sp-2, 8px);
    align-self: stretch;
    &_input {
      min-width: 120px;
      flex: 1 0 0;
    }
    &_clear {
      border: none !important;
      background: transparent !important;
      outline: none !important;
      padding: 0%;
      i {
        font-size: 16px;
        color: #6C7284;
        display: block;
      }
    }
  }
  &__table {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &__cell {
      &_id {
        color: var(--text-basic, #2E3549);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &_v {
        color: var(--text-basic, #2E3549);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &_title {
        color: var(--text-basic, #2E3549);
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &_impressions,
      &_views,
      &_clicks {
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &_impressions {
        color: var(--scheme-green, #5BBC43);
      }
      &_views {
        color: var(--scheme-orange, #FE7202);
      }
      &_clicks {
        color: var(--scheme-soft-blue, #7A85EB);
      }
      &_date {
        white-space: nowrap;
        color: var(--text-basic, #2E3549);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &_status {

      }
      &_action {
        display: flex;
        padding: 0px 4px;
        align-items: center;
        gap: 4px;
      }
    }
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  &__status_tag {
    white-space: nowrap;
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-radius: 4px;

    color: var(--text-white, #FFF);
    font-family: Nunito;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    // line-height: 150%;
    text-transform: uppercase;
    span {
      margin: -3px 0;
    }
    background: #b4b4b4;
    &.approved, &.displaying {
      background: var(--scheme-green, #5BBC43);
    }
    &.pending, &.package {
      background: var(--scheme-soft-blue, #7A85EB);
    }
    &.declined, &.rejected, &.incomplete, &.skipped {
      background: var(--scheme-red, #F44949);
    }
    &.planned, &.ad {
      background: var(--scheme-orange, #FE7202);
    }
    &.deleted {
      background: var(--strokes-main-darken, #E0EBF6);
      color: var(--text-additional, #414B67);
    }
  }
  &__modal_tabs {
    &__button {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      background: transparent;
      border-radius: 8px;

      border: none !important;
      outline: none !important;
      margin: 0px !important;
      i {
        display: block;
        font-size: 16px;
      }
      color: #28262C;
      &:hover, &:focus, &:active {
        color: #4D0CB9;
        background: var(--background-additional, #F3F5FB);
      }
      &:disabled {
        color: #B5BDCA;
        background: transparent !important;
      }
    }
  }
  &__link {
    color: var(--scheme-link, #0BA1E1) !important;
    text-decoration: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.ads_detail {
  width: 100%;
  &__version {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    &__wrap {
      display: flex;
      min-width: 58px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--sp-1, 4px);
    }
    &__title {
      color: var(--text-additional-alt, #6C7284);

      @include bold__caption_1;
    }
    &__body {
      display: flex;
      align-items: center;
      gap: var(--sp-1, 4px);

      color: var(--text-basic, #2E3549);
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      &.link {
        a {
          color: var(--scheme-link, #0BA1E1);
          &:hover, &:focus, &:active {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__divader {
    width: 100%;
    height: 1px;

    background: var(--strokes-main-darken, #E0EBF6);
  }
}

.ads_archive {
  &__card {
    display: flex;
    min-width: 229px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--r-4, 16px);
    background: var(--colors-white, #FFF);
    &_container {
      display: flex;
      align-items: flex-start;
      gap: var(--sp-4, 16px);
      align-self: stretch;
    }
    &__title {
      color: #505D77;

      @include bold__caption_1;
    }
    &__subtitle {
      color: var(--colors-martinique, #3A324E);

      @include bold__body_1;
      &.impressions {
        color: var(--colors-apple, #5BBC43);
      }
      &.viewed {
        color: var(--colors-atomic-tangerine, #FE7202);
      }
      &.clicked {
        color: var(--colors-cornflower-blue, #7A85EB);
      }
    }
  }
}

.ads_create {
  &__modal {
    &__body {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--modals-mod-content-paddings, 24px);
    }
    &__first {
      width: 100%;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: var(--sp-4, 16px) 16px;
      align-self: stretch;
      flex-wrap: wrap;
      &__input {
        flex: 1;
      }
    }
    &__second {
      width: 100%;
      display: flex;
      &__input {
        flex: 1;
      }
      &__textarea {
        position: relative;
        flex: 1;
        min-height: 120px;
        resize: none;
        &__label {
          white-space: nowrap;
          pointer-events: none;
          display: flex;
          padding: 0px 13px;
          align-items: flex-start;
          gap: 8px;

          border-radius: var(--r-4, 16px);
          background: var(--text-additional-alt, #6C7284);
          position: absolute;
          bottom: 6px !important;
          top: unset !important;
          left: 50%;
          color: #FFF;
          font-family: Nunito;
          font-size: 12px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          transform: translateX(-50%) !important;
          opacity: .75;
          &.red {
            background: var(--text-additional-alt, #ff5858);
          }
        }
      }
    }
    &__media {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--sp-2, 8px);
      flex: 1;
      &__container {
        width: 100%;
        display: flex;
        padding: var(--sp-4, 16px);
        align-items: flex-start;
        align-content: flex-start;
        gap: var(--sp-4, 16px) 16px;
        flex-wrap: wrap;
  
        border-radius: var(--r-6, 24px);
        background: var(--background-additional, #F3F5FB);
      }
      &__title {
        display: flex;
        gap: var(--sp-1, 4px);
        align-items: baseline;
        @include bold__body_1;
        .optional {
          color: var(--text-additional-alt, #6C7284);
          @include regular__caption_1;
        }
      }
      &__add {
        cursor: pointer;
        user-select: none;
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 150px;
        padding: 3px;
        justify-content: center;
        align-items: center;
        gap: 65px;

        border-radius: var(--r-2, 8px);
        border: 1px dashed var(--text-additional-alt, #6C7284);
        &__icon {
          font-size: 32px;
          color: #414B67;
        }
      }
      &__cover {
        padding: 8px;
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 150px;
        border-radius: var(--r-2, 8px);
        background: var(--background-main, #FFF);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        background-image: var(--cover, none);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .nodata {
          border-radius: var(--r-2, 8px);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
        }
        &.video {
          --cover: url(../../../assets/video_icon.svg);
          background-repeat: no-repeat;
          background-size: 40% 40%;
          background-position: center;
        }
        &__show_media {
          position: absolute;
          cursor: pointer;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        &__remove, &__edit {
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
          cursor: pointer;
          border: 2px solid #FFF;
          background: #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -4px;
          top: -4.5px;
          border-radius: 50%;
          i {
            pointer-events: none;
            font-size: 24px;
            color: #414B67;
            display: block;
          }
          &:hover {
            i {
              color: #F44949;
            }
          }
        }
        &__edit {
          width: 28px;
          height: 28px;
          right: 28px;
          i {
            font-size: 12px;
            color: #414B67;
          }
          &:hover {
            i {
              color: #fff;
            }
            background-color: #7A85EB;
          }
        }
      }
    }
    &__amazon {
      color: var(--text-basic, #2E3549);
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  &__template {
    --modals-mod-content-paddings: 24px;
    padding: var(--modals-mod-content-paddings);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: var(--modals-mod-content-paddings, 24px);
    &__loader {
      width: 100%;
    }
    &__item {
      width: calc((100% - (var(--modals-mod-content-paddings) * 3)) / 4);
      position: relative;

      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-4, 16px);
      flex: 1 0 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: var(--r-5, 20px);
        background: var(--background-main, #FFF);
      }
      &__image {
        position: relative;
        height: 124px;
        width: 100%;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: var(--r-3, 12px);
          background-position: center;
          background-color: transparent;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: var(--img, none);
        }
        &::before {
          opacity: 0.5;
          background-color: rgb(211 211 211 / 50%);
          filter: blur(16px);
        }
      }
      &__title {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        white-space: nowrap;
      }
      &__button {
        width: 100%;
        position: relative;
        display: flex;
        button {
          width: 100%;
        }
      }
      &__active {
        z-index: 1;
        pointer-events: none;
        position: absolute;
        right: -8px;
        top: -8px;
        display: flex;
        padding: 2px;
        flex-direction: column;
        align-items: center;

        border-radius: var(--r-6, 24px);
        background: var(--background-main, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
        i {
          display: block;
          font-size: 24px;
          color: #5BBC43;
        }
      }
    }
  }
}

.mobile {
  .ads_create {
    &__modal{
      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--modals-mod-content-paddings, 24px);
        flex: 1 0 0;
        align-self: stretch;
      }
  
      &__first {
        flex-direction: column;
        > * {
          width: 100%;
        }
        &__input {
          order: 1;
        }
      }

      &__media {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--sp-2, 8px);
        flex: 1;
        &__container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-content: flex-start;
        }
        &__title {
          @include bold__body_1;
          .optional {
            @include regular__caption_1;
          }
        }
        &__add {
          width: 100%;
          height: 100%;
          min-height: 150px;
        }
        &__cover {
          width: 100%;
          height: 100%;
          min-height: 150px;
        }
      }
    }
  }
}

.mobile {
  .ads_manager {
    &__right_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
    }
    &__title {
      @include bold__title_2;
      &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--sp-4, 16px);
        align-self: stretch;
      }
      &_action {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;
      }
    }
    &__stats {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: var(--sp-4, 16px) 16px;
      align-self: stretch;
      flex-wrap: wrap;
      &_card {
        width: 100%;
        height: auto;
        display: flex;
        padding: var(--sp-4, 16px) var(--sp-6, 24px);
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        &__wrap {
          display: flex;
          align-items: flex-start;
          gap: 24px;
        }
        &__inner {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
        &__ext {
          &.network {
            order: 1;
            width: 100%;
            border-left: none;
            padding-left: 0px;
          }
        }
      }
    }
    &__body {
      &__title {
        @include bold__body_1
      }
    }
    &__filter {
      .overview_table__filter_actions {
        width: auto;
      }
    }
    &__table {
      --pad: 16px;
      overflow-x: auto;
      width: calc(var(--pad) * 2 + 100%);
      margin-left: calc(var(--pad) * -1);
      margin-right: calc(var(--pad) * -1);
      padding-left: var(--pad);
      padding-right: var(--pad);
      padding-bottom: var(--pad);
      .iw_table {
        width: max-content;
      }
    }
    &__pagination {
      justify-content: center;
    }
  }
  .ads_archive {
    &__card {
      width: 100%;
      display: flex;
      min-width: unset;
      padding: 16px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      &_container {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 16px;
        align-self: stretch;
        flex-wrap: wrap;
      }
    }
  }
}

.mobile {
  .ads_create {
    &__modal__cropper__actions {
      flex-direction: column;
      gap: 16px;
      button {
        width: 100%;
      }
    }
    &__template {
      --modals-mod-content-paddings: 16px;
      flex-wrap: nowrap;
      flex-direction: column;
      &__item {
        width: 100%;
      }
    }
  }
  }
