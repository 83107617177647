.loader {
    * {
    }
    .inner__container {
        background-image: unset !important;
        svg {
            width: 90vw !important;
            height: 90vh !important;
            margin: auto !important;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}
