/* nunito-regular - latin_cyrillic */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/nunito-v22-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/nunito-v22-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/nunito-v22-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/nunito-v22-latin_cyrillic-regular.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-600 - latin_cyrillic */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/nunito-v22-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/nunito-v22-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/nunito-v22-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/nunito-v22-latin_cyrillic-600.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-700 - latin_cyrillic */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/nunito-v22-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/nunito-v22-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/nunito-v22-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/nunito-v22-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/nunito-v22-latin_cyrillic-700.svg#Nunito') format('svg'); /* Legacy iOS */
  }

#root .App {
    font-family: 'Nunito', sans-serif;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.card {
    &.card__mapfilter {
        width: 328px;

        .form-switch {
            .form-check-input {
                width: 49px;
            }
            & + .form-switch {
                margin-top: 19px;
            }
        }
    }
}
.map_settings {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: calc(100% - 79px);
    max-height: 100%;
    height: 100%;
    padding: 28px;
    width: fit-content;
    pointer-events: none;
    > * {
        z-index: 3;
        pointer-events: all;
    }
}
.mode {
    &__selector {
        display: flex;
        padding: 16px;
        background-color: rgba(245, 245, 245, 0.5);
        border-radius: 32px;
        width: 128px;
        transition: width;
        transition-duration: 50ms;

        .mode__item.inactive {
            display: none;
        }
        &.expanded {
            width: 352px;
            .mode__item.inactive {
                display: block;
            }
        }
    }
    &__item {
        cursor: pointer;
        font-size: 0.875rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: var(--aw-primary);
        & + .mode__item {
            margin-left: 16px;
        }
        &.inactive {
            color: var(--aw-secondary);
            font-weight: 400;
            .mode__item-img {
                opacity: .5;
            }
        }
        &-img {
            &_wrap {
                background-color: #ffffff;
                padding: 11px;
                border-radius: 16px;
            }
            width: 74px;
            height: 74px;
        }
    }
}



.aw-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    padding: 32px;
    cursor: pointer;
    --color: #fff;
    i {
        display: block;
        transition: color .1s, border-color .1s;
        color: var(--color);
        padding: 12px;
        border: 2px solid var(--color);
        border-radius: 40px;
    }
    &:hover {
        --color: #FE7202;
    }
}

.aw-link {
    cursor: pointer;
    color: #009DC4;
    &:hover {
        color: #327DA0;
      }
    &:active {
        color: #327DA0;
    }
}

.ext_title__container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.mobile .ext_title__container {
    flex-direction: column;
    justify-content: space-between;
}
