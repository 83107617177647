
.redesign {
    &-table {
        border-collapse: collapse;
        width: 100%;
        &-wrapper {
            border-radius: 24px;
            padding: 24px;
            background: $color-white;
            position: relative;
            &.disabled {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.2;
                    background: $color-primary;
                    border-radius: 24px;
                }
            }
        }
        thead {
            th {
                font-size: 12px;
                color: $color-additional-alt;
                font-weight: 700;
                line-height: 150%;
                padding: 8px 16px 8px 16px;
                white-space: nowrap;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                .faw-sort-two {
                    margin-left: 4px;
                    font-size: 14px;
                    &.sort-asc, &.sort-desc {
                        background-clip: text !important;
                        -webkit-background-clip: text !important;
                        -webkit-text-fill-color: transparent;
                    }
                    &.sort-asc {
                        background: linear-gradient(to right, $color-accent 0%, $color-accent 50%, $color-additional-alt 50%, $color-additional-alt 100%);
                    }
                    &.sort-desc {
                        background: linear-gradient(to right, $color-additional-alt 0%, $color-additional-alt 50%, $color-accent 50%, $color-accent 100%);
                    }
                }
            }
        }
        tbody {
            tr {
                border-top: 1px solid $color-main-dark;
                td {
                    border-radius: 0 !important;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 150%;
                    padding: 4px 16px;
                    .img {
                        &-left-in-table {
                            margin-right: 12px;
                            width: 48px;
                            height: 48px;
                            min-width: 48px;
                        }
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
                &.opened-row {
                    border-top: none;
                }
                
            }
        }
    }
}

.mobile {
    .redesign {
        &-table {
            tbody tr  td {
                white-space: nowrap;
            }
            &-wrapper { 
                width: fit-content;
                &-position {
                    margin: 0 -16px;
                    overflow-x: auto;
                    padding: 0 16px;
                }
            }
        }
    }
}

.table {

    thead {
        color: #4B5677;
    }
    tbody {
        color: #2E3549;
        font-weight: 400;
    }
    .goto {
        color: #009DC4;
        border-radius: 16px;
        padding: 0px 16px 16px 16px;
        //width: 100%;
        border:unset;
        display: flex;
        justify-content: flex-end;
    }

    td {
        vertical-align: middle;
        &:first-child {
            border-bottom-left-radius: $table-td-radius;
            border-top-left-radius: $table-td-radius;
        }
        &:last-child {
            border-bottom-right-radius: $table-td-radius;
            border-top-right-radius: $table-td-radius;
        }
    }
    &--thumb {
        &.mobile {
            width: 64px;
            height: 64px;
        }
        width: 48px;
        height: 48px;
        background-color: #F7F7F7;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover, auto;
        background-image: var(--image, none), url(../../assets/image.svg);
        background-image: var(--webp, var(--image, none)), url(../../assets/image.svg);
    }
}

.table, .mobile-table--inner, .mobile-table--list {
    .long-text-elipsis {
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        max-width: 5vw;
    }
}

.mobile-table {
    &--container {
        overflow-y: initial !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }
    &--wrap {
        max-height: 100%;
        flex: 1;
        overflow: hidden;
        .table-wrap {
            overflow: auto;
            flex: 1;
            width: 100%;
        }
    }
    &--inner {
        display: flex;
        flex-direction: column;
        max-height: 100%;

        border-radius: 24px;
        padding: 8px;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5);
        .table-wrap {
            border-radius: 0;
            padding: 0;
            backdrop-filter: unset;
            background-color: transparent;
        }
    }
    &--item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        .cell-5 {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        padding: 8px;
    }
    &--cell {
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        &-label {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #4B5677;
            padding-bottom: 8px;
            // white-space: nowrap;
        }
        .land {
            flex: 1;
        }
        .goto {
            padding: 16px;
            margin-right: -16px;
            border-left: 1px solid #E0EBF6;
            &:disabled {
                opacity: 0.4;
            }
        }
        &-value {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            text-transform: capitalize;
            .amount {
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                line-height: 120%;
            }
            .desc {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }
            .lvl {
                font-weight: 700;
                font-size: 38px;
                line-height: 120%;
            }
            .link {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                a {
                    color: #009DC4 !important;
                }
            }
        }
    }
    &--header {
        display: flex;
        h4 {
            margin-top: 35px;
            margin-bottom: 11px;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
            flex: 1;
        }
        &-switch {
            margin-top: 35px;
            display: flex;
            &--button {
                width: 40px;
                height: 40px;
                background: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:not(.active) {
                    background: transparent;
                    box-shadow: none;
                    filter: grayscale(.9);
                }
            }
        }
    }
}

.mobile{

    .table-no-data {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5) !important;
        font-size: 2.5rem !important;
        font-weight: 800;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF !important;
    }
}

table tr.hovered > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}
table tr.hovered {
    td {
        border-radius: 0 !important;
        .brd {
            border-bottom: 1px solid #E0EBF6;
        }
    }

}
table tr.hovered.first td:first-child {
    border-top-left-radius: 16px !important;
}

table tr.hovered.first td:last-child {
    border-top-right-radius: 16px !important;
}

table tr.hovered.last td:first-child {
    border-bottom-left-radius: 16px !important;
}

table tr.hovered.last td:last-child {
    border-bottom-right-radius: 16px !important;
}
