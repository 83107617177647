.registration {
    @mixin registration-small {
        @media (max-height: 744px) { @content; }
    }
    &.finish {
        img.check {
            width: 48px;
            height: 48px;
        }
    }
    &.createForm {
        #main  {
            @include registration-small {
                height: auto !important;
            }
        }
        .main__content {
            @include registration-small {
                margin-bottom: 32px;
            }
        }
    }
    .confirm {
        .form-control-error {
            font-size: 1rem;
        }
        a {
            color: #009DC4;
            font-weight: 400;
        }
    }
    &.finish {
        h5 {
            color: #2C3653;
        }
    }
    &.start {
        .divider {
            border-top: 1px solid #E0EBF6;
        }
        .or-divider {
            margin: 1.5rem 0 0.5rem;
            .or {
                color: #505D77;
            }
        }
        h5 {
            margin-bottom: 2rem !important;
        }
        .btn-send {
            margin-top: 2rem;
        }
    }
}
