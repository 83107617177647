.form {
    &-control {
        &.is-invalid {
            background-image:unset !important;
        }
    }
    &-control-error {
        color: #F44949;
        font-weight: 400;
        font-size: .75rem;
    }
    &-switch {
        .form-check-input {
            width: 49px;
            height: 28px;
            &:checked {
                background-color: white;
                border-color: #CDD5E4;
            }
        }
    }
}
.bg-modal-input {
    input, select {
        background-color: #F7F7F7 !important;
        border: 1px solid #B5BDCA !important;
    }
}
