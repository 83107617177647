@use "./mixins.module.scss" as *;

.modal_success {
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: var(--r-6, 24px);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.17);
  &__wrapper {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  }
  &__content {
    position: relative;
    display: flex;
    padding: 0px var(--modals-mod-content-paddings, 24px) var(--modals-mod-content-paddings, 24px) var(--modals-mod-content-paddings, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--modals-mod-content-paddings, 24px);
    flex: 1 0 0;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 32px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 24px 24px 0px 0px;
      background: var(--background-main, #FFF);
    }
  }
  &__icon {
    z-index: 2;
    display: flex;
    padding: var(--sp-1, 4px);
    align-items: center;
    justify-content: center;
    gap: 8px;

    border-radius: 100px;
    background: var(--background-main, #FFF);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);
    i {
      font-size: 80px;
      display: block;
    }
  }
  &__body {
    z-index: 2;
    display: flex;
    padding: 0px var(--sp-2, 8px) var(--sp-2, 8px) var(--sp-2, 8px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--sp-2, 8px);
  }
  &__title {
    color: var(--text-primary, #2C3653);

    @include bold__title_1;
  }
  &__text {
    color: #2E3549;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    b {
      color: #2E3549;
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    };
  }
  &__action {
    display: flex;
    padding: var(--sp-4, 16px);
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-top: 1px solid var(--strokes-main-darken, #E0EBF6);
    background: var(--background-main, #FFF);
    border-radius: 0px 0px 24px 24px;
    button {
      min-width: 76px;
    }
  }
}
