/* comfortaa-regular - latin_cyrillic */
@font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/comfortaa-v37-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/comfortaa-v37-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/comfortaa-v37-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/comfortaa-v37-latin_cyrillic-regular.svg#Comfortaa') format('svg'); /* Legacy iOS */
  }
  /* comfortaa-600 - latin_cyrillic */
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/comfortaa-v37-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/comfortaa-v37-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/comfortaa-v37-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/comfortaa-v37-latin_cyrillic-600.svg#Comfortaa') format('svg'); /* Legacy iOS */
  }
  /* comfortaa-700 - latin_cyrillic */
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/comfortaa-v37-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/comfortaa-v37-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/comfortaa-v37-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/comfortaa-v37-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/comfortaa-v37-latin_cyrillic-700.svg#Comfortaa') format('svg'); /* Legacy iOS */
  }

.App {
    font-family: Comfortaa, 'sans-serif';
}
.landing {
    background-color: #FFFFFF;
    &-description {
        .landing__footer {
            background-color: #FFFFFF !important;
            border-top: none !important;
            padding-top: 0 !important;
        }
        
    }
    font-family: Rubik, 'sans-serif';
    h3 {
        font-size: 3rem;
        font-weight: bold;
        color: #2C3653;
    }
    &-ad {
        .header__right {
            .btn-outline-light {
                color: #4B5677 !important;
                border-color: #4B5677 !important;
            }
            a:not(.dropdown-item).is_active {
                color: #4B5677 !important;
                border-color: #4B5677 !important;
            }
        }
        #main {
            height: calc(100vh + 48px) !important
        }
        .next-container {
            position: absolute;
            bottom: 72px;
            display: flex;
            .next__button {
                margin: auto;
                width: 70px;
                height: 70px;
                
                display: flex;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 60px;
                i {
                    color: #009DC4;
                    font-size: 16px;
                    background:  #FFFFFF;
                    padding: 16px;
                    margin: auto;
                    border-radius: 24px;
                }
            }
        }
    }
    #main {
        height: 100vh;
        background-position-y: center;
        background-color: #2498f6;
        background-image: url('../../../public/landing-main-v2.png');
        background-image: url('../../../public/landing-main-v2.webp');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        &.dark:before {
            content: '';
            background-color: rgba(0,0,0,.6);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
        }
    }

    header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        z-index: 4;
        position: relative;
        .faw-logo {
            margin-top: 24px;
            margin-left: 32px;
            display: block;
            font-size:5.4425rem;
            color: white;
        }
    }
    header .dropdown {
        margin-right: 27px;
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 8px;
    }
    .header {
        &__left {
            width: 10%;
        }
        &__right {
            text-align: right;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            align-items: center;
            @media (max-width: 581px) {
                align-items: flex-end;
                justify-content: center;
            }

            a:not(.dropdown-item) {
                border-bottom: 2px solid transparent;
                font-weight: 400;
                padding-bottom:4px;
                color: white;
                font-size: 1rem;
                text-decoration: none;
                & + a, & + .header__a-wrap {
                    //margin-left: 16px;
                }
                &.is_active {
                    //color: #FE7202;
                    border-color: white;
                }
            }
            hr {
                height: 24px;
                width: 1px;
                opacity: 0.24;
                background-color: white;
            }
            .lang-dropdown {
                margin-left: -16px;
                a {
                    &.dropdown-toggle {
                        padding: 8px 12px !important;
                        &:hover, &:active, &:focus, &.show {
                            background: transparent !important;
                            box-shadow: none !important;
                            border-color: transparent !important;
                        }
                    }
                }
                .dropdown-menu.show {
                    >div {
                        white-space: nowrap;
                        
                        padding: 8px 16px !important;
                        font-size: 14px;
                        color: #333!important;
                        cursor: pointer;
                        &.active, &:hover {
                            background: #EEE !important;
                        }
                        .dropdown-item {
                            &.active {
                                background: transparent;
                                color: #333!important;
                            }
                        }
                    }
                }
            }
        }
    }

    .ad {
        &-cards-box {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            padding: 8px;
            gap: 8px;
            background: linear-gradient(90deg, #8D378E 0%, #4286F4 100%);
            border-radius: 24px;
            margin: 0 auto;
            width: fit-content;
            .card-item {
                display: flex;
                width: 198px;
                flex-direction: column;
                align-items: center;
                padding: 16px;
                background: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 16px;
                color: #353451;

                >div {
                    padding: 8px;
                    border-bottom: 1px solid #E0EBF6;
                    align-items: center;
                    gap: 8px;
                    width: 100%;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150%;
                }
                >div:last-child {
                    border-bottom: none;
                }
                .price {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 150%;   
                }
                .desc {
                    height: 100%;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;  
                }
            }
        }
        &-titles {
            color: #2E3549;
            font-family: 'Jost';
            font-style: normal;
            letter-spacing: 1px;
            text-align: center;
            position: relative;
            margin: auto;
            top: 0;
            right: 0;
            margin-top: calc(50vh - 227px);
            padding: 48px 64px;
            gap: 32px;
            
            background: linear-gradient(109.81deg, rgba(255, 255, 255, 0.3) 0.05%, rgba(255, 255, 255, 0) 101.49%), rgba(255, 255, 255, 0.3);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 24px;

            h1 {
                font-weight: 700;
                font-size: 96px;
                line-height: 120%;
            }
            h2 {
                
                font-weight: 600;
                font-size: 32px;
                line-height: 150%;
            }
            .catalog-btn {
                font-family: Rubik, 'sans-serif';
                font-style: normal;
                display: flex;
                padding: 12px 24px;
                
                border-radius: 12px;
                gap: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                align-items: center;
                text-align: center;
                width: min-content;
                margin: 0 auto;
                i {
                    rotate: 180deg;
                }
            }
            
        }
        &-content {
            font-style: normal;
            border-radius:  48px 48px 0 0;
            // background: white;
            padding-top: 72px;
            margin-top: -96px;
            .landing {
                &__commission_plan {
                    white-space: break-spaces;
                    text-wrap: balance;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    &__subtitle {
                        text-align: start;
                        color: #3F3F4B !important;
                        font-family: Rubik !important;
                        font-size: 18px !important;
                        font-style: normal !important;
                        font-weight: 400 !important;
                        line-height: 200% !important;
                        margin-bottom: 24px !important;
                    }
                    &__title {
                        // max-width: 860px;
                        margin: 0;
                        text-align: start;
                        margin: 0;
                        color: #353451;
                        font-family: Rubik;
                        font-size: 80px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 100%;
                    }
                    &__img {
                        width: 137px;
                        height: 137px;
                    }
                }
                &__title {
                    color: #353451;
                    text-align: center;
                    font-family: Rubik;
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                }
                &__part {
                    .small-text {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #4B5677;
                        margin-top: 24px;
                        
                    }
                    h4 {
                        color: #353451;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 150%;
                        margin-top: 48px;
                        margin-bottom: 32px;
                    }
                    h5 {
                        color: #353451;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 150%;
                    }
                    p {
                        font-size: 24px;
                        line-height: 150%;
                        color: #3F3F4B;
                        max-width: 888px;
                        width: initial;
                        .fw-bolder {
                            color: #3F3F4B;
                            font-weight: 700;
                        }
                        .no-wrap {
                            white-space: nowrap;
                        }
                    }
                    .image-box {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 8px;
                        gap: 8px;
                        width: 664px;
     
                        background: #FFFFFF;
                        margin: 32px auto;
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                        border-radius: 24px;
                        img {
                            border-radius: 16px;
                            width: 320px;
                        }
                        img:first-child {
                            margin-right: 4px;
                        }
                        img:last-child {
                            margin-left: 4px;
                        }
                        &-text {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 150%;
                            text-align: center;
                            letter-spacing: 1px;
                            color: #2E3549;
                        }

                    }
                    .bonus-box {
                        margin: 48px auto;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 0px;
                        gap: 24px;
                        width: 888px;
                        align-items: stretch;
                        >div {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    .bonus-card {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 49px 16px 16px;
                        gap: 16px;
                        background: #FFFFFF;
                        border: 2px solid #FE7202;
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                        border-radius: 24px;
                        flex: 1;
                        &-per {
                            padding: 16px;
                            background: #3F50EC;
                            border: 2px solid #FFFFFF;
                            border-radius: 16px;
                            color: #FFFFFF;
                            font-weight: 700;
                            font-size: 32px;
                            line-height: 150%;
                            width: 106px;
                            margin: 0 auto -44px auto;
                            position: relative;
                        }
                        &-title {
                            font-weight: 500;
                            color: #353451;
                            font-size: 24px;
                        }
                        &-desc {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 150%;
                            color: #353451;
                        }
                    }
                }
                &__page {
                    background: none;
                    padding-top: 72px;
                    padding-bottom: 96px;
                }
                &__page:first-child {
                    padding-top: 0;
                }
                &__companies {
                    margin-top: 80px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 48px;
                    color: #4B5677;
                    .company {
                        display: flex;
                        flex-direction: column;
                        img {
                            height: 65px;
                        }
                        &-name {
                            margin-top: 24px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 150%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .main {
        &__content {
            height: calc(100% - 96px);
            display: flex;
            justify-content: center;
        }
        &__titles {
            color: #f0a920;
            text-align: center;
            position: relative;
            margin: auto;
            top: 0;
            right: 0;
            margin-top: 320px;
            h2 {
                font-size: 3rem;
                text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
                font-weight: 700;
            }
            h1 {
                text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 120%;
                /* or 58px */

                text-align: center;
                letter-spacing: 0.24em;
                &:nth-child(1) {
                    text-transform: uppercase;
                }
                &:nth-child(2) {
                    font-size: 38.4px;
                }

            }
            p {
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        #main {
            height: 100vh;
            background-image: url('../../../public/landing-main-v2.jpg');
            background-size: cover;
            background-repeat: no-repeat;
        }

        header {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        header .dropdown {
            margin-top: 27px;
            margin-right: 27px;
        }
    }
    .landing {
        &__content {
            position: relative;
            z-index: 3;
            width: 63%;
            margin-left: auto;
            margin-right: auto;
            min-width: 1218px;
        }
        &__page {
            &.gray {
                background-color: #F4F6FB;
            }
        }
        h3 {
            margin-bottom: 0px;
        }
        &__undertitle {
            margin: auto;
            height: 4px;
            width: 64px;
            margin-top: 8px;
            background-color: #FE7202;
            opacity: 1;
        }
        &__separator {
            margin: auto;
            opacity: 1;
            background-color: #e0e5f3;
            margin-top: 90px;
        }
        p {
            color: #505D77;
            font-size: 1.25rem;
        }
        &__footer {
            padding: 24px;
            text-align: center;
            color: #4B5677;
            font-weight: 400;
            font-size: 1.25rem;
            color: #353451;
            font-size: 20px;
            // background: #F7F7F7;
            // border-top: 1px solid #E0EBF6;
        }
        &__part {
            text-align: center;
            h3 {
                margin-top: 120px;
            }
            p {
                margin-top: 24px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                font-weight:400;
                width: 594px;
                &.adnet-description {
                    max-width: 964px;
                    width: auto;
                }
            }
            .categories-box {
                background: #F9FBFD;
                border: 1px solid #E0EBF6;
                margin: 40px auto 94px auto;
                border-radius: 32px;
                padding: 49px 48px;
                grid-template-columns: 1fr 1fr 1fr;
                display: grid;
                grid-gap: 17px 40px;
                width: 1316px;
                >a{
                    border-bottom: 1px solid #E0EBF6;
                }
                .category-item {
                    margin-bottom: 0;
                    padding-bottom: 17px;
                    align-items: baseline;
                    &-title {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 120%;
                        color: #2E3549;
                    }
                }
                .faw-vector_right {
                    color: #009DC4;
                    font-size: 14px;
                    margin-left: auto;
                }
            }
        }
    }
    .whatisit {
        &__map {
            width: 597px;
            height: 474px;
            margin-top: 48px;
            margin-bottom: 30px;
        }
    }
    .nft_based {
        margin-top: 64px;
        display: flex;
        padding: 0 71px;
        padding-bottom: 149px;
        h3 {
            margin-top: 68px;
        }
        img {
            width: 593px;
            height: 480px;
            padding-left: 48px;
        }
        p {
            font-size: 1.25rem;
            margin-top: 24px;
            margin-bottom: 0px;
            min-width: 465px;
            font-weight:400;
        }
        &__content {
            padding-right: 48px;
        }
    }
    .terra {
        padding-top: 90px;
        padding-bottom: 64px;
        p {
            font-size: 1.25rem;
            padding: 64px 0px;
            font-weight:400;
            width: 465px;
            margin-bottom: 0;
        }
    }
    .goldrush {
        display: flex;
        margin-top: 64px;
        align-items: center;
        justify-content: space-between;
        &__content {
            padding-left: 135px;
        }
        p {
            width: 465px;
            margin-top: 24px;
            font-size: 1.25rem;
            font-weight:400;
        }
    }
    .img {
        &__city {
            margin-top: 64px;
        }
        &__jackpot {
            margin-top: 64px;
            margin-bottom: 105px;
            width: 435px;
            height: 435px;
        }
    }
    .tile {
        background-color: white;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        padding: 32px;
        max-width: 744px;
        margin-top: 54px;
        position: relative;

        &__wrap {
            min-height: calc(100vh - 111px);
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        h1 {
            color: #353451;
            font-family: Rubik;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;

            margin-bottom: 24px;
        }
        p {
            margin-bottom: 0;

            color: #3F3F4B;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 200%;
        }
        .back__button {
            width: 56px;
            height: 56px;
            border-radius: 32px;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            border: none;
            position:absolute;
            top: 0px;
            left: -72px;
            display: flex;
            justify-content: center;
            align-items: center;
            .faw-arrow_left {
                font-size: 21px;
                width: fit-content;
                height: fit-content;
                margin: auto;
                color: #121C42;
            }
        }
    }
    .link__black {
        .header {
            &__right {
                hr {
                    background-color: #2E3549;
                    opacity: .24;
                }
                a:not(.dropdown-item) {
                    color: #4B5677;
                    border-bottom: 2px solid transparent;
                    font-weight: 400;
                    padding-bottom:4px;
                    &.is_active {
                        color: #FE7202;
                        border-color: #FE7202;
                    }
                }
                .dropdown {
                    background-color: transparent;
                }
                .btn-outline-light {
                    color: #FE7202;
                    border-color: #FE7202;
                }
            }
        }
    }
    &.light {
        #main {
            height: unset;
            min-height: 100vh;
            width: auto;
            background-image: url('../../../public/bg_abstract.jpg');
        }
    }
    .terra_tile_ {
        &1 {
            width: 346px;
            height: 346px;
        }
        &2 {
            width: 346px;
            height: 346px;
        }
        &3 {
            width: 346px;
            height: 346px;
        }
    }
    .lang-dropdown {
        margin-bottom: 5px;
    }
}
.modal__map {
    .modal-xl {
        max-width: 1000px  !important;
    }
    .modal-body {
        position: static !important;
        flex: unset !important;
        padding: unset !important;
    }
    .modal-content {
        background-color: transparent;
        border-color: transparent;
    }
    .btn-close {
        opacity: 1;
        position: absolute;
        right: -40px;
        top: -5px;
        background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
    }
    .img__map {
        width: 1000px !important;
        border-radius: 24px;
    }
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .landing .tile {
        padding: 0% !important;
        background: transparent !important;
        box-shadow: none !important;
        .back__button {
            display: none;
            -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
}
.landing.registration {
    .btn-send {
        background-color: #7000BF;
        &:hover {
          color: #ffffff;
          background-color: #9B1EF2;
        }
        &[disabled] {
            background-color:#52008d;
            color: #ffffff;
        }
        &:active, &:focus {
            color: #ffffff;
            border-color: #9B1EF2;
            background-color: #9B1EF2;
        }
      }
}
.login {
    &__wrap {
        font-family: Rubik, 'sans-serif';
        
        position: absolute;
        z-index: 3;
        top: 192px;
        margin-left: 800px;

        .btn-warning {
            background-color: #7000BF;
            &:hover {
              color: #ffffff;
              background-color: #9B1EF2;
            }
            &[disabled] {
                background-color:#52008d;
                color: #ffffff;
            }
            &:active, &:focus {
                color: #ffffff;
                border-color: #9B1EF2;
                background-color: #9B1EF2;
            }
          }
        @media (max-width: 1220px) {
            // flex-direction: column;
            .login__titles {
                margin-right: unset !important;
                text-align: center;
                margin-bottom: 32px;
            }
        }
        h1 {
            font-family: Jost, 'sans-serif';
            color: #f0a920;
            font-size: 4.75rem;
            text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
            letter-spacing: 0.24em;
            font-weight: 700;
        }
        h4 {
            font-family: Jost, 'sans-serif';
            color: #f0a920;
            font-size: 1.75rem;
            text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
            font-weight: 700;
        }
        h5 {
            color: #353451;
            font-family: Rubik;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-align: center;
        }
        form {
            border-radius: 24px;
            min-width: 450px;
            padding: 32px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        }
        .form {
            &-label {
                font-size: .75rem;
                margin-bottom: 2px;
                color: #505D77;
            }
            &-control, &-select {
                border-radius: 12px;
                height: 48px;
                padding: 0.375rem 15px;
                &::placeholder {
                    color: #B5BDCA;
                    font-size: 1rem;
                }
            }
            &-note {
                font-size:1.125rem;
                color: #505D77;
                font-weight:600;
            }
            &-text {
                font-size: 1rem;
                color: #4B5677;
                font-weight: 400;
                margin-top:24px;
                text-align: center;
                a {
                    color:#009DC4;
                    font-weight:400;

                    &:hover {
                        color: #327DA0;
                    }
                    &:active {
                        color: #327DA0;
                    }
                    &:focus {
                        color: #327DA0;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }
        .btn-send {
            height: 48px;
            border-radius: 12px;
            background-color:#FE7202;
            color: #ffffff;
            font-size: 1rem;
            &:hover {
                color: #ffffff;
                background-color: #994401;
            }
            &:active {
                color: #ffffff;
                background-color: #7F3901;
            }
        }
        .btn-outline{
            color: #3A324E;
            border-radius: 12px;
            border: 2px solid #3A324E;
        }
        .back__button {
            width: 56px;
            height: 56px;
            border-radius: 32px;
            background: rgba(255, 255, 255, 0.9);
            border: none;
            position:absolute;
            top: 0px;
            left: -72px;
            display: flex;
            justify-content: center;
            align-items: center;
            .faw-arrow_left {
                font-size: 21px;
                width: fit-content;
                height: fit-content;
                margin: auto;
                color: #121C42;
            }
        }
        .btn-info {
            background-color:#51466D;
            color:#ffffff;
            border-color: transparent;
            &:focus {
                box-shadow:unset;
            }
            &:hover {
                color: #ffffff;
                background-color: #3A324E;
            }
            &:active {
                color: #ffffff;
                background-color: #231E2F;
            }
        }
        .congratulation {
            &__note {
                border-radius:16px;
                background-color: #FFFFFF;
                .text-muted {
                    color: #4B5677 !important;
                    font-size:0.75rem;
                    font-weight:400;
                }
                .form-note {
                    font-size: 1rem;
                    color: #2E3549;
                    font-weight: 700;
                }
                .text-darker {
                    color: #2e3549;
                }
            }
        }
    }
    &__titles {
        color: #f0a920;
    }
}

.mobile {
    div.landing {
        height: 100vh;
        overflow-y: auto;
        // padding-top: 66px;
        &-ad {
            overflow-x: hidden;
        }
    }
    .login {
        &__wrap {
            position: relative;
            z-index: 3;
            margin-left: inherit;
            top: 24px;
        }
    }
    .landing .ad-content .landing {
        &__title {
            text-align: start;
            color: #353451;
            font-family: Rubik;
            font-size: 40px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: -0.12px;
        }
        &__part p {
            text-align: start;
        }
        &__commission_plan {
            display: flex;
            padding: 0px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            &__title {
                margin: 0;
                color: #353451;
                font-family: Rubik;
                font-size: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
            }
            &__img {
                order: -1;
                width: 90px;
                height: 90px;
            }
        }
    }
    .landing {
        .ad-cards-box {
            width: auto;
        }
        #main {
            min-height: 100%;
            height: 100%;
        }
        .main__content {
            flex-direction: column;
            height: calc(100vh - 66px);
        }
        .next-container {
            position: initial;
        }
        &__part {
            .adnet-description{
                &.mt-0 {
                    margin-top: 0 !important;
                }
                &.mb-0 {
                    margin-bottom: 0 !important;
                }
            }
            .categories-box {
                display: flex;
                flex-direction: column;
                padding: 17px 16px;
                margin: 40px auto 16px auto;
                width: auto;
                > div:last-child {
                    border-bottom: none;
                }
            } 
        }
        &-ad {
            #main {
                height: calc(100vh - 24px) !important;
            }
            .landing__companies{
                flex-direction: column;
            }
            .image-box {
                width: initial !important;
                display: block !important;
                >.d-flex {
                    flex-direction: column;
                    gap: 8px;
                }
                img {
                    margin: 0 0 0 0 !important;
                    width: 100% !important;
                }
                .image-box-text {
                    margin-top: 8px;
                }
            }
            .ad-cards-box {
                overflow-y: auto;
                .card-item {
                    width: 286px;
                    >div {
                        min-width: 254px;
                    }
                }
            }
            .bonus-box {
                flex-direction: column !important;
                width: initial !important;
            }
            .ad-titles {
                margin-left: 16px;
                margin-right: 16px;
                
                h1 {
                    font-size: 48px;
                }
                h2 {
                    font-weight: 600 ;
                    font-size: 16px ;
                }
            }
            p {
                font-size: 16px !important;
            }
            .landing__companies {
                .company {
                    img {
                        height: 48px;
                    }
                    .company-name {
                        font-size: 14px ;
                    }
                }
            }
            .bonus-card-title {
                font-size: 18px !important;
            }
            .bonus-card-desc {
                font-size: 16px !important;
            }
            h4 {
                font-size: 20px !important;
                margin-top: 32px !important;
                margin-bottom: 16px !important;
            }
            h5 {
                font-size: 18px !important;

            }
            .landing__page {
                padding-bottom: 64px;
            }
        }
    }
}
