.mobile {
    .logo {
        width: 72px;
        height: 40px;
    }
    .burger {
        &-wrapper {
            position: relative;
            width: 19px;
            height: 15px;

            &.default {
                .burger-bars {
                    background-color: #fff;
                }
            }
            &.other {
                .burger-bars {
                    background-color: #51466D;
                }
                &.is-open {
                    .burger-bars {
                        background-color:#FE7202;
                    }
                }
            }
        }
        &-button {
            z-index: 1000;

        }
        &-menu {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
            border: none;
            font-size: 0px;
            background: #e9000000;
            cursor: pointer;
        }
        &-bars {
            position: absolute;
            height: 16%;
            left: 0px;
            right: 0px;
            opacity: 1;
            border-radius:16px;
            &:nth-child(1) {
                top: 0%;
            }
            &:nth-child(2) {
                top: 40%;
            }
            &:nth-child(3) {
                top: 80%;
            }
        }
    }
    .l-menu {
        position:fixed;
        width: calc(100% - 32px);
        height: calc(100vh - 88px);
        overflow: auto;
        top: 72px;
        bottom: 16px;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        background-color: #fff;
        z-index:4000;
        padding: 24px;

        .menu {
            &-title {
                color: #4B5677;
                font-size: 1.75rem;
            }
            &-list {
                display: flex;
                flex-direction: column;
                padding-top: 24px;
                &-item {
                    font-weight:400;
                    font-size: 1.3125rem;
                    color: #009DC4;
                    &:last-child {
                        margin-bottom: 24px;
                    }
                    & + .menu-list-item {
                        padding-top: 24px;
                    }
                    &.with-submenu {
                        display: flex;
                        justify-content:space-between;
                        align-items: center;
                        img {
                            width: 8px;
                            height: 14px;
                        }
                    }
                }
                & + .menu-list {
                    border-top: 1px solid #E0EBF6;
                }
            }
        }
    }
    #menu {
        padding: 16px 16px 0px;
    }
    #main {
        background-position: center;
    }
    .landing {
        h3 {
            font-size: 1.5rem;
        }
        .main {
            &__titles {
                h1 {
                    &:nth-child(1) {
                        font-size: 1rem;
                        margin-bottom: 0px;
                        line-height:1.5;
                        letter-spacing:6px;
                    }
                    &:nth-child(2) {
                        font-size: 1.5rem;
                        margin-bottom: 0px;
                        line-height:1;
                        letter-spacing:6px;
                    }
                    &:nth-child(3) {
                        font-size: 1rem;
                        margin-bottom: 0px;
                        line-height:1.5;
                        letter-spacing:4px;
                    }
                }
                p {
                    font-size: 1rem;
                    letter-spacing:2px;
                    margin-top:4px !important;
                }
            }
        }
        .landing {
            &__part {
                h3 {
                    margin-top: 32px;
                }
                p {
                    font-size: 1rem;
                    margin-bottom: 0px;
                }
                & + .landing__separator {
                    margin: 32px 16px;
                }
            }
            &__undertitle {
                margin-top: 8px;
                border-radius: 16px;
            }
            &__separator {
                margin: 32px 0px !important;
            }
            &__footer {
                padding: 16px 0px;
                font-size: 1rem;
                font-weight:400;
                color: #4B5677;
                z-index: 1;
            }
        }
        .nft {
            &_based {
                margin-top: 0px;
                text-align: center;
                &__content {
                    h3 {
                        margin-top: 0px;
                    }
                }
                h3 {
                    text-align: center;
                }
                p {
                    font-size: 1rem;
                    padding: 0px 16px;
                    margin-top:16px;
                    text-align: center;
                }
                img {
                    height: auto;
                    width: 66vw;
                    margin: 24px auto;
                }
            }
        }
        .whatisit__map {
            width: 65.4vw;
            height: auto;
            margin-bottom: 0px;
        }
        &__part {
            padding: 0px 16px;
            p {
                margin-top: 16px !important;
                width: unset !important;
            }
        }
        &__page {
            & * {
                max-width: 100vw !important;
            }
        }
        &__content {
            min-width: unset !important;
            width: auto !important;
        }
        .nft_based {
            flex-direction: column !important;
            padding: 0;
            &__content {
                padding-right: 0px;
            }
            p {
                min-width: unset;
            }
            img {
                padding-left: 0px;
            }
        }
        .img {
            &__city {
                width: 240px;
                height: auto;
            }
            &__jackpot {
                width: 240px;
                height: 240px;
                margin-top: 32px;
                margin-bottom: 0px;
            }
        }
        .terra {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
            .d-flex {
                flex-direction: column !important;
                &:first-child {
                    flex-direction: column-reverse !important;
                }
                &:last-child {
                    flex-direction: column-reverse !important;
                    p {
                        padding-bottom: 0px;
                    }
                }
            }
            &_tile_1, &_tile_2, &_tile_3 {
                width: 240px;
                height: 240px;
                margin: auto;
            }
            p {
                width: auto !important;
                padding-top: 32px;
                text-align: center;
                padding-bottom: 32px;
            }
        }
        .goldrush {
            margin-top: 0px;
            flex-direction: column-reverse;
            &__content {
                padding-left: unset;
                h3 {
                    text-align: center;
                }
            }
            p {
                font-size: 1rem;
                padding: 0 16px;
                text-align: center;
                margin-top: 16px;
                margin-bottom: 38px;
            }
        }
        .tile {
            margin: 16px;
            padding: 24px;
            &__wrap {
                min-height: calc(100vh - 57px);
                .m-auto {
                    margin-top: 64px !important;
                }
            }
            .back__button {
                top: -48px;
                left: 0px;
                width: 32px;
                height: 32px;
                img {
                    width: 15px;
                    margin-top: 6px;
                    margin-left: 9px;
                }
            }
            h1 {
                font-size: 1.5rem;
                margin-bottom: 16px;
            }
            p {
                font-size: 1rem;
            }
        }
    }
    .login {
        &__wrap {
            // flex-direction: column;
            // margin-top: 40px !important;
            h1 {
                font-size:2.375rem;
                margin-bottom:0px;
            }
            h4 {
                font-size: 1.125rem;
                margin-bottom: 24px;
            }
            h5 {
                font-size: 1.75rem;
                margin-bottom:32px;
            }
            form {
                padding: 24px;
                min-width: auto;
                width: calc(100vw - 32px);
            }
            .back__button {
                width: 40px;
                height: 40px;
                left: 0px;
                top: -56px;
                img {
                    margin-top: 15px;
                    width: 14px;
                    height: auto;
                }
            }
        }
        &__titles {
            margin-right: 0px !important;
            text-align: center;
        }
    }
    .login {
        .logo {
            width: 90px;
            height: auto;
        }
    }
    .registration {
        .logo {
            width: 90px;
            height: auto;
        }
        .login__wrap {
            margin-top: 87px !important;
            h5 {
                margin-bottom: 16px;
            }
        }
        &.confirmNumber .btn {
            margin-top: 16px !important;
        }
        &.createForm .btn {
            margin-top: 32px !important;
        }
    }
    &.registration__page {
        .intl-tel-input.iti-container {
            top: 297px !important;
            left: 40px !important;
            width: 80vw;
        }
    }
    .modal__map {
        .modal-content {
            width: fit-content !important;
            margin: auto;
        }
        .btn-close {
            display: none !important;
        }
        .img {
            &__map {
                width: 96vw !important;
            }
        }
    }
}
