.redesign {
    .bg_injection {
        background: var(--bg, none);
        background-size: cover;
    }
    .content {
        &-wrapper {
            overflow-y: overlay;
        }
        &-body {
            max-width: $base-desctop-width;
        }
        &-left {
            width: 200px;
            position: sticky;
            margin-top: -24px;
            padding-top: 24px;
            margin-bottom: -24px;
            min-width: 200px;
            top: 0;
            margin-right: 24px;
            height: fit-content;
            max-height: calc(100vh - 61px);
            overflow-y: overlay;
            >div:not(:first-child) {
                margin-top: 16px;
            }
            .resource-list {
                padding: auto;
                &-item {
                    padding: 8px 12px;
                    font-size: 16px;
                    font-weight: 400;
                }
                &-icon {
                    width: 16px;
                    height: 16px;
                }
            }
            .add-nav {
                &-title,  &-item {
                    padding: 8px 12px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 150%;
                }
                &-title {
                    text-transform: uppercase;
                }
                &-item:hover {
                    color: $color-accent;
                }
                &-item {
                    border-radius: $br-3;
                    display: flex;
                    justify-content: flex-start;
                    .unread--marker {
                        width: 6px;
                        height: 6px;
                    }
                    &.active {
                        color: $color-accent-dark;
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}

.iw_logo {
    --lwidth: 225px;
    --lheight: 56px;
    --position: center;
    min-width: var(--lwidth);
    min-height: var(--lheight);
    background-image: url('/assets/logo.svg');
    background-repeat: no-repeat;
    background-position: var(--position);
    background-size: contain;
    &.black {
        // background-image: url('/assets/old_logo_black.svg');
    }
}

.modal-abey-wallet {
    z-index: 10002 !important;
}

// .mint_modal_overlay {
//     display: flex;
//     flex-direction: column;
//     max-height: 100vh;
//     &__card {
//         flex: 1;
//         overflow: hidden;
//         min-height: 500px;
//     }
//     &__scroll {
//         overflow-y: auto;
//     }
// }
// .mobile {
//     max-height: unset;
//     &__card {
//         overflow: unset;
//     }
//     &__scroll {
//         overflow-y: unset;
//         min-height: unset;
//     }
// }