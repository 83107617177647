.redesign {
    .mobile-menu {
        .mobile-balance {
            padding: 16px;
            background: $color-white;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: $br-4;
            &-label {
                font-size: 12px;
                font-weight: 700;
                line-height: 150%;
                color: $color-additional-alt;
            }
            &-value {
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                color: $color-primary;
                margin-top: 8px;
            }
        }
    }
    .content-body {
        padding: 24px;
        .content-main {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .page-header h4 {
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                margin: 0;
            }
            .main-header {
                display: none !important;
            }
            .App:not(.canvas-map) {
                position: relative !important;
            }
            .inner__container {
                // background: transparent;
            }
        }
    }
    .tab-filters {
        display: flex;
        gap: 8px;
    }
    
}

body.mobile {
    overscroll-behavior-y: contain;
    overflow: hidden;
    
    .redesign {
        .content-body {
            padding: 24px 16px 32px 16px;
            .content-main {
                width: 100%;
            }
        }
        .tab-filters {
            padding: 16px;
            width: max-content;
            &-wrap {
                width: 100vw;
               margin: -16px;
               overflow-x: auto;
            }
        }
    }
    #root .App {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.cursor-pointer {
    cursor: pointer;
}
.container-fluid {
    @include make-container(32px);
}

.main-container {
    display: flex;
    flex-direction: column;

    .inner__container {
        flex: 1;
        overflow-y: auto;
    }
}

.comming__soon {
    @extend .container-fluid;
    height: 100vh;

    background-image: url('../../../public/background_soon_inner.jpg');
    background-image: url('../../../public/background_soon_inner.webp');
    background-size: cover;
    padding-top: 155px;
}
.unread {
    &--nested-item {
        position: relative;
        right: calc(-100% + 16px);
        top: 16px;
    }
    &--header-item {
        position: absolute;
        right: 20px;
        top: 0px;
    }
    &--menu-item {
        margin-left:12px;
    }
    &--marker {
        display: block;
        width: 8px;
        height: 8px;
        background-color:#F44949;
        border-radius: 16px;
    }
}
.inner__container {
    @extend .container-fluid;

    background-image: url('../../../public/background_inner.jpg');
    background-image: url('../../../public/background_inner.webp');
    background-size: cover;

    padding-bottom: 46px;
    margin-top: 80px;
    @include header-min {
        margin-top: 72px;
    }
    h4 {
        font-size: 2.375rem;
        margin: 44px 0 24px;
    }

    .table-wrap {
        border-radius: 24px;
        padding:8px;
        &.react-loading-skeleton {
            border-radius: 16px !important;
        }
    }

}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .inner__container .table-wrap {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
    }
}
a {
    font-weight: 700;
}

.main {
    &-wrapper {
        flex: 1;
        position: relative;
    }
    &-container {
        display: flex;
        flex-direction: column;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button.clear, button.clear:focus{
    outline: none;
    background: transparent;
    border: 0;
  }

button.clear:active{
    outline: none;
    background: transparent;
    border: 0;
}

.popup-inner {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .btn-outline-dark {
        margin-top: 12px;
        border: 1px solid #3A324E;
        color: #3A324E;
        border-radius: 12px;
        font-size: 1rem;
        font-weight:500;
        &:hover {
            color: #ffffff;
        }
    }
}
.plot-info {
    display: flex;
    font-family: Nunito, sans-serif;
    // min-width: 340px;
    &--icon {
        cursor:pointer;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.9);
        box-sizing: border-box;
        width: 64px;
        height: 64px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #909090;
        @supports not (background-image: url('/plot_thumbs/1.webp')) {
            background-image: var(--icon, none);
        }
        @supports (background-image: url('/plot_thumbs/1.webp')) {
            background-image: var(--icon-webp, none);
        }
        &:hover {
            .plot-info--icon-tint {
                opacity:1;
            }
        }
        &-tint {
            opacity:0;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            transition:opacity .2s;
        }
        .faw-zoom-in {
            font-size:1.75rem;
            color: white;
            display: block;
        }
    }
    &--body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 12px;
    }
    &--header {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #2E3549;
        margin: 0px 2px;
        &_level {
            color: #FE7202;
            margin-right: 2px;
        }
        .btn-close {
            width: 8px;
            height: 8px;
            align-self: normal;
        }
    }
    &--owner {
        font-weight: 700;
        font-size: .75rem;
        color: #4B5677;

        overflow-x: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
    }
    &--status {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        margin-bottom: 4px;
        &.isAvailable {
            font-size: 0.5625rem;
            border: 1px solid;
            border-radius: 4px;
            padding: 4px 3px 0px 4px;
            text-transform: uppercase;
            margin-left: 8px;
        }
        &_available {
            color: #5BBC43;
            border-color: #5BBC43;
        }
        &_unavailable {
            color: #8f8f8f;
            border-color: #8f8f8f;
        }
        &_soldout {
            color: #7A85EB;
            border-color: #7A85EB;
        }
        &_owned {
            color: #FE7202;
            border-color: #FE7202;
        }
        &_minted {
            color: #009DC4;
            border-color: #009DC4;
        }

    }
    &--price {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #4B5677;
        &-abey {
            margin-left: 10px;
        }
        &_red {
            color: #F44949;
        }
    }
}
.plot-popup {
    &--wrap {
        display: flex;
        align-items: center;
        .plot-info {
            flex: 1;
        }
    }
    &--warning {
        font-family: Nunito, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        color: #F44949;
    }
    &--button {
        margin-top: 12px;
        button {
            @extend button, .clear;
            font-family: Nunito, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            border-radius: 12px;
            padding: 12px 24px;
            background-color: #FE7202;
            color: #FFFFFF;
            transition: background-color .2s;

            &.disabled, &:disabled {
                opacity: .4;
                pointer-events: none;
            }
            &:hover, &:active, &.active, &:focus {
                background-color: #994401;
            }
            &:focus {
                outline: 4px solid #FEAB68 !important;
            }
        }
    }
}

.cart {
    &-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        max-width: 100%;
        margin-top: 80px;
        padding: 2rem;
        overflow: hidden;
        pointer-events: none;
        > * {
            pointer-events: all;
        }
    }
    &-body {
        font-family: Nunito, sans-serif;
        max-height: 100%;
        width: 380px;
        display: flex;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        padding: 24px;
        &.buy {
            background: #F7F7F7;

        }
        &:not(.buy) {
            backdrop-filter: blur(32px);
        }
    }
    &-header {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 120%;
        color: #2E3549;
        margin-top: 8px;
        margin-bottom: 24px;
    }
    &-list {
        flex: 1;
        overflow-y: auto;
        overflow-x: clip;
        scrollbar-width: thin;
        &::scrollbar {
            width: 4px;
            border-radius: 2px;
            background-color: #B5BDCA; /* or add it to the track */
        }
        &::scrollbar-thumb {
            border-radius: 2px;
            background: #231E2F;
        }
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
            background-color: #B5BDCA; /* or add it to the track */
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: #231E2F;
        }
    }
    &-item {
        &:not(:last-child) {
            margin-bottom: 28px;
        }
        padding: 4px;
        border-radius: 16px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        &.selected, &.enable-hover:hover {
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
            background: #FFFFFF;
        }
        &.soldout {
            outline: 1px solid rgba(122, 133, 235, 0.75);
        }
        &--info {
            flex: 1;
            cursor: pointer;
        }
        &--remove {
            .btn {
                padding: 6px;
                border-radius: 6px;
                line-height: 1;
                filter: grayscale(1);
                transition: filter .2s;
                &:hover {
                    filter: grayscale(0);
                }
            }
        }
    }
    &-devider {
        height: 1px;
        background-color: #FFFFFF;
        // border: 1px solid #FFFFFF;
        margin: 24px 0;
    }
    &-footer {

    }
    &-total {
        display: flex;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #2E3549;
        &--skeleton {
            flex: 1;
        }
    }
    &-warning {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #BC4343;
    }
    &-button {
        button {
            width: 100%;
            @extend button, .clear;
            font-family: Nunito, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            border-radius: 12px;
            padding: 12px 24px;
            background-color: #FE7202;
            color: #FFFFFF;
            transition: background-color .2s;

            &.disabled, &:disabled {
                opacity: .4;
                pointer-events: none;
            }
            &:hover, &:active, &.active, &:focus {
                background-color: #994401;
            }
            &:focus {
                outline: 4px solid #FEAB68 !important;
            }
        }
    }
    &-button-clear {
        display: flex;
        justify-content: center;
        button {
            @extend button, .clear;
            font-family: Nunito, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #009DC4;
            &:hover {
                color: #327DA0;
            }
            &:active {
                color: #327DA0;
            }
            &:focus:not(:active) {
                color: #327DA0;
                text-decoration-line: underline;
            }
        }
    }
}

.confirmation, .overlay {
    &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        z-index: 10001;
        background: var(--background-modal-tint, rgba(32, 30, 36, 0.90));
        backdrop-filter: blur(4px);
        padding: 16px;
        .cart-container {
            position: relative;
            max-height: 100%;
            display: flex;
            margin-bottom: 80px;
        }
        .cart-button {
            display: flex;
            margin-left: -8px;
            margin-right: -8px;
            button {
                margin-left: 8px;
                margin-right: 8px;
                white-space: nowrap;
            }
            button.cart-cancel {
                border: 2px solid #FE7202;
                background: transparent;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                color: #FE7202;
                &.disabled, &:disabled {
                    opacity: .4;
                    pointer-events: none;
                }
                &:hover, &:active, &.active, &:focus {
                    background: #FE7202;
                    color: #FFFFFF;
                }
                &:focus {
                    outline: 4px solid #FE7202 !important;
                }
            }
            button.cart-close {
                color: #FFFFFF;
                background: #51466D;

                &.disabled, &:disabled {
                    opacity: .4;
                    pointer-events: none;
                }
                &:hover {
                    background: #3A324E;
                }
                &:active {
                    background: #231E2F;
                }
                &:focus:not(:active) {
                    background: #3A324E;
                    outline: 4px solid #51466D !important;
                }
            }
        }
    }
    &-close-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }
}

.mobile {
    &-map-btn {
        cursor: pointer;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 40px;
        position: relative;
        .faw-cross {
            font-size: 1.125rem;
        }
        &.top {
            z-index: 10000;
            background: transparent;
            box-shadow: none;
            backdrop-filter: initial;
            border: 2px solid #FFFFFF;
        }
        .value {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            width: 16px;
            height: 16px;
            right: 0px;
            top: 0px;

            background: #FE7202;
            border-radius: 16px;
            overflow: hidden;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 165%;
            color: #FFFFFF;
        }
    }
    &-map-zoom {
        cursor: pointer;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 32px;
    }
    &-map-area {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 72px;
        overflow: hidden;
        pointer-events: none;
        .legend-wrap {
            position: absolute;
            top: 72px;
            right: 80px;
            padding-top: 16px;
            > * {
                margin: 0;
                backdrop-filter: initial;
                background: #F7F7F7;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            }
        }
        .overlay-container {
            pointer-events: all;
        }
        &--over {
            position: relative;
            flex: 1;
            pointer-events: none;
            &-zone {
                pointer-events: all;
                position: absolute;
                padding: 16px;
                &.topright {
                    top: 0;
                    right: 0;
                }
                &.bottomright {
                    bottom: 0;
                    right: 0;
                    padding-bottom: 16px;
                }
                &.placig-bottom {
                    bottom: 144px;
                }
            }
        }
        &--footer {
            pointer-events: all;
            display: flex;
            flex-direction: column;
            &:not(.drag) {
                height: auto !important;
            }
            &.hidden:not(.drag) {
                height: 44px !important;
            }
            &-tail {
                padding-top: 24px;
                z-index: 2;
                padding-bottom: 24px;
                margin: 0 64px;
                margin-bottom: -16px;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                &::before {
                    display: block;
                    content: '';
                    background: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 8px;
                    width: 48px;
                    height: 4px;
                }
            }
            &-inner {
                overflow: hidden;
                background: rgba(255, 255, 255, 0.75);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(32px);
                border-radius: 28px 28px 0px 0px;
                padding: 8px;
                flex: 1;
            }
            &-container {
                min-height: min-content;
                display: flex;
                flex-direction: column;
            }
            &-item {
                padding: 8px;
                background: #FFFFFF;
                border-radius: 20px;
            }
        }
    }
    &-my_plots {
        &-button {
            @extend button, .clear;
            width: 100%;
            font-family: Nunito, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            padding: 12px 24px;
            border-radius: 12px;
            color: #FFFFFF;
            background: #51466D;
            &.disabled, &:disabled {
                opacity: .4;
                pointer-events: none;
            }
            &:hover {
                background: #3A324E;
            }
            &:active {
                background: #231E2F;
            }
            &:focus:not(:active) {
                background: #3A324E;
                outline: 4px solid #51466D !important;
            }
        }

    }
    &-balance {
        &--half {
            flex: 1;
            &:not(:last-of-type) {
                margin-right: 16px;
            }
            &_wrap {
                justify-content: center;
                display: flex;
            }
        }
        &--item {
            padding: 16px;
            background: #FFFFFF;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            &.info {
                background: rgba(46, 53, 73, 0.79);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(40px);
                color: #F9FBFD;
                font-size: 14px;
            }
            &.linked-wallet {
                &.invalid {
                    background-color:#F44949;
                }
                &.valid {
                    background-color:#5BBC43;
                }
                &.notconfirmed {
                    background-color:#ECAB04;
                }
                [class*=" faw-"] {
                    color: #ffffff;
                    font-size: 2.291875rem;
                }
                & .mobile-balance {
                    &--lw {
                        &-label {
                            color: #fff;
                            font-size: 0.75rem;
                            font-weight:600;
                            line-height:1.44;
                        }
                        &-value {
                            img {
                                width: 37px;
                                height: 37px;
                                margin-left: 2px;
                                margin-top: -1px;
                            }
                        }
                        &-container {
                            position:relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 16px;

                        }
                    }
                }
            }
        }
        &--abey {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            &-label {
                font-weight: 600;
                font-size: .75rem;
                line-height: 178%;
                color: #4B5677;
            }
            &-value {
                margin-top: 8px;
                font-weight: 700;
                font-size: 1rem;
                line-height: 120%;
                color: #FE7202;
            }
        }
        &--tickets {
            flex: 1;
            cursor: pointer;
            &-container {
                display: flex;
                font-family: 'Nunito', sans-serif;
                font-style: normal;
            }
            &-label {
                word-wrap: normal;
                white-space: nowrap;
                font-weight: 600;
                font-size: .75rem;
                line-height: 150%;
                color: #4B5677;
            }
            &-value {
                font-weight: 700;
                font-size: 2.375rem;
                line-height: 120%;
                color: #FE7202;
            }
            &-add {
                display: flex;
                align-items: center;
                padding-left: 12px;
                border-left: 1px solid #FFFFFF;
            }
        }
    }
    &-cart {
        &--container {
            max-height: 100%;
            overflow: hidden;
            display: flex;
            padding: 16px;
            z-index: 100;
            width: 100%;
            .cart-body {
                width: 100% !important;
                background: #F7F7F7;
                backdrop-filter: initial;
            }
        }
    }
    .modal-aw-transfer {
        display: block;
    }
    
}
.mobile {
    .overlay-container {
        padding: 0;
    }
}
.App {
    &.mobile, &.app-mobile {
        .cart-container {
            padding: 0 ;
            right: 0;
            top: initial;
            margin-top: calc(100vh - 146px);
            overflow: initial;
            .cart-body {
                margin-right: 0;
                width: 100%;
                height: 146px;
                padding: 8px;
                .mobile-map-btn {
                    position: absolute;
                    z-index: 1;
                    margin-top: -68px;
                }
                .cart-list {
                    overflow-y: clip;
                    overflow-x: auto;
                    display: flex;
                    .market-buildings--placement {
                        &-item {
                            display: block;
                            padding-right: 0;
                            .body-content {
                                margin-bottom: 12px;

                            }

                        }
                    }
                    .cart-item {
                        margin-bottom: 0;
                        border: 1px solid #FFFFFF;
                        margin-right: 8px;
                        padding: 8px;
                        &.selected {
                            button {
                                &.market-buildings--owned-list--item-action--place {
                                    color: #FFFFFF;
                                    background: #51466D;
                                }
                            }
                        }
                    }
                }
                .cart-list::-webkit-scrollbar {
                    height: 4px;
                }
            }
        }
        .confirmation-container {
            .cart-container {
                margin-top: 0;
                padding: 0;
                .market-buildings--build-mirror--item {
                    width: 128px;
                    height: 128px;
                    &.active::after {
                        border: 2px solid #FEAB68;
                        border-radius: 30px;
                        left: -4.69%;
                        right: -4.69%;
                        top: -4.69%;
                        bottom: -4.69%;
                    }
                }
            }
        }
    }
}
.payment-option {
    &.text-red {
        span {
            color: #F44949 !important;
        }
    }
    &.form-check {
        background-color: #ffffff;
        margin-top: -8px;
        margin-bottom: 0px;
        padding: 16px 2.5rem 16px 56px;
        border-radius: 16px;
        .form-check-input {
            border: 2px solid ;
            cursor: pointer;
            &:checked {
                background-size: 28px;
                background-color: #ffffff;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23F0A920%27/%3e%3c/svg%3e");
            }
        }
        .form-check-label {
            margin-left: 0px;
            font-weight: 400;
            font-size: 1rem;
            .cursor-pointer & {
                cursor: pointer;
            }
            color: #2E3549;
        }
    }
    & + .payment-option {
        margin-top: 16px;
    }
}
.currency-price {
    &--icon {
        margin-top: -1px;
    }
}
