.ads-catalog {
    font-family: 'Nunito' !important;
    font-style: normal !important;
    background-image: url('../../../public/bg_abstract.jpg');
    background-position: top;
    .h-inherit {
        font-size: inherit;
        font-weight: inherit;
        margin: 0;
        line-height: inherit;
        color: inherit;
    }
    .landing__footer {
        font-size: 16px !important;
    }
    .link__black {
        .header__right {
            color: #3A324E !important;
            .btn-outline-light {
                border: none;
                color: #3A324E !important;
                font-weight: 600 !important;
                font-size: 16px !important;
            }
            a {
                color: #3A324E !important;
                font-weight: 600 !important;
                font-size: 16px !important;
                &.active {
                    color: #fff !important;
                }
            }
        }
    }
    #main {
        display: flex;
        flex-direction: column;
    }
    header {
        height: 72px;
        background: #FFFFFF;
        .faw-logo {
            color: #f0a920 !important;
            margin-top: 16px !important;
            font-size: 2.5rem  !important;
        }
        h3 {
            font-size: 21px;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        >div {
           padding-right: 160px ; 
           padding-left: 160px ; 
        }
        min-height: calc(100vh - 160px);
        .ads-search {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 16px ;
            padding-bottom: 16px ;
            gap: 8px;
            background: #F9FBFD;
            border-top: 1px solid #E0EBF6;
            width: 100%;
            select {
                appearance: none !important;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") !important;
                background-repeat: no-repeat !important;
                background-position: right 1rem center !important;
                background-size: 14px !important;
                padding-right: 2em;
            }
            .faw-magnifier{
                font-size: 20px;
            }
            input {
                padding-top: 11px;
                padding-bottom: 11px;
                border-right: none;
                background-color: #FFFFFF !important;
                border-color: #B5BDCA !important;
            }
            
            .btn.category-btn {
                border: none;
                padding: 6px 11px;
                position: relative;
                select {
                    width: 100%;
                    background: transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 0;
                    right: 0;
                    bottom: 0;
                    background-image: none !important;
                }
            }
            .category {
                border-left: none;
                border-right: none;
                background: #FFFFFF;
                border-color: #B5BDCA !important;
                width: 275px;
                display: flex;
                color: #4B5677;
                font-weight: 600;
                outline: unset;
                .arrow-down {
                    margin-left: auto;
                    margin-right: 16px;
                }
                .arrow-down::after {
                    font-family: 'faw' !important;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    content: "\e918";
                    font-size: 0.37rem;
                }
            }
        }
        .totals {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            padding: 16px 0px;
            .ads-overview--wspace {
                min-width: 200px;
            }
            .title {
                font-weight: 700;
                font-size: 12px;
                line-height: 150%;
                color: #505D77;
                white-space: nowrap;
            }
            .value {
                font-weight: 700;
                font-size: 28px;
                line-height: 120%;
                &.hidden {
                    height: 0;
                    overflow: hidden;
                }
            }
            
            &-ads {
                color: #7A85EB;
            }
            &-users {
                color: #FE7202;
            }
            &-views {
                color: #5BBC43;
            }
        }
        .result {
            flex: 1;
            padding-top: 24px;
            background: #F7F7F7;
            .categories {
                width: 286px;
                
                
            }
            .ads-list {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                padding-left: 32px;
                .no-data-content {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    color: #4B5677;
                    padding: 16px;
                    gap: 16px;
                    border: 1px solid #E0EBF6;
                    border-radius: 16px;
                }
                .search-result {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 120%;
                    color: #2E3549;
                    margin: 24px 0 12px 0;
                    white-space: pre-line;
                }
                .ad-item, .ads-detail-item {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 16px;
                    gap: 16px;
                    background: #FFFFFF;
                    border-radius: 32px;
                    margin: 24px 0;
                    &:first-child {
                        margin-top: 24px !important;
                    }
                    .ad-image {
                        width: 288px;
                        height: 192px;
                        border: none;
                    }
                    .ad-item-title {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 120%;
                        color: #2E3549;
                        .faw-arrow_up, .faw-arrow_down {
                            font-size: 20px;
                            padding: 6px;
                            color: #FFFFFF;
                            border-radius: 8px;
                            margin-right: 8px;
                        }
                        .faw-arrow_up {background-color: #5BBC43;}
                        .faw-arrow_down {background-color: #F44949;}
                    }
                    .ad-item-stats {
                        margin-top: 8px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #4B5677;
                        .faw-eye_open_lite {
                            font-size: 22px;
                            margin-right:  5px;
                            display: inline-block;
                        }
                    }
                    .ad-item-label {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 120%;
                        color: #4B5677;
                        margin: 12px 0 4px 0;
                    }
                    .ad-item-description {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #4B5677;
                        white-space: initial;
                        max-height: 48px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    
                    .detail-box {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 24px;
                        gap: 8px;
                        height: fit-content;
                        background: #FFFFFF;
                        border-radius: 24px;
                        &:first-child {margin-right: 16px;  position: relative;}
                        .ad-item-description {
                            max-height: initial;
                            text-overflow: initial;
                        }
                        .staff-events-editor--video-body--list-item {
                            height: 330px;
                        }

                        .ad-image {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            border-radius: 0;
           
                            z-index: 1;
                            > img {
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                                border-radius: 16px;
                            }   
                        }
                        
                    }
                }
                .ads-detail-item {
                    white-space: pre-line;
                    background: none;
                    .ad-item-description, a {
                        font-size: 18px;
                        white-space: pre-line;
                    }
                    a {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: calc(100% - 24px);
                    }
                }
                .ad-item{
                    &:hover {
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    }
                    .ad-image {
                        margin-right: 16px;
                        display: flex;
                        
                        > img {
                            border: 1px solid #E0EBF6;
                            max-width: 100%;
                            max-height: 100%;
                            margin: 8px auto auto auto;
                            border-radius: 16px;
                        }
                        
                    }
                }
            }
        }
    }
    .landing__footer {
        height: 88px;
    }
}

.modal-categories {
    .card {
        width: 100%;
        margin: auto 128px;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 24px;
        &-title {
            font-weight: 700;
            font-size: 38px;
            line-height: 120%;
        }
        &-body {
            grid-template-columns: 1fr 1fr 1fr;
            display: grid;
            padding: 24px 0 32px 0;
            grid-gap: 16px 32px;
            >div{
                border-bottom: 1px solid #E0EBF6;
                .faw-check_round {
                    opacity: 0;
                    &.active {
                        opacity: 1 !important;
                    }
                    
                }
                &:hover {
                    .faw-check_round {
                        opacity: 0.5;
                    }
                }
            }
            .category-item {
                margin-bottom: 0;
                padding-bottom: 16px;
                
                &-title {
                    color: #2E3549;
                }
            }
            .faw-vector_right {
                color: #009DC4;
                font-size: 12px;
            }
        }
    }
}

.btn.search-btn {
    border-radius: 12px;
    background-color: #7000BF;
    border-color: transparent;
    &:active, &:hover, &:focus {
        background: #9B1EF2;
        outline: none;
    }
}

.category-item {
    margin-bottom: 12px;
    &.active { 
        .category-item-title {
            color: #3A324E !important;}
    }
    &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #009DC4;
        &:hover {
            color: #327DA0;
        }

    }
    .total, .new {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
    }
    .new {color: #5BBC43; margin-left: 4px;}
    .total {color: #B5BDCA}
}

.mobile {
    .ads-search {
        form {
            align-items: baseline!important;
            display: flex;
        }
    }
    .modal-categories {
        .card {
            margin: 0;
            padding: 24px;
            height: 100%;
            border-radius: 24px;
            &-body {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                color: #2E3549;
                
            }
            .sub-title {
                font-weight: 700;
                font-size: 21px;
                line-height: 120%;
                color: #2E3549;
                margin-top: 24px;
            }
            &-title {
                font-size: 28px;
            }
            &-actions {
                gap: 16px;
                margin-top: 24px;
                >button {
                    flex: 1;
                }
            }
        }
        .sear-box {
            padding-bottom: 24px;

            border-bottom: 1px solid #E0EBF6;
        }
    }
    .ads-catalog {
        min-height: 100vh;

        #main {
            min-height: 100% !important;
        }
        .no-data-wrap {
            padding: 0 !important;
            background: transparent !important;
            box-shadow: none;
        }
        .landing__footer {
            padding: 32px 0px;
        }
        .main-header {
            position: relative;
            z-index: 10;
            padding: 8px 0;
            width: 100vw;
            .container-fluid {
                height: 37px;
                .iw_logo {
                    --lwidth: 160px !important;
                    --lheight: 39px !important;
                }
            }
            .dropdown-menu {
                &.mobile-menu {
                    display: none;
                    &.show {
                        display: block;
                        .mobile-menu--section  {
                            display: flex;
                        }
                        .mobile-menu--h2 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 21px;
                            line-height: 120%;
                            color: #2E3549;
                            padding: 16px 12px;
                            font-family: 'Rubik', sans-serif;
                        } 
                    }
                }
            }
        }
        .content {
            > div {
                padding-right: 16px;
                padding-left: 16px;
            }
            .result {
                .ads-list {
                    padding: 0;
                    .ad-item, .ads-detail-item {
                        padding: 8px;
                        border-radius: 24px;
                        >div {
                        flex-direction: column; 
                        width: 100%;
                        }
                        .ad-item-title {
                            font-size: 21px;
                            padding: 0 8px;
                        }
                        .ad-item-stats {
                            font-size: 12px;
                            display: flex;
                            padding: 0 8px 8px 8px;
                            .v-line {display: flex;     align-items: center;}
                            .faw-eye_open_lite {
                                font-size: 14px;
                            }
                        }
                        .ad-image {
                            margin-right: 0;
                            width: 100%;
                            height: 100%;
                            > img {
                                margin: 0;
                                
                            }
                        }
                        .detail-box {
                            padding: 16px;
                            &:first-child {padding: 8px; margin-right: 0;}
                            .staff-events-editor--video-body--list-item {
                                height: 262px;
                            }
                        }
                    }
                    .ad-item {
                        margin: 16px 0;
                    }
                    .search-result {
                        margin-top: 16px;
                    }
                    .ads-detail-item {
                        padding: 0;
                        margin-top: 16px;
                        .ads-create--input-text {
                            display: flex;
                            width: 100%;
                            
                        }
                    }
                }
                padding-top: 16px;
            }
        }
        .totals {
            min-width: min-content;
            &-wrap {
                overflow-x: auto;
            }
            .ads-overview--wspace {
                min-width: initial;
            }
        }
    } 
}

