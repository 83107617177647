@use "../mixins.module.scss" as *;

// LOCAL STYLES
@mixin card {
  min-width: 328px;
  max-width: 596px;
}

// MAIN MODULE STYLES
.overview {
  &_title {
    margin-bottom: 0%;
    color: var(--text-basic, #2E3549);
    @include bold__title_1;
  }
  &_right_container {
    width: 100%;
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sp-4, 16px);
    flex: 1 0 0;
    align-self: stretch;
  }
  &_status {
    @include card;
    position: relative;
    border-radius: 16px;
    width: 100%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;

    background: #7D7D7D;
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
    .title {
      color: var(--colors-white, #FFF);
      @include bold__body_2;
    }
    .subtitle {
      color: var(--colors-white, #FFF);
      @include bold__title_1;
    }
    &.inactive {
      background: linear-gradient(135deg, #D9725F 0%, #A83D3D 100%);
    }
    &.active {
      background: linear-gradient(135deg, #78D95F 0%, #3EA83D 100%);
    }
    &.wait {
      background: linear-gradient(135deg, #D9B65F 0%, #D39A07 100%);
    }


    .round_1, .round_2, .round_3 {
      position: absolute;
      background: rgba(255, 255, 255, 0.05);
      flex: none;
    }
    .round_1 {
      width: 401px;
      height: 401px;
      right: -149px;
      top: 7px;
      border-radius: 200px;
      order: 1;
      flex-grow: 0;
      z-index: 1;
      box-shadow: inset 0px 0px 128px rgba(255, 255, 255, 0.15);
    }
    .round_2 {
      width: 435px;
      height: 435px;
      right: -164px;
      top: -27px;
      border-radius: 218px;
      order: 2;
      flex-grow: 0;
      z-index: 2;
      box-shadow: inset 0px 0px 128px rgba(255, 255, 255, 0.08);
    }
    .round_3 {
      width: 480px;
      height: 480px;
      right: -188px;
      top: -72px;
      border-radius: 240px;
      box-shadow: inset 0px 0px 128px rgba(255, 255, 255, 0.05);
    }
  }
  &_card {
    @include card;
    display: flex;
    padding: var(--sp-4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    border-radius: 16px;
    background: var(--background-main, #FFF);
    .auth {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &_title {
        flex: 1 0 0;
        color: var(--text-basic, #2E3549);
        @include bold__body_2;
      }
    }
    .second {
      display: flex;
      padding-top: 8px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      border-top: 1px solid var(--strokes-main-darken, #E0EBF6);

      &_text {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;

        color: var(--text-additional, #414B67);

        @include regular__caption_1;
      }
    }
    .third {
      &_title {
        color: var(--text-additional, #414B67);

        @include regular__caption_1;
      }
      &_credits {
        color: var(--text-basic, #2E3549);

        @include bold__body_2;
      }
    }
  }
  &_stats {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sp-4, 16px);
    align-self: stretch;
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--sp-1, 4px);
      align-self: stretch;
    }
    &__title {
      color: var(--text-basic, #2E3549);

      @include bold__title_2;
    }
    &__subtitle {
      color: var(--text-additional, #414B67);
      font-size: 14px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__body {
      display: flex;
      align-items: center;
      align-content: flex-start;
      gap: var(--sp-4, 16px) 16px;
      align-self: stretch;
      flex-wrap: wrap;
    }
    &__card {
      display: flex;
      min-width: 200px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      border-radius: 16px;
      background: var(--background-main, #FFF);
      &___title {
        display: flex;
        flex-direction: column;
        align-self: stretch;

        color: var(--text-additional, #414B67);
        font-size: 12px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      &__subtitle {
        @include bold__body_2;
        &.green {
          color: var(--scheme-green, #5BBC43);
        }
        &.orange {
          color: var(--scheme-orange, #FE7202);
        }
        &.blue {
          color: var(--scheme-soft-blue, #7A85EB);
        }
      }
    }
  }
  &_table {
    &__container {
      display: flex;
      padding-top: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--sp-4, 16px);
      align-self: stretch;
    }
    &__title {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      &_text {
        color: var(--text-basic, #2E3549);

        @include bold__title_2;
        margin-bottom: 0%;
      }
    }
    &__before {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
    
    &__filter {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      &_mtitle {
          display: none;
      }
      &_input {
        min-width: 157px;
      }
      &_actions {
        display: flex;
        align-items: flex-start;
      }
      &_body {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }
      &_wrap {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }
    }
    &__update_at {
      color: var(--text-additional, #414B67);
      font-size: 14px;
      font-family: Nunito, sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      white-space: nowrap;
    }
    &__button {
      &_reset {
        border: none !important;
        outline: none !important;
        background: transparent !important;

        display: flex;
        padding: 8px 12px;
        align-items: flex-start;
        gap: 10px;

        border-radius: 24px;

        color: var(--text-basic, #2E3549);
        font-size: 14px;
        font-family: Nunito, sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      &.loading {
        pointer-events: none;
        > * {
          opacity: .6;
        }
      }
    }
    &__no_data {
      display: flex;
      min-height: 200px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      color: var(--colors-ebony-clay, #2E3549);
      text-align: center;
      @include medium__body_2;
    }
    &__views {
      color: var(--scheme-red, #F44949) !important;
    }
    &__clicks {
      color: var(--scheme-green, #5BBC43) !important;
    }
    &__pagination {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
  }
}

.mobile {
  .overview {
    &_stats {
      &__body {
        display: flex;
        align-items: center;
        align-content: flex-start;
        gap: var(--sp-4, 16px) 16px;
        align-self: stretch;
        flex-wrap: wrap;
      }
      &__card {
        width: 100%;
        &_title {
          font-family: Nunito;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        &_subtitle {
          font-family: Nunito;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
      &__title {
        @include bold__body_1;
      }
    }
    &_table {
      &__pagination {
        align-items: center;
        justify-content: center;
      }
      &__container {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--sp-4, 16px);
        align-self: stretch;
      }
      &__title {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        &_text {
          color: var(--text-basic, #2E3549);
  
          @include bold__title_2;
          margin-bottom: 0%;
        }
      }
      &__filter {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;

        display: flex;
        padding: var(--sp-4, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        background: #FFF;
        &_mtitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .mtitle {
            color: var(--text-basic, #2E3549);

            @include bold__title_2;
          }
          .mclose {
            border: none !important;
            outline: none !important;
            background: transparent !important;
            padding: 0% !important;
            margin: 0% !important;
            i {
              font-size: 20px;
            }
          }
        }
        &_body {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
        }
        &_wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--sp-4, 16px);
          align-self: stretch;
          .iw_input__group {
            width: 100%;
          }
        }
        &_actions {
          width: 100%;

          display: flex;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          > * {
            flex: 1 0 0;
            justify-content: center;
          }
        }
      }
      &__update_at {
        color: var(--text-additional, #414B67);
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      &__button {
        &_reset {
          order: -1;
        }
      }
    }
  }
}
