/** All toolbar buttons are inside of .ql-formats */
.ql-formats button, .ql-size, .ql-font {
    position: relative;

    /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus  */
    &:hover::after,
    &:active::after,
    &:focus::after {
        background: #0d1e42;
        white-space: nowrap;
        color: white;
        padding: 0.5em;
        border-radius: 0.4em;
        top: -140%;
        left: -10px;
        z-index: 999999;
        position: absolute;
        font-size: 12px;

    }
}
/** Set tooltip content for bold button **/
.ql-bold {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Bold";
    }
}

.ql-italic {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Italic";
    }
}

.ql-underline {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Underline";
    }
}
.ql-strike {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Strikeout";
    }
}
.ql-link {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Hyperlink";
    }
}
.ql-clean {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Clear formatting";
    }
}
.ql-blockquote {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Quote";
    }
}
.ql-source {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Show source";
    }
}
.ql-image {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Add image";
    }
}
.ql-size {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Font size";
    }
}
.ql-font {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Fonts";
        left: 20px;
    }
}
.ql-list[value="bullet"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Bulleted List";

    }
}
.ql-list[value="ordered"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Numbered List";

    }
}
.ql-indent[value="-1"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Decrease indent";

    }
}
.ql-indent[value="+1"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Increase indent";

    }
}
.ql-header[value="1"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Heading 1";
    }
}
.ql-header[value="2"] {
    &:hover::after,
    &:active::after,
    &:focus::after {
        content: "Heading 2";
    }
}