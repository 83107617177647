.footer {
  display: flex;
  width: 1224px;
  position: relative;

  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  &, .footer * {
    box-sizing: border-box;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
  }
  &__address {
    color: #505D77;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &__menu {
    flex-shrink: 0;
    width: 603px;
    height: 32px;
    position: relative;
    display: flex;
    gap: 32px;
  }
  &__item {
    color: #353451;
    text-align: left;
    font: 400 16px/200% "Rubik", sans-serif;
    &:hover, &:focus, &:active, &.active {
      color: #7000BF;
    }
  }

  &__copyright {
    color: #353451;
    text-align: left;
    font: 400 14px/200% "Rubik", sans-serif;
    position: relative;
  }
}

