.redesign {
    .marketplace {
        &-card {
            z-index: 0;
            position: relative;
            padding: 16px;
            &-container {
                display: grid;
                padding: 0 ;
                grid-gap: 16px 24px;
                grid-template-columns: repeat(auto-fill, minmax(241px, 1fr));
            }
            &::after {
                content: "";
                background-color: $color-white;
                position: absolute;
                top: 62px;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: $br-5;
                z-index: -2;
            }
            > div {
                position: relative;
            }

            &-name {
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
            }
            &-id {
                line-height: 150%;
                font-size: 12px;
                font-weight:400;
                color:#6C7284;
            }
            &-badge {
                /* Regular/Caption 1 */
                font-family: Nunito;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 18px */
                width: fit-content;
                padding: 4px 12px;
                margin: auto;

                border-radius: 16px;
                box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);

                position: absolute !important;
                top: 127px;
                left: 0;
                right: 0;

                &-danger {
                    color: $color-white;
                    background: #F56E6E;
                }
            }
            &-subname {
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 150%;
                font-style: normal;
            }
            &-pins {
                margin-top: -16px;
                transform: translateY(-50%);
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px var(--sp-2, 8px);
                align-self: stretch;
                flex-wrap: wrap;
                &_button {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    padding: var(--sp-2, 8px);
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    align-self: stretch;
                    border: 2px solid #fff;
                    outline: none !important;

                    border-radius: var(--r-6, 24px);
                    background: var(--scheme-buttons-button, #fff);
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
                    font-size: 16px;
                    margin: 0;
                    padding: 0;
                    &:disabled {
                        pointer-events: none;
                    }
                    &.location {
                        font-size: 18px;
                        &:hover, &:focus, &:active {
                            background: var(--scheme-buttons-button, #28262C);
                            color: var(--scheme-buttons-text, #fff);
                        }
                    }
                    &.remove {
                        font-size: 12px;
                        color: #F44949;
                        &:hover, &:focus, &:active {
                            background: var(--scheme-red, #F44949);
                            color: var(--scheme-buttons-text, #fff);
                        }

                    }
                    &.add {
                        font-size: 12px;
                        color: #5BBC43;
                        &:hover, &:focus, &:active {
                            color: var(--scheme-buttons-text, #fff);
                            background: #5BBC43;
                        }
                    }
                }
            }

            &-main-picturte {
                width: 192px;
                height: 124px;
                background-image: var(--img, none);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin: 0 auto 16px auto;
                border-radius: 12px;
                &::before {
                    content:'';
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 192px;
                    height: 124px;
                    background: inherit;
                    background-size: contain;
                    background-position: center;
                    filter: blur(16px);
                    z-index: -1;
                }
                &.preview {
                    cursor: pointer;
                    &:hover {
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            border-radius: 12px;
                            color: $color-primary;

                            background-image: url(../../assets/zoom-in.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }

            }

            &-price-alternative {
                label {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 150%;
                }
            }

            .resource-list {
                display: flex;
                flex-wrap: wrap;
                gap: 4px 16px;
                &-item {
                    font-size: 12px;
                    line-height: 150%;
                    min-width: 55px;
                }
                &-icon{
                    height: 16px;
                }
            }
        }
    }

    .banner {
        &-header {
            font-size: 28px;
            color: $color-white;
            font-weight: 700;
            line-height: 120%;
        }
        &-text {
            color: $color-white;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
        }
        &-building {
            overflow-x: clip;
            max-width: 1320px;
            width: calc(100vw - 280px);
            display: flex;
            padding: 40px;
            align-items: center;
            gap: 319px;
            align-self: stretch;
            border-radius: 32px;
            background: url(../../assets/market-bg-1.png), linear-gradient(140deg, #4D0CB9 0%, #4E0CBB 100%);
            margin-bottom: 40px;
            position: relative;
            .text-content {
                display: flex;
                flex-direction: column;
                min-width: 319px;
                .redesign-btn {
                    margin-top: 32px;
                    margin-right: auto;
                }
            }
            .banner-img {
                position: absolute;
                left: 758px;
                top: 6px;
                width: 473px;
            }
        }

        &-minerals-get, &-minerals-sell {
            display: flex;
            min-width: 328px;
            max-width: 652px;
            padding: 40px;
            align-items: center;
            flex: 1 0 0;
            border-radius: 32px;
            overflow-x: clip;
            position: relative;
            margin-bottom: 40px;
            .text-content {
                max-width: 362px;
                z-index: 1;
            }
            .banner-img {
                position: absolute;

                z-index: 0;
            }
            .redesign-btn {
                width: fit-content;
                white-space: nowrap;
            }
            .buttons-box {
                margin-top: 32px;
                display: flex;
                gap: 16px;
            }
        }
        &-minerals-get {
            background: url(../../assets/market-bg-2.png), #65B236;
            .banner-img {
                left: 353px;
                top: 0;
                width: 247px;
            }
        }
        &-minerals-sell {
            background: url(../../assets/market-bg-1.png), #EBA111;
            .banner-img {
                width: 323px;
                left: 340px;
                bottom: -6px;
            }
        }
    }
}

.redesign-modal-page {
    .redesign-modal-conteiner {
        &.ad-net, &.ticket {
            width: 574px;
        }
        &.plot-profile {
            width: 550px;
            .values-block {
                border: none;
                flex-wrap: wrap;
                > div {
                    width: 151px;
                }
            }

        }

    }
    .redesign-modal-body {
        .description {
            color: $color-primary;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
        }
        .sybtitle {
            color: $color-primary;
            font-size: 18px;
            font-weight: 700;
            line-height: 120%;
        }
    }
    .marketplace {
        &-sell-info,  &-sell-action {
            .label {
                color: $color-additional-alt;
                font-size: 12px;
                font-weight: 700;
                line-height: 150%;
            }

            .value {
                color: $color-primary;
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
            }
        }
        &-sell-info {
            position: relative;
            z-index: 0;
            border-radius: 24px;
            border: 1px solid  $color-main-dark;
            background: $color-white;
            padding: 16px;
            gap: 16px;

            .title {
                font-size: 21px;
                font-weight: 700;
                line-height: 120%;
                color: $color-primary;
            }
        }
        &-sell-action {
            border-radius: 24px;
            background: $color-main;
            padding: 16px;
            .form-check {
                background-color: $color-white;
                border-radius: 8px;
                padding: 12px 16px 12px 52px !important;
            }
            .form-block {
                background-color: $color-white;
                border-radius: 8px;
                padding: 12px 16px;
            }
            .title {
                font-size: 18px;
                font-weight: 700;
                line-height: 120%;
                color: $color-primary;
            }
        }
        &-main-picturte {
            width: 148px;
            height: 85px;
            background-image: var(--img, none);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: auto 0;
            border-radius: 12px;
            position: relative;
            &::before {
                content:'';
                display: block;
                position: absolute;
                width: 148px;
                height: 85px;
                background: inherit;
                background-size: contain;
                background-position: center;
                filter: blur(8px);
                z-index: -1;
            }
        }
    }
    .resource-list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px 16px;
        &-item {
            font-size: 12px;
            line-height: 150%;
            min-width: 55px;
            font-weight: 400;
        }
        &-icon{
            height: 16px;
        }
    }

}

.mobile {
    .redesign {
        .banner {
            &-minerals-get, &-minerals-sell {
                margin-bottom: 0;
                .buttons-box {
                    flex-direction: column;
                    gap: 12px;
                }
                .redesign-btn {
                    margin-top: 0;
                }
            }
            &-minerals-sell {
                margin-bottom: 24px;
            }
            &-building {
                width: 100%;
                margin-bottom: 24px;
                padding: 32px;
            }
        }
    }
    .redesign-modal-page {
        .marketplace {
            &-main-picturte {
                width: 221.316px;
                height: 128px;
                margin-bottom: 32px;
                &::before {
                    width: 221.316px;
                    height: 128px;
                }
            }
        }
    }
}

.marketplace {
    &__info {

    }
    &-confirm-share-note {
        padding: 16px;
        gap: 16px;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        background: #F3F5FB;

        color: var(--Text-basic, #2E3549);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        p {
            margin-bottom: 0px;
        }
    }
}

.marketplace-page {
    .tikets-wrap .mineral-card {
        >img{
            width: 213px;
        }
        .label {
            font-size: 16px;
            margin-top: 12px;
        }
        .quantity {
            font-size: 12px;
            color: #4B5677;
            margin-bottom: 16px;
        }
        .price {
            font-size: 16px;
            margin-bottom: 16px;
            .sign {
                color: #657757;
                font-weight: 700;
            }
        }
    }
    .my-order {
        color: #7F3901;
    }
    .faw-abey {
        color: #FEAA67;
        box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
    }
    .table-wrap {
        box-shadow: none;
    }
    .remove-btn {
        border: 2px solid #3A324E;
        color: #3A324E;
        &:hover, &.active, &:focus {
            color: #FFFFFF;
        }
    }
    .block-container {
        &.buildings-wrap {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 16px;
            .no-data-wrap {
                grid-column: 1 / 5;
            }
            .pages-naw {
                grid-column-start: 1;
                grid-column-end: 5;
            }
        }
    }
    .inner__container {
        color: #2E3549;
        padding: 24px;
        &.confirm {
            .page-header {
                // width: 559px;
                margin: auto;
            }
            .desctop-inner, .mobile-inner {
                &--wrap {
                    width: 559px;
                    margin: auto;
                    padding: 24px;
                    .confirmed-message {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 8px 8px -4px 8px;

                        img {
                            height: 44px;
                        }
                        h3 {
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 120%;
                            margin-bottom: 18px;
                            margin-top: 16px;
                        }
                        .text {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                        }

                    }
                    .back {
                        font-size: 18px;
                        padding-bottom: 24px;
                        align-items: baseline;
                        display: flex;

                        img {
                            margin-right: 13px;
                        }
                    }
                    .info {
                        background: rgba(255, 255, 255, 0.75);
                        border-radius: 16px;
                        padding: 24px;
                        &.minerals {
                            .block-label {
                                img, span[aria-live="polite"] {
                                    width: 32px;
                                    height: 32px;
                                    margin-right: 8px;
                                }
                            }
                        }
                        .divider {
                            height: 0;
                            border-top: 1px solid #E0EBF6;
                            margin-top: 16px;
                        }
                        .block-label {
                            font-size: 28px;
                            font-weight: 700;
                        }

                        .block-label {
                            font-size: 28px;
                            font-weight: 700;
                        }

                        .block-data {
                            margin-top: 16px;
                            .label, label {
                                font-weight: 600;
                                font-size: 12px;
                                color: #4B5677;
                                margin-bottom: 8px;
                            }
                            .value {
                                font-weight: 700;
                                font-size: 16px;
                            }
                        }

                        .block-addition {

                            font-size: 16px;
                            .label, label {
                                font-weight: 400;
                                color: #4B5677;
                                margin-right: 8px;
                            }
                            .value {
                                font-weight: 700;
                            }
                        }
                        .block-total {
                            display: flex;
                            margin-top: 16px;
                            align-items: baseline;
                            .label {
                                font-weight: 400;
                                margin-right: 8px;
                                font-size: 16px;
                            }
                            .value {
                                font-weight: 700;
                                font-size: 16px;
                            }
                        }
                    }
                    .info-alert {
                        font-weight: 400;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                        border-radius: 16px;
                        padding: 24px;
                        .alert {
                            background: transparent;
                        }

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                    .actions {
                        button {
                            font-size: 16px;
                            padding: 12px 24px;
                        }
                        .cancel-btn {
                            color: #3A324E;
                            border: 1px solid #3A324E;
                            margin-right: 16px;
                        }
                    }
                }
            }
        }
    }

    .page-header {
        text-align: center;
        h4 {
            margin: 24px auto 24px auto;
            font-size: 38px;
            color: #2E3549;
        }
        &-no {
            margin-top: 24px;
        }
    }
    .desctop-inner {
        &--wrap {
            margin: 0 auto;
        }
    }
    .desctop-inner, .mobile-inner {
        &--wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px;
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            border-radius: 24px;
            .page-name {
                font-weight: 700;
                font-size: 28px;
                line-height: 120%;
                margin: 16px 0;
            }
            h3 {
                &.placed-orders {
                    font-weight: 700;
                    font-size: 21px;
                    text-align: center;
                    margin: 32px 0 16px 0;
                    color: #2E3549;

                }
            }
            .my-minerals {
                display: flex;
                flex-direction: column;
                .mineral-card {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-right: 16px;
                    padding: 16px ;
                    max-width: 200px;
                    img, .mineral-img, span[aria-live="polite"] {
                        width: 120px;
                        height: 120px;
                        margin: 0 auto;
                    }
                    &--devider {
                        border: 1px solid #E0EBF6;
                        width: 0;
                        height: calc(100% - 24px);
                        margin-top: 24px;
                    }
                    .label {
                        margin: 24px auto 0 auto;
                        font-size: 18px;
                        &.wood {
                            font-size: 28px;
                        }
                    }
                    .quantity {
                        font-weight: 400;
                        font-size: 16px;
                        margin: 4px auto 24px auto;
                        color: #4B5677;
                        &.wood {
                            margin-bottom: 16px;
                        }
                    }

                }
                .mineral-card:last-child {
                    margin-right: 0 !important;
                }
            }

            .navbar-expand {
                padding: 0 0 24px 0;
            }

            .table-wrap {
                background: transparent !important;
                border-radius: 0;
                padding: 0;
            }

            .hr {
                border-top: 1px solid  white;
                height: 0;
                margin-bottom: 24px;
            }


            .marketplace-nav {

                &-block {
                    padding: 24px;
                    gap: 24px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 16px;
                    width: 240px;
                    min-width: 240px;
                    height: fit-content;
                    margin-right: 16px;
                    top: 0;
                    position: sticky;
                    .marketplace-nav {
                        padding: 0 !important;
                        margin: 8px 0 0 0 !important;
                        margin: 0 8px 0 0;
                        font-size: 16px;
                        font-weight: 400;
                        color: #009DC4;
                        &.active {
                            color: #994401;
                            font-weight: 700;
                        }
                        &:hover {
                            color: #FE7202;
                        }
                    }
                }
                &-title {
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 120%;
                    color: #2E3549;
                }
                .soon {
                    background: #FE7202;
                    border-radius: 4px;
                    padding: 2px 4px;
                    font-weight: 700;
                    font-size: 9px;
                    display: inline;
                    vertical-align: middle;
                    margin-left: 4px;
                    color: #FFFFFF;
                }
            }


            .mineral-table-buy {
                .items-filters {
                    margin: 16px 0;
                    .item {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 120%;
                        padding: 12px 24px;
                        white-space: nowrap;
                        cursor: pointer;
                        &.active, &:hover {
                            background-color: #FFFFFF;
                            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                            border-radius: 12px;
                            color: #994401;
                        }
                        &:hover {
                            color: #fe7202;
                        }
                    }
                }
                .tbody {
                    font-size: 18px;
                    padding: 16px 32px 16px 16px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                    img, span[aria-live="polite"] {
                        width: 120px;
                        height: 120px;
                        margin-right: 16px;
                    }
                    .mobile-table--cell-label {
                        margin-top: 12px;
                        margin-bottom: 4px;
                        padding: 0;
                    }
                    .mobile-table--cell-value {
                        font-weight: 400;
                    }
                    .material-name {
                        font-size: 21px;
                    }
                    .total-abey {
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 120%;
                        margin-right: 32px;
                        .faw-abey {
                            font-size: 24px;
                        }
                    }
                    .faw-abey {
                        font-size: 18px;
                    }
                    .nft-btn {
                        width: 82px;
                    }
                }
            }



            .filter {
                padding: 0 0 24px 0 !important;
                .f-group {
                    width: 125px;
                    margin-right: 16px;
                }
            }
        }
    }

    &.mobile {
        .inner__container {
            padding: 16px;
            &.confirm {
                overflow-x: hidden;
                .desctop-inner, .mobile-inner {
                    &--wrap {
                        width: initial;
                        padding: 16px;
                        background: rgba(255, 255, 255, 0.6);
                        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                        .info {
                            padding: 16px;
                            .block-label {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
            .mineral-table-buy {
                &:not(.on-buy) {
                    background: rgba(255, 255, 255, 0.6);
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 24px;
                    padding: 8px;
                }

                .items-filters {
                    overflow-x: auto;
                    margin: 0 0 16px 0;
                }
                .page-name {
                    margin: 16px 8px;
                    font-size: 21px;
                }
                .faw-abey {
                    color: #FEAA67;
                    font-size: 24px;
                }
                &.buildings {
                    background: none;
                    box-shadow: none;
                }
            }
            .marketplace-nav {
                font-size: 16px;
                white-space: nowrap;
            }
            .marketplace-nav:last-child {
                margin: 0 !important;
            }
            .links-nav {
                flex: 1;
                margin-right: 8px;
                button {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 700;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                    text-align: start;
                    display: flex;
                }
                button::after {
                    margin-left: auto;
                    margin: auto 0 auto auto;
                }
                .dropdown-item {
                    display: flex;
                    align-items: center;
                    .soon {
                        background: #FE7202;
                        border-radius: 4px;
                        padding: 2px 4px;
                        font-weight: 700;
                        font-size: 9px;
                        display: inline;
                        vertical-align: middle;
                        margin-left: auto;
                        color: #FFFFFF;
                    }
                }
            }
            .mobile-table--header-switch--button {
                padding: 9px;
                border-radius: 12px;
                &.first {
                    margin: auto 0 auto 8px;
                }
                &.last {
                    margin: auto 0;
                }
            }
            .page-header {
                h4 {
                    margin: 16px auto 24px auto;
                    font-size: 28px;
                }
                &-no {
                    margin-top: 16px;
                }
            }
            .desctop-inner, .mobile-inner {
                &--wrap {
                    padding: 0;
                    background: transparent;
                    box-shadow: none;
                    .navbar-expand {
                        padding: 0 0 16px 0;
                    }
                    .my-minerals {
                        .list {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 8px;
                            .mineral-card {
                                margin: 0;
                            }
                        }
                        &.wood {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            .mineral-card {
                                margin: 0;
                            }
                            .quantity {
                                font-size: 16px;
                            }
                            .mineral-card--devider {
                                height: auto;
                            }
                            .label {
                                font-size: 21px;
                            }
                            img {
                                width: 84px;
                                height: auto;
                            }
                        }

                        .table-wrap {
                            overflow-x: auto;
                            &.in-grid{
                                grid-column-start: 1;
                                grid-column-end: 3;
                                overflow: auto;
                            }
                        }
                    }

                }
            }
            .block-container {
                padding: 8px !important;
                background: rgba(255, 255, 255, 0.6);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                border-radius: 24px;
                &.buildings-wrap {
                    width: 100%;
                    padding: 0 !important;
                    display: flex;
                    flex-direction: column;
                    background: none;
                    box-shadow: none;
                    gap: 8px;
                    .market-buildings--table-item--img-thumb {
                        padding: 0;
                        width: 100%;
                        height: 289px;
                    }
                    .faw-abey {
                        font-size: 16px;
                    }
                }
                .my-buildings {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                    border-radius: 16px;
                    .size-label {
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 120%;
                    }
                    .name-label {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                    }
                }
                &.my-minerals {
                    padding: 8px 16px !important;
                }

            }
            .mobile-table--item{
                backdrop-filter: none;
                img {
                    &.buliding {
                        width: 48px;
                        height: 48px;
                    }
                }
                .market-buildings--resource-list--item{
                    font-weight: 700;
                }
            }
            .table-wrap {
                overflow: auto;
            }
            .mineral-table-buy {
                .cell-7, .cell-8{
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
                .cell-5 {
                    grid-column-start: initial;
                    grid-column-end: initial;
                }
                .mobile-table--cell-value {
                    img, span[aria-live="polite"] {
                        width: 60px;
                        height: 60px;
                        display: flex;
                        margin-right: 12px;
                    }

                    .important {
                        font-size: 21px;
                    }
                }
                .gallery {
                    max-width: 100vw;
                    max-height: 55vh;
                }
            }
        }
    }
    .flex-center {
        display: flex;
        align-items: center;
    }
}

.marketplase-desctop-dialog, .marketplase-mobile-dialog {
    .modal-dialog {
        font-family: 'Nunito';
        font-style: normal;
        .modal-content {

            border-radius: 24px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        }
        .modal-body {
            .confirmed-message {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 8px 8px -4px 8px;

                img {
                    height: 44px;
                }
                h3 {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 120%;
                    margin-bottom: 18px;
                    margin-top: 16px;
                }
                .text {
                    padding: 16px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    background-color: #FFFFFF;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                    text-align: center;
                    width: 100%;
                }

            }
        }
    }
}

.aw-break-word {
    word-break: break-word;
}

.market-buildings {
    &--placed-info {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px solid #F3F5FB;
        margin-bottom: 8px;
        height: 37px;
        padding: 4px 12px;
        &--label {
            color: #4D0CB9;
            font-family: Nunito;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2;
            cursor: pointer;
            &:hover {
                color: #7929FF;
            }
        }
        .remove {
            width: 29px;
            height: 29px;
            margin-top: -1px;
            margin-right: -8px;
            font-size: 1rem;
            border-radius: 4px !important;
            border: 1px solid;
            .faw-cross {
                margin-top: 1px;
                margin-left: 1px;
            }
        }
    }
    &--slide-button {
        @extend button, .clear;
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 32px;

        border: 2px solid #FFFFFF;
        border-radius: 16px;

        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 120%;

        color: #FFFFFF;
        &:hover {
            color: #FE7202;
            background-color: transparent;
            border: 2px solid #FE7202;
        }
        &:active, &:focus {
            color: #FE7202;
            border: 2px solid #FE7202 !important;
            background-color: transparent;
            box-shadow: unset;
        }
        &[disabled] {
            opacity: .6;
        }
    }
    &--table-item {
        &--img {
            &-view {
                max-height: 45vw;
                max-width: 90vw;
                width: 100vw;
                height: 100vw ;
                //max-height: calc(80vh - 100px);
                // min-height: 512px;
                background-color: #E0E7CD;

                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 62.382px;
                background-image: var(--img, none);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                border: 32px solid transparent;
            }
            &-thumb {
                cursor: pointer;
                width: 213.5px;
                height: 213px;

                background: #E0E7CD;
                border: 1px solid #FFFFFF;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                padding: 4px;
                position: relative;
                img {
                    margin: 0 !important;
                    width: 100%;
                    max-width: 100%;
                    height: auto !important;
                }
                &:hover {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(35, 30, 47, 0.85);
                        background-image: url(../../assets/zoom-in.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 50%;
                    }
                }
                &.img-left-in-table {
                    &:hover::after {
                        background-image: url(../../assets/zoom-in-table.svg);
                    }
                }
            }
        }
        &--name {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            color: #2E3549;
            &-container {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }
        &--price {
           font-size: 12px;
            &-container {
                display: inline-flex;
                flex-wrap: wrap;
                margin-bottom: 12px;


            }
        }
        &--button {

        }
        &--size {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #2E3549;
        }
        &--type {
            &.exchange {
                color: #5BBC43 !important;
            }
            &.upgrade {
                color: #7A85EB !important;
            }
            &.downgrade {
                color: #994401 !important;
            }
        }
    }
    &--switch-label {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        color: #2E3549;
    }
    &--resource-list {
        gap: 16px;
        flex-wrap: wrap;
        display: inline-flex;
        margin-left: auto;
        &--item {
            display: flex;
            align-items: center;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #2E3549;
            &.color-light {
                color: #fff;
            }
        }
        &--icon {
            width: auto;
            height: 24px;
        }
    }
    &--exchange-building {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 24px;
        isolation: isolate;
        position: relative;
        background: #F7F7F7;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 24px;
        max-width: 364px;
        height: min-content;
        .form-check {
            & + .form-check {
                margin-top: 0px !important;
            }
        }
        &--page {
            .page-header {
                max-width: 1318px;
                margin: 0 auto;
            }
            .market-buildings--table-item--img-thumb {
                width: 48px;
                height: 48px;
            }
            .market-buildings--table-item--name-container {
                flex-direction: row;
            }
            .load-more {

                &--wrapper {
                    padding: 0px !important;
                }
            }
            .confirmation-container {
                overflow: auto;
                form {
                    @media (max-height: 617px) {
                        margin-top: 200px;
                    }
                }
            }
            .congratulations {
                p {
                    .mobile & {
                        padding-bottom: 16px;
                        border-bottom: 1px solid #ffffff;
                        margin: 0;
                    }
                }
                .nft-btn {
                    .mobile &  {
                        padding: 0px;
                        font-size: 1rem;
                        margin-top: 16px;
                        line-height: 1;
                        height: unset;
                    }
                }
            }
            &.mobile-table--container {
                .faw-arrow_left {
                    font-size: 14px;
                }
                #buildingsSizeFilter {
                    height: 48px;
                }
            }
        }
        &--mobile {


            .mobile-table {
                &--item {
                    grid-template-areas: "NAME NAME" "PRICE PRICE" "FEE SIZE" "BUTTON BUTTON";
                    padding: 8px 8px 12px 8px;
                }
                &--cell {
                    &-label {
                        line-height: 1;
                    }
                    &-value, .important  {
                        font-size: 1.125rem;
                    }
                    &.name {
                        grid-area: NAME;
                        .market-buildings--owned-list--item-img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                    &.price {
                        grid-area: PRICE;
                    }
                    &.fee {
                        grid-area: FEE;
                    }
                    &.size {
                        grid-area: SIZE;
                    }
                    &.exchange {
                        grid-area: BUTTON;
                        padding: 0px;
                        background-color: transparent;
                        .nft-btn {
                            height: 48px;
                            font-size: 1rem;
                            color: #ffffff;
                            font-weight: 600;
                            width: calc(100% - 8px);
                            margin-left: 4px;
                        }
                    }
                    .market-buildings--owned-list--item-img {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            .load-more {
                margin-top: 16px !important;
            }
            &.history {
                .mobile-table {
                    &--item {
                        grid-template-areas: "NAME NAME" "TYPE TYPE" "TARGET TARGET" "PRICE FEE" "DATE DATE";
                        padding: 8px 8px 12px 8px;
                    }
                    &--cell {
                        &-label {
                            line-height: 1;
                        }
                        &-value, .important  {
                            font-size: 1.125rem;
                            &.exchange {
                                color: #5BBC43;
                            }
                            &.upgrade {
                                color: #7A85EB;
                            }
                        }
                        &.name {
                            grid-area: NAME;
                            .market-buildings--owned-list--item-img {
                                width: 48px;
                                height: 48px;
                            }
                        }
                        &.type {
                            grid-area: TYPE;
                        }
                        &.target {
                            grid-area: TARGET;
                            .market-buildings--owned-list--item-img {
                                width: 48px;
                                height: 48px;
                            }
                        }
                        &.price {
                            grid-area: PRICE;
                        }
                        &.fee {
                            grid-area: FEE;
                        }
                        &.date {
                            grid-area: DATE;
                        }

                        .market-buildings--owned-list--item-img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }
        }
        &--back-link {
            .mobile & {
                padding: 8px;
                background: #fff;
                border-radius: 24px;
                font-size: 1.3125rem;
                line-height: 1;
                background: rgba(255, 255, 255, 0.6);
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
                backdrop-filter: blur(16px);

                border-radius: 24px;
            }
        }
        &--warning {
            gap: unset;
            padding: 32px;
            max-width: 414px;
            &-container {
                width: calc(100% - 32px);
            }
            .faw-cross_round {
                font-size: 2.75rem;
                margin-top: 2px;
            }
            h3 {
                font-size: 2.375rem;
                margin-top: 18px;
                margin-bottom: 24px !important;
            }
            .alert {
                color: #2E3549;
                margin-bottom: 24px !important;
                p {
                    margin-bottom: 0px !important;
                }
            }
            .react-loading-skeleton {
                border-radius: 12px;
            }
        }
        &--items {
            display: flex;
            align-items: center;
            gap: 13px;
            margin: -8px auto;
            .faw-arrow_left {
                display: block;
                transform: rotate(180deg);
            }
        }
        &--title {
            color: #231E2F;
            font-size: 1.75rem;
            width: 100%;
            text-align: left;
            line-height: 1.1;
        }
        &--img {
            width: 128px;
            height: 128px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            .congratulations & {
                width: 168px;
                height: 168px;
            }
            &.green {
                background-color: #E0E7CD;
                background: #E0E7CD;
            }
            &.purple {
                background-color: #FACFEE;
                background: #FACFEE;
            }
            &-title {
                color: #2E3549;
                font-size: 1rem;
                font-weight: 400;
                text-align: center;
                margin-top: 8px;
                line-height: 1;
            }
            img {
                width: 83%;
            }
        }
        &--img-wrapper {
            padding: 8px;
            background-color: #ffffff;
            border-radius: 16px;
            .congratulations & {
                padding: 0px;
            }
            & + .plot-profile--wallet-header {
                margin-top: 32px;
            }
            .icon--wrapper {
                position: absolute;
                background: #fff;
                width: fit-content;
                height: fit-content;
                border-radius: 16px;
                border: 1px solid #fff;
                right: 8px;
                bottom: 8px;
                i {
                    font-size: 2rem;
                    display: block;
                }
                &.upgrade {
                    background: #7A85EB;
                    width: 34px;
                    height: 34px;
                    i {
                        display: block;
                        color: white;
                        font-size: 1rem;
                    }
                }
            }
        }
        .alert {
            margin-bottom: 0px;
            border-radius: 16px;
            hr {
                width: 1px;
                height: 40px;
            }
            p {
                color: #4B5677;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
    &--buy-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 64px 32px 32px;
        gap: 24px;
        isolation: isolate;

        position: relative;
        background: #F7F7F7;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 24px;
        margin-top: 36px;
        min-width: 354px;
        height: min-content;
        @media (max-height: 794px) {
            margin-top: 120px;
        }
        &--img {
            width: 80px;
            height: 80px;
            background-color: #E0E7CD;

            background: #E0E7CD;
            border: 3px solid #FFFFFF;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.17);
            border-radius: 16px;

            background-image: var(--img, none);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &--title {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;

            text-align: center;

            color: #231E2F;
        }
        &--devider {
            width: 100%;
            height: 0px;
            border: 1px solid #FFFFFF;
        }
        &--action {
            width: 100%;
            display: flex;
            gap: 16px;
            > * {
                flex: 1;
            }
        }
        &--pay {
            cursor: pointer;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;
            gap: 12px;

            background-color: #FFFFFF;

            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            &.disabled {
                // cursor: default;
                pointer-events: none;
                background-color: rgba(255, 255, 255, 0.75);
                box-shadow: none;
                .pd-target {
                    opacity: .5;
                }
                .pd-alert {
                    color: #F44949 !important;
                }
            }
            &-marker {
                width: 24px;
                height: 24px;
                border: 2px solid #3A324E;
                border-radius: 24px;
                padding: 3px;
                &.selected {
                    &::after {
                        content: '';
                        display: block;
                        background: #FE7202;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
            &-list {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            &-item {
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;

                color: #2E3549;
            }
            &-img {
                width: 24px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }

        }
        .alert-staking {
            border-radius: 16px;
            hr {
                height: 94px;
                border-left: 1px solid #E0EBF6;
                border-right: 1px solid #E0EBF6;
                border-right-color: rgb(224, 235, 246);
            }
            p {
                font-size: 1rem;
                color: #4B5677;
                font-weight: 400;
            }
        }
        &--prices {
            display: grid;
            gap: 8px;
            //grid-template-columns: calc(100% - 16px);
            width: 100%;
            .mobile & {
                width: 100%;
                grid-template-columns: 100%;
            }
        }
    }
    &--congratulations {
        width: 100%;
        max-width: 523px;
        display: flex;
        flex-direction: column;
        padding: 32px;
        gap: 16px;
        align-items: center;

        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        &-title {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;

            color: #2C3653;
        }

        &-message {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            white-space: pre-wrap;

            color: #2E3549;
        }
        &-devider {
            width: 100%;
            height: 0px;
            border-top: 1px solid #FFFFFF;
        }
        &-goto {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #009DC4;
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
                color: #327DA0;
            }
            &:active {
                color: #327DA0;
            }
            &:focus:not(:active) {
                color: #327DA0;
                text-decoration-line: underline;
            }
        }
    }
    &--owned {
        &-list {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 16px;
            row-gap: 16px;
            &--item {
                display: flex;
                padding: 24px;
                gap: 24px;

                background: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 16px;

                &-img {
                    cursor: pointer;
                    overflow: hidden;
                    position: relative;
                    width: 100px;
                    height: 100px;
                    background-color: #E0E7CD;
                    border: 1px solid #FFFFFF;
                    border-radius: 8px;

                    background-image: var(--img, none);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.mobile {
                        width: 72px;
                        height: 72px;
                    }
                    &:hover {
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-color: rgba(35, 30, 47, 0.85);
                            background-image: url(../../assets/zoom-in.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 50%;
                        }
                    }
                }
                &-devider {
                    width: 0;
                    border: 1px solid #E0EBF6;
                    height: 100%;
                }
                &-body {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 16px;
                }
                &-header {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;
                }
                &-title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 12px;

                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 120%;

                    color: #2E3549;
                }
                &-subtitle {
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #4B5677;
                }
                &-action {
                    width: 100%;
                    &--place {
                        display: block;
                        text-align: center;
                        width: 100%;
                        padding: 6px 16px;
                        border-radius: 8px;
                        background: transparent;
                        border: 2px solid #51466D;

                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 150%;
                        color: #51466D;
                        &.disabled, &:disabled {
                            opacity: .4;
                            pointer-events: none;
                        }
                        &:hover {
                          background: #3A324E;
                          color: #FFFFFF;
                        }
                        &:active {
                          color: #FFFFFF;
                          background: #231E2F;
                          border: 2px solid #231E2F;
                        }
                        &:focus:not(:active) {
                            border: 2px solid transparent;
                            box-shadow: 0 0 0 4px #3A324E;
                        }
                    }
                    &--solid {
                        display: block;
                        text-align: center;
                        width: 100%;
                        padding: 8px 16px;
                        border-radius: 8px;

                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 150%;

                        color: #FFFFFF !important;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #51466D !important;
                        background-color: #51466D;
                        color: #FFFFFF;
                        transition: background-color .2s;

                        &.disabled, &:disabled {
                            opacity: .4;
                            pointer-events: none;
                        }
                        &:hover, &:active, &.active, &:focus {
                            background-color: #3A324E;
                        }
                        &:focus {
                          background-color: #3A324E;
                          outline: 2px solid #51466D !important;
                        }
                    }
                    &--remove {
                        width: 100%;
                        padding: 6px 16px;
                        border-radius: 8px;
                        background: transparent;
                        border: 2px solid #F44949;

                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 150%;
                        color: #F44949;
                        &.disabled, &:disabled {
                            opacity: .4;
                            pointer-events: none;
                        }
                        &:hover {
                          background: #F44949;
                          color: #FFFFFF;
                        }
                        &:active {
                          color: #FFFFFF;
                          background: #F44949;
                          border: 2px solid #F44949;
                        }
                        &:focus:not(:active) {
                            border: 2px solid transparent;
                            box-shadow: 0 0 0 4px #F44949;
                        }
                    }
                    &--exchange {
                        @extend.btn;
                        @extend .btn-outline-info;
                        width: 100%;
                        border-radius: 8px;
                        font-size: 0.75rem;
                        padding: 6px 16px;
                    }
                }
                &-locator {
                    .faw-location_stroke {
                        color: #51466D;
                        font-size: 1.375rem;
                    }
                    &:hover {
                        .faw-location_stroke {
                            color: #FE7202 !important;
                        }
                    }
                }

            }
        }
    }
    &--placement-item {
        flex: 1;
        user-select: none;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
        gap: 8px;

        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.07));
        &--img {
            width: 64px;
            height: 64px;

            border: 1px solid rgba(255, 255, 255, 0.9);
            border-radius: 12px;

            background-color: #E0E7CD;
            background-image: var(--img, none);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &--body {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;
        }
        &--title {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            white-space: nowrap;
        }
        &--subtitle {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
            color: #4B5677;
        }
    }
    &--build-mirror {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 48px;
        &--title {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 120%;

            color: #FFFFFF;
            margin-bottom: 42px;
        }
        &--item {
            cursor: pointer;
            position: relative;
            width: 256px;
            height: 256px;

            background: #E0E7CD;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 24px;

            background-image: var(--img, none);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &.is-mirrored {
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
            &.active::after {
                content: '';
                display: block;
                box-sizing: border-box;

                position: absolute;
                left: -4.5%;
                right: -4.5%;
                top: -4.5%;
                bottom: -4.5%;

                border: 4px solid #FEAB68;
                border-radius: 36px;
            }
        }
    }
    &--filter {
        min-width: 120px;
        background-color: transparent !important;
    }
    &--arrows {
        &[disabled] {
            opacity: .4;
            pointer-events: none;
        }
        i {
            color: #fff;
            font-size: 32px;
        }
        &:hover {
            i {
                color: #FE7202;
            }
        }
    }
}
.fee-color {
    color: #F44949 !important;
}

.v-line {
    border-left: 1px solid #E0EBF6;
    &.h-60px {
        min-height: 60px;
        display: inline-flex;
        align-items: center;
    }
    &.d-12px {
        margin-left: 12px;
        padding-left: 12px;
    }
    &.d-16px {
        margin-left: 16px;
        padding-left: 16px;
    }
}
.mh-48px {
    min-height: 48px;
}
.flex-flow-row {
    flex-flow: row;
}
table.mineral-table-buy {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 16px;
    th {
        white-space: nowrap;
        img {
            margin-left: 8px;
        }
    }
    tbody {
        tr {
            td {
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: 16px;
            }
            td:last-child {
                border-bottom-right-radius: 16px;
            }
        }
    }

}
.tikets-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .mineral-card {
        max-width: 241px;
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        padding: 24px;
        flex-direction: column;
        display: flex;
        align-items: center;
        &.tiket {
            max-width: max-content;
            padding: 16px;
        }
        >img {
            width: 126px;
        }
        .label {
            font-weight: 700;
            font-size: 21px;
            line-height: 120%;
            margin-top: 16px;
        }
        .quantity {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-top: 4px;
            margin-bottom: 24px;
        }
    }
}

.mobile-inner--wrap{
    .tikets-wrap {
        margin-top: 24px;
        display: block;

        padding: 0;
        .mineral-card {
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}
.box-white {
    background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);

}

.image-gallery-slide {
    .market-buildings--resource-list {
        &--item {
            font-size: 18px;
        }
    }
    .resource-list {
        gap: 12px;
        font-weight: 400;
        font-size: 18px;
        color: $color-white;
        display: flex;
        img {
            width: 16px;
            height: 16px;
        }
        .faw-abey, .faw-stickers {
            font-size: 14px;
            display: inline;
        }
        .faw-stickers {
            color: #009DC4;
        }
    }
    .market-buildings--table-item--price-container>:not(:last-of-type):after {
        border: 1px solid #e0ebf6;
        content: "";
        display: block;
        height: 100%;
        width: 0;
    }
    .market-buildings--table-item--price-container {
        gap: 16px;
        margin-bottom: 0;
    }
}

.confirm-activity {
    &-name {
        padding: 24px;
        h3 {
            font-size: 28px;
            color: #2E3549;
        }
    }
    &-info {
        p {
            color: #4B5677;
        }
    }
}

.building-exchange {
    .aw-673 .table-wrap table thead th {
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        padding: 8px 16px !important;
    }
    &-color-01 {
        background-color: #E0E7CD;
    }
    &-color-02 {
        background-color: #CEE7CD;
    }
    &-color-03 {
        background-color: #CDDEE7;
    }
    &-color-04 {
        background-color: #CECDE7;
    }
    &-color-05 {
        background-color: #E7CDDB;
    }
    .img-over, .btn-check-container {
        position: absolute;
        &.left {
            top: 6px;
            left: 6px;
        }
        &.right {
            top: 6px;
            right: 6px;
        }
    }

    .market-buildings--table-item--img-thumb {
        &:hover::after {
            content: none;
        }
    }
    .market-buildings--table-item--name-container {
            background: #FFFFFF;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            padding: 8px;

        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            margin-top: 8px;
        }

        .btn-check-container {
            display: none;
            width: 28px;
            height: 28px;
            background: #51466D;
            border: 2px solid #FFFFFF;
            border-radius: 8px;
            color:  #FFFFFF;
            cursor: pointer;
            .faw-empty {
                width: 100%;
                height: 100%;
                background: #FFFF;
                border: 2px solid #51466D;
                border-radius: 4px;
            }

        }
    }
    .card {
        background: #F7F7F7;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
    }
    h4 {
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        color: #231E2F;
        display: flex;
        .faw-arrow_left {
            font-size: 14px;
            align-self: center;
            cursor: pointer;
        }
    }
    .action-tabs {
        > div {
            cursor: pointer;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: #2E3549;
            padding: 12px 24px;
            text-align: center;
            &:hover {
                color: #994401;
            }
            &.active {
                background: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 12px;
                color: #994401;
            }
            &.inactive {
                color: #B5BDCA;
                pointer-events: none;
            }
        }
    }
    .selector {
        padding: 8px;

        background: #FFFFFF;
        border-radius: 24px;
        .downgrade-options {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #009DC4;
            text-align: center;
            cursor: pointer;
        }
        >.d-flex{
            gap: 13px;
            >.market-buildings--table-item--name-container {
                .market-buildings--table-item--img-thumb {
                    width: 162px;
                    height: 162px;
                    cursor: initial;
                }
            }
        }
        .faw-arrow_right {
            font-size: 12px;
        }
        .buildings-list {
            background: #FFFFFF;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            width: 178px;
            height: 210px;
            overflow-y: auto;
            .market-buildings--table-item--img-thumb {
                width: 42px;
                height: 42px;
                border-radius: 8px;
                cursor: initial;
            }
            .label {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                align-self: center;

            }
            .faw-cross {
                color: #009DC4;
                font-size: 8px;
                align-self: center;
                cursor: pointer;
            }

        }
        .empty-building {
            background: #F7F7F7;
            border: 1px dashed #B5BDCA;
            border-radius: 16px;
            height: 208px;
            width: 178px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .link {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #009DC4;

            }
        }
    }
    .price-block {
        display: flex;
        flex-direction: row;
        padding: 8px 16px;
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        .label {
            color: #231E2F;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
        }
        .value {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            display: flex;
            align-items: baseline;
        }
        .faw-abey {
            font-size: 16px;
            color: #feaa67;
            margin-right: 6px;
        }
    }
    .confirm-block {
        padding: 8px 16px;
        display: flex;
        gap: 12px;
        background: #FFFFFF;
        border-radius: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #4B5677;
        align-items: center;
        .faw-exclamation_point_round {
            color: #F0A920;
            font-size: 22px;
        }
    }
    .actions-block {
        justify-content: flex-end;
        button {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            padding: 10px 24px;
            border-radius: 12px;
            &.btn-outline-cancel {
                border: 1px solid #3A324E;
                color: #3A324E;
            }
        }
    }
    .items-filters {
        margin: 16px 0;
        .item {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            padding: 12px 24px;
            white-space: nowrap;
            cursor: pointer;
            &.active, &:hover {
                background-color: #FFFFFF;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
                border-radius: 12px;
                color: #994401;
            }
            &:hover {
                color: #fe7202;
            }
        }
    }
    .items-combinations {
        .label {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2E3549;
            white-space:nowrap;
        }
        .container {
            gap: 2px;
            border: 2px solid #FFFFFF;
            background-color: #FFFFFF;
            padding: 0;
            border-radius: 8px;
            .item {
                font-weight: 700;
                font-size: 12px;
                line-height: 150%;
                padding: 2px 22px;
                color: #2E3549;
                background: #F7F7F7;
                &.active {
                    background: linear-gradient(180deg, #FF943E 0%, #FE7202 100%);
                    color: #FFFFFF;
                }
            }
            .item:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            .item:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
    .content-wrapper {
        display: flex;
        flex: 1;
        min-height: 0px;
    }

    .buildings-wrap {
        grid-gap: 16px;
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(5,1fr);
        grid-auto-rows: min-content;
        overflow-y: auto;
        box-shadow: none;
        background: #FFFFFF;
        .loading-ref {
            grid-column: 1 / 6;
            height: 1px;
        }
        .disabled-wrap {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #FFFFFF;
            opacity: 0.5;
        }
        >div {
            box-shadow: none;
        }
        .market-buildings--table-item--img-thumb {
            width: 158px;
            height: 160px;
            cursor: initial;
        }

        .tamplates-list {
            display: none;
            position: absolute;
            bottom: 4px;
            padding: 4px;
            gap: 8px;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07), inset 0px 0px 4px #FFFFFF;
            backdrop-filter: blur(16px);
            border-radius: 50px;
            >div{
                margin: 0;
                background: #FFFFFF;
                border: 2px solid #51466D;
                font-weight: 700;
                font-size: 12px;
                line-height: 150%;
                color: #51466D;
                width: 18px;
                height: 18px;
                border-radius: 18px;
                text-align: center;
                cursor: pointer;
                &.active, &:hover {
                    border-color: #FE7202;
                }
                >div {
                    margin-top: -2px;
                }
            }
        }
        .market-buildings--table-item--name:hover {
            .tamplates-list {
                display: flex;
            }
            .btn-check-container {
                display: flex;
            }
        }
    }
    .congratulations {
        .main-img {
            border: 2px solid #FFFFFF;
            width: 134px;
            height: 134px;
            cursor: initial;
            border-radius: 24px;
            .icon--wrapper {
                border: 2px solid rgba(255, 255, 255, 0.6);
                border-radius: 18px;
                &.upgrade, &.downgrade {
                    color: #FFFFFF;
                    padding: 8px 12px;

                    i{font-size: 16px;}
                }
                &.upgrade {
                    background-color: #7A85EB;
                }
                &.downgrade {
                    background-color: #009DC4;
                    i{rotate: 180deg;}
                }
                &.exchange {
                    height: 36px;
                    i{font-size: 32px;}
                }
            }
        }
        .new-templates {
            margin-top: -24px;
            .market-buildings--table-item--img-thumb {
                width: 48px;
                height: 48px;
                border: 2px solid #FFFFFF;
            }
        }
        .plot-profile--wallet-header {
            margin-top: 32px;
        }
        .message {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #2E3549;
        }
    }
}

.mobile {
    .building-exchange {
        .action-tabs {
            overflow-x: auto;
        }
        .buildings-list {
            .label {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #2E3549;
            }
        }
        .selector {
            .empty-building {
                width: 100%;
                height: initial;
                padding: 17px 0;
            }
            .faw-arrow_down {
                margin-top: 13px;
                margin-bottom: 13px;
            }
        }
        .buildings-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            .market-buildings--table-item--img-thumb  {
                width: 100%;
                height: 260px;

            }
            .market-buildings--table-item--name {
                .tamplates-list, .btn-check-container {
                    display: flex;
                }
                .tamplates-list {
                    padding: 8px;
                    bottom: 8px;
                    >div {
                        font-size: 20px;
                        width: 32px;
                        height: 32px;
                        border-width: 3px;
                    }
                    >div {
                        margin-top: -1px;
                    }
                }
            }
        }
        .items-filters, .items-combinations .container {
            overflow-x: auto;
        }
        .items-combinations .container {
            .item {
                flex: 1;
                text-align: center;
            }
        }

        .aw-673 .table-wrap {
            overflow-x: auto;
            table {
                td, th {
                    white-space: nowrap;
                }
            }
        }

        .card {
            overflow-y: auto;
            max-height: calc(100% - 32px);
        }
    }
}



.market-buildings--table-item--img-thumb {
    &.no-hover-view::after {
        content: none !important;
    }
}

.mint-building-card {
    width: 523px;
    background: transparent !important;
    box-sizing: unset;
    backdrop-filter: none !important;
    border: none !important;
    max-height: 100%;
    box-shadow: none !important;
    .market-buildings--table-item--img-view {
        width: 100%;
        height: 213px;
        border-radius: 16px;
    }
    .aw-form--control:disabled {
        background-color: #e9ecef;
    }
}

.sort-icon {
    &.faw-sort-two {
        margin-left: 4px;
        font-size: 14px;
        &.sort-asc, &.sort-desc {
            background-clip: text !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent;
        }
        &.sort-asc {
            background: linear-gradient(to right, $color-accent 0%, $color-accent 50%, $color-additional-alt 50%, $color-additional-alt 100%);
        }
        &.sort-desc {
            background: linear-gradient(to right, $color-additional-alt 0%, $color-additional-alt 50%, $color-accent 50%, $color-accent 100%);
        }
    }
}
