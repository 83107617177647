.assets-history, .usdt-history {
    &.staff-body {
        .page-header {
            h4 {
                margin: 0px auto 24px auto;
            }
        }
        .history__list {
            width: calc(100% - 16px);
            margin: 32px auto 0px;
        }
    }
    .page-header {
        h4 {
            margin: 48px auto 24px auto;
            font-size: 2.375rem;
        }
        &-no { 
            margin-top: 16px;
        }
    }
    .form-select {
        // height: 50px;
    }
    .table {
        .faw-vector_down {
            font-size:0.5rem;
            display:inline-block;
            color:#2E3549;
            cursor: pointer;
        }
    }
    .history {
        &__filter {
            position: relative;
            &:after {
                content: ' ';
                display: block;
                height: 1px;
                width: calc(100% - 32px);
                position: absolute;
                background: #E0EBF6;
                bottom: 0;
                right: 16px;
            }
        }
        &__list {
            margin-top: 32px;
        }
        &__item {
            &_opened {
                @extend .bg-light;
                position: relative;
                &:after {
                    content: ' ';
                    display: block;
                    height: 1px;
                    width: calc(100% - 48px);
                    position: absolute;
                    background: #E0EBF6;
                    bottom: 0;
                    right: 24px;
                }
                td {
                    &:first-child {
                        margin-left: 10px;
                        border-bottom-left-radius: 0;
                    }
                    &:last-child {
                        border-bottom-right-radius: 0;
                    }
                }
                &:hover {
                    @extend .bg-light;
                }
            }
            &_details {
                @extend .bg-light;
                border-bottom: 8px solid transparent;
                td {
                    &:first-child {
                        border-top-left-radius: 0;
                    }
                    &:last-child {
                        border-top-right-radius: 0;
                    }
                }
                &:hover {
                    @extend .bg-light;
                }
            }
            &_stub {
                height: 8px;
            }
        }
    }
    .mobile-table {
        &--container {
            padding: 0 16px;
            .history {
                &__filter {
                    flex-direction: column;
                    padding: 0 16px 16px 16px !important;
                    >div, >button {
                        margin: 16px 0 0 0!important;
                        width: 100%;
                        >select {
                            width: 100%!important;
                        }
                    }
                    >div:first-child{
                        margin: 0!important;
                    }
                    &::after {
                        content: none;
                    }
                }
            }
        }
        &--cell-value {
            .nft-btn {
                width: 100%;
            }
            .nft-check {
                width: 29.33px;
                height: 29.33px;
            }
            &-sm {
                font-weight: 700;
                font-size: 12px;
                line-height: 150%;
                color: #2E3549;
            }
        }
        &--item {
            .table--thumb {
                margin-right: 12px;
            }
            .lvl {
                &:not(:first-child) {
                    border-left: 1px solid #E0EBF6;
                    padding-left: 12px;
                }
            }
        }
        
    }
}

.filter-dd {
    form {
        flex-direction: column;
        padding: 0 16px 16px 16px !important;
        > div {
            width: 100%;
            margin-left: 0 !important;
        }
    }
    .dropdown-toggle{
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #51466D;
        &:focus {
            outline:none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
    }

    .dropdown-menu {
        &.show {
            margin-top: -16px;
            background: #FFFFFF;
            border-radius: 0 0 16px 16px;
        }
    }
}